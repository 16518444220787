export default {
  email: 'البريد الإلكتروني',
  password: 'كلمة المرور',
  newPassword: 'كلمة مرور جديدة',
  givenName: 'الاسم الأول (أسماء)',
  hintGivenName: 'إذا كان لديك عدة أسماء، افصلها بفاصلة',
  firstName: 'الاسم الأول',
  givenNameUse: 'الاسم المستخدم',
  lastName: 'اللقب',
  familyName: 'اسم العائلة',
  middleName: 'اسم الولادة',
  middleNameUse: 'الاسم المستخدم',
  birthPlaceInsee: 'مكان الولادة - رمز INSEE',
  countryOfBirth: 'رمز بلد الولادة',
  streetAddress: 'العنوان',
  phoneNumber: 'رقم الهاتف',
  otherPhoneNumber: 'رقم هاتف آخر',
  birthDate: 'تاريخ الولادة (AAAA/MM/JJ)',
  admissionDate: 'تاريخ القبول (AAAA/MM/JJ)',
  operationDate: 'تاريخ العملية (AAAA/MM/JJ)',
  externalID: 'معرف خارجي',
  weight: 'وزنك (بالكيلوغرام)',
  size: 'طولك (بالسنتيمتر)',
  sex: 'الجنس',
  sexChoice: {
    male: 'ذكر',
    female: 'أنثى',
    unknown: 'غير محدد'
  },
  postalCode: 'الرمز البريدي',
  locality: 'المدينة',
  specificInformation: 'معلومات محددة',
  patient: {
    label: 'المريض',
    identifier: 'رقم الضمان الاجتماعي'
  },
  personalDataUseApproval: 'أوافق بشكل صريح على استخدام بياناتي الشخصية في سياق استخدام حل apTeleCare لمتابعتي عن بُعد من قبل المتخصصين في الرعاية الصحية في المؤسسة التي أنشأت لي حسابًا',
  cguValidation: 'تأكيد الشروط والأحكام',
  cguDateValidation: 'في 01/07/2021 الساعة 16:35',
  consent: 'موافقة',
  consentDate: 'في 01/07/2021 الساعة 16:36',
  contactDetails: 'تفاصيل الاتصال',
  mailEditConfirm: 'أنت على وشك تعديل عنوان بريدك الإلكتروني إلى {email}. هل أنت متأكد أنك ترغب في الاستمرار؟',
  editionSuccess: 'تم تعديل الملف الشخصي بنجاح',
  changePassword: {
    title: 'تغيير كلمة المرور',
    previous: 'كلمة المرور القديمة',
    new: 'كلمة المرور الجديدة',
    confirm: 'تأكيد كلمة المرور الجديدة',
    changeSuccess: 'تم تغيير كلمة المرور بنجاح',
    noMatch: 'كلمات المرور غير متطابقة'
  },
  contextSelector: {
    choosePatientFile: {
      title: 'اختيار ملف المريض',
      subtitle: 'يرجى اختيار ملف مريض',
      label: 'اختيار ملف المريض',
      available: 'سيكون ملفك متاحًا في: {date}'
    }
  },
  session: {
    expired: 'انتهت صلاحية جلستك.',
    soonExpired: 'ستنتهي جلستك قريبًا، هل ترغب في تمديدها؟',
    timer: 'إلا إذا، ستتم إزالة الاتصال في {remainingTime} ثانية',
    extend: 'تمديد الجلسة'
  }
}
