import { api } from 'boot/axios'
import { Pagination } from 'src/modules/core/helpers/pagination'

/**
 * @typedef {{
 *   page: int,
 *   pageSize: int,
 * }} Pagination
 *
 * @typedef {{
 *   id: string,
 *   patientFileId: string,
 *   text: string,
 *   creationDate: string,
 *   lastUpdateDate: string,
 *   status: string,
 *   author: AuthorData,
 * }} NoteData
 *
 * @typedef {{
 *   identifier: string,
 *   firstName: string,
 *   lastName: string,
 *   name: string,
 * }} AuthorData
 *
 * @typedef {{
 *   id: ?string,
 *   title: !string,
 *   dateRange: !string,
 *   position: !int,
 *   size: !int,
 *   ucumUnitCode: !string,
 *   visibleForPatient: !boolean
 *   chartDisplayIndicatorSettings: !ChartDisplayIndicatorSettings[]
 * }} ChartDisplay
 *
 * @typedef {{
 *   id: ?string,
 *   indicatorId: !string,
 *   color: !string,
 *   showAlertTriggers: !boolean,
 *   showReferenceValue: !boolean,
 *   showMin: !boolean,
 *   showMax: !boolean,
 *   showMedian: !boolean,
 *   showAverage: !boolean
 * }} ChartDisplayIndicatorSettings
 *
 * @typedef {{
 *   id: !string,
 *   title: !string,
 *   dateRange: !string,
 *   position: !int,
 *   size: !int,
 *   ucumUnitCode: !string,
 *   visibleForPatient: !boolean
 *   excluded: !boolean
 *   protocol: ?ProtocolData
 *   chartDisplayIndicatorSettings: !ChartDisplayIndicatorSettingsData[]
 * }} ChartDisplayData
 *
 * @typedef {{
 *   id: !string,
 *   indicator: !string,
 *   color: !string,
 *   showAlertTriggers: !boolean,
 *   showReferenceValue: !boolean,
 *   showMin: !boolean,
 *   showMax: !boolean,
 *   showMedian: !boolean,
 *   showAverage: !boolean
 * }} ChartDisplayIndicatorSettingsData
 *
 * @typedef {{
 *   id: !string,
 *   name: !string
 * }} ProtocolData
 *
 * @typedef {{
 *   id: !string,
 *   name: !string
 * }} IndicatorData
 *
 * @typedef {{
 *   activeGlobalReferenceValue: !number,
 *   activeCustomReferenceValueId: !number,
 *   activeCustomReferenceValue: !number,
 *   referenceValueCustomization: !boolean,
 *   status: string,
 *   type: !IndicatorTypeData,
 *   measureType: string,
 *   ucumBaseUnit: !UcumUnit,
 *   compatibleUcumUnits: !UcumUnit[],
 *   id: string,
 *   name: string,
 * }} CustomizableIndicatorData
 *
 * @typedef {{
 *   id: string,
 *   name: string
 * }} IndicatorTypeData
 *
 * @typedef {{
 *   code: string,
 *   symbol: string,
 *   name: !string
 * }} UcumUnit
 */

export function readPatientFile(patientFileId, urlParam = '', filters = {}) {
  const params = {
    ...filters || {}
  }

  return api.get(`/patient_files/${patientFileId}${urlParam}`, { params })
}

export function getFollowUp(patientFileId) {
  return api.get(`/patient_files/${patientFileId}/follow-up`)
}

export function createDocument(patientFileId, data = {}, source = 'UPLOADED_CONTENT', providedAsCaregiver = false) {
  return api.post(`/patient_files/${patientFileId}/documents`, {
    ...data,
    source,
    providedAsCaregiver
  })
}

export function createDocumentFromFile(patientFileId, fileId, data = {}, source = 'UPLOADED_CONTENT', providedAsCaregiver = false) {
  return api.post(`/patient_files/${patientFileId}/documents/from_file/${fileId}`, {
    ...data,
    source,
    providedAsCaregiver
  })
}

/**
 * @param {string} patientFileId
 * @param {{
 *     indicatorsId?: ?string[],
 *     indicatorsObservationCode?: ?string[],
 *     ucumUnitCode?: ?string,
 *     startDate?: ?string,
 *     endDate?: ?string,
 *     maxResultCount?: ?int,
 *     withThresholds?: ?boolean,
 *     withIndicatorBoundaries?: ?boolean,
 *     withReferenceValues?: ?boolean
 * }} filters
 */
export function getPatientFileIndicatorData(patientFileId, filters = {}) {
  return api.post(`/patient_files/${patientFileId}/indicators/data`, filters)
}

export function getPatientFileIndicatorValuesTableSetting(patientFileId) {
  return api.get(`/patient_files/${patientFileId}/indicator_values_table_settings`)
}

export function upsertPatientFileIndicatorValuesTableSetting(patientFileId, indicators, startDate) {
  return api.put(`/patient_files/${patientFileId}/indicator_values_table_settings`, {
    indicators,
    startDate
  })
}

export function activatePatientData(patientFileId) {
  return api.post(`/patient_files/${patientFileId}/patient_data/activate`)
}

export function deactivatePatientData(patientFileId) {
  return api.post(`/patient_files/${patientFileId}/patient_data/deactivate`)
}

export function closePatientFile(patientFileId, closingReasonType, closingReason) {
  return api.post(`/patient_files/${patientFileId}/close`, { closingReasonType, closingReason })
}

export function reopenPatientFile(patientFileId) {
  return api.post(`/patient_files/${patientFileId}/reopen`)
}

export function listMyPatientFiles(pagination) {
  return api.get('/me/patient_files', {
    params: {
      page: pagination.page,
      pageSize: pagination.rowsPerPage
    }
  })
}

export function listEstablishmentPatientFiles(
  establishmentIdentifier,
  page = 1,
  pageSize = Pagination.rowsPerPage,
  params = {},
  signal = null
) {
  return api.post(`/establishments/${establishmentIdentifier}/patient_files/search`, {
    page,
    pageSize,
    ...params
  }, { signal })
}

export function createPatientFile(patientFile) {
  return api.post('/patient_files', patientFile)
}

export function linkFormToPatientFile(patientFile, formId, params) {
  return api.post(`/patient_files/${patientFile.id}/forms/${formId}`, params)
}

export function deactivatePatientFileForm(patientFileForm) {
  return api.post(`/patient_file_forms/${patientFileForm.id}/deactivate`)
}

export function activatePatientFileForm(patientFileForm) {
  return api.post(`/patient_file_forms/${patientFileForm.id}/activate`)
}

export function overloadPatientFileForm(patientFileFormId, data) {
  return api.post(`/patient_file_forms/${patientFileFormId}/overload`, data)
}

export function activatePatientFileProtocol(protocol) {
  return api.post(`/patient_file_protocols/${protocol.id}/activate`)
}

export function deactivatePatientFileProtocol(patientFileProtocol) {
  return api.post(`/patient_file_protocols/${patientFileProtocol.id}/deactivate`)
}

export function restorePatientFileProtocol(patientFileProtocol) {
  return api.post(`/patient_file_protocols/${patientFileProtocol.id}/restore`)
}

export function applyProtocolToPatientFile(protocolId, patientFileId) {
  return api.post(`/patient_files/${patientFileId}/protocols/${protocolId}/apply`)
}

export function conditionList(patientFileProtocol) {
  return api.get(`/patient_files/${patientFileProtocol}/conditions`)
}

export function boundariesList(patientFileId) {
  return api.get(`/patient_files/${patientFileId}/indicator_boundaries`)
}

export function updateBoundary(patientFileId, indicatorId, data) {
  return api.put(`/patient_files/${patientFileId}/indicators/${indicatorId}/boundaries`, data)
}

export function deleteBoundary(patientFileId, indicatorId, data) {
  return api.delete(`/patient_files/${patientFileId}/indicators/${indicatorId}/boundaries`, { params: data })
}

export function excludePatientFileDocument(patientFileId, documentId) {
  return api.post(`/patient_files/${patientFileId}/documents/${documentId}/exclude`)
}

export function restorePatientFileDocument(patientFileId, documentId) {
  return api.post(`/patient_files/${patientFileId}/documents/${documentId}/restore`)
}

export function listAllPayloads(patientFileId, pagination) {
  return api.get(`/patient_files/${patientFileId}/payloads`, {
    params: {
      page: pagination.page,
      pageSize: pagination.pageSize
    }
  })
}

export function viewCpiaPayload(patientFileId, cpiaPayloadId) {
  return api.get(`/patient_files/${patientFileId}/cpia_payloads/${cpiaPayloadId}`)
}

export function viewMedialPayload(patientFileId, medialPayloadId) {
  return api.get(`/patient_files/${patientFileId}/medial_payloads/${medialPayloadId}`)
}

export function excludePatientFileCalculation(patientFileId, calculationLinkId) {
  return api.post(`/patient_files/${patientFileId}/calculation_links/${calculationLinkId}/exclude`)
}

export function restorePatientFileCalculation(patientFileId, calculationLinkId) {
  return api.post(`/patient_files/${patientFileId}/calculation_links/${calculationLinkId}/restore`)
}

/* Données externes biologies */
export function viewBiologyPayload(patientFileId, clinicalPayloadId) {
  return api.get(`/patient_files/${patientFileId}/clinical_document_payloads/${clinicalPayloadId}`)
}

export function linkMeasureToIndicator(patientFileId, clinicalDocumentPayloadId, measureMappingId, indicatorId = null, ucumUnitCode) {
  return api.put(`/patient_files/${patientFileId}/clinical_document_payloads/${clinicalDocumentPayloadId}/measure_mapping/${measureMappingId}`, {
    indicatorId,
    ucumUnitCode
  })
}

export function cancelMeasureMapping(patientFileId, clinicalDocumentPayloadId, measureMappingId) {
  return api.delete(`/patient_files/${patientFileId}/clinical_document_payloads/${clinicalDocumentPayloadId}/measure_mapping/${measureMappingId}`)
}

export function applyMeasureMapping(patientFileId, clinicalDocumentPayloadId) {
  return api.post(`/patient_files/${patientFileId}/clinical_document_payloads/${clinicalDocumentPayloadId}/apply_mapping`)
}

export function getMeasureMappingSuggestions(patientFileId, clinicalDocumentPayloadId, measureMappingId) {
  return api.get(`/patient_files/${patientFileId}/clinical_document_payloads/${clinicalDocumentPayloadId}/mapping_suggestions/${measureMappingId}`)
}

export function listChartDisplays(patientFileId) {
  return api.get(`/patient_files/${patientFileId}/chart_displays`)
}

export function listPatientChartDisplays(patientFileId) {
  return api.get(`/patient_files/${patientFileId}/chart_displays/patient`)
}

/**
 * @param {string} patientFileId
 * @param {ChartDisplay} chartDisplayInput
 * @return {Promise<{data: ChartDisplayData}>}
 */
export function createChartDisplay(patientFileId, chartDisplayInput) {
  return api.post(`/patient_files/${patientFileId}/chart_displays`, chartDisplayInput)
}

/**
 * @param {string} patientFileId
 * @param {string} chartDisplayId
 * @param {ChartDisplay} chartDisplayInput
 * @return {Promise<{data: ChartDisplayData}>}
 */
export function updateChartDisplay(patientFileId, chartDisplayId, chartDisplayInput) {
  return api.put(`/patient_files/${patientFileId}/chart_displays/${chartDisplayId}`, chartDisplayInput)
}

/**
 * @param {string} patientFileId
 * @param {string} chartDisplayId
 * @return {Promise<{data: null}>}
 */
export function excludeChartDisplay(patientFileId, chartDisplayId) {
  return api.post(`/patient_files/${patientFileId}/chart_displays/${chartDisplayId}/exclude`)
}

/**
 * @param {string} patientFileId
 * @param {string} chartDisplayId
 * @return {Promise<{data: null}>}
 */
export function restoreChartDisplay(patientFileId, chartDisplayId) {
  return api.post(`/patient_files/${patientFileId}/chart_displays/${chartDisplayId}/restore`)
}

/**
 * @param {string} patientFileId
 * @param {string} chartDisplayId
 * @return {Promise<{data: null}>}
 */
export function deleteChartDisplay(patientFileId, chartDisplayId) {
  return api.delete(`/patient_files/${patientFileId}/chart_displays/${chartDisplayId}`)
}

export function getPlannedAdditionalEvents(patientFileId, filters = {}, pagination = {}) {
  const params = {
    ...filters,
    ...pagination
  }
  return api.get(`/patient_files/${patientFileId}/calendar_events`, { params })
}

export function createRdv(patientFileId, data = {}) {
  return api.post(`/patient_files/${patientFileId}/calendar_events/rendez_vous`, data)
}

export function updateRdv(patientFileId, rdvId, data) {
  return api.put(`/patient_files/${patientFileId}/calendar_events/rendez_vous/${rdvId}`, data)
}

export function deleteRdv(patientFileId, rdvId, proAsCaregiver) {
  return api.delete(`/patient_files/${patientFileId}/calendar_events/rendez_vous/${rdvId}`, {
    params: {
      proAsCaregiver
    }
  })
}

export function createReminder(patientFileId, data = {}) {
  return api.post(`/patient_files/${patientFileId}/calendar_events/reminder`, data)
}

export function updateReminder(patientFileId, reminderId, data) {
  return api.put(`/patient_files/${patientFileId}/calendar_events/reminder/${reminderId}`, data)
}

export function deleteReminder(patientFileId, reminderId, proAsCaregiver) {
  return api.delete(`/patient_files/${patientFileId}/calendar_events/reminder/${reminderId}`, {
    params: {
      proAsCaregiver
    }
  })
}

/**
 * @param {string} patientFileId
 * @param {Pagination} pagination
 * @returns {Promise<{data: NoteData[]}>}
 */
export function listNotes(patientFileId, pagination) {
  return api.get(`/patient_files/${patientFileId}/notes`, {
    params: {
      page: pagination.page,
      pageSize: pagination.pageSize
    }
  })
}

/**
 * @param {string} patientFileId
 * @param {string} text
 * @returns {Promise<{data: NoteData[]}>}
 */
export function addNote(patientFileId, text) {
  return api.post(`/patient_files/${patientFileId}/notes`, { text })
}

/**
 * @param {string} patientFileId
 * @param {string} noteId
 * @param {string} text
 * @returns {Promise<{data: NoteData[]}>}
 */
export function editNote(patientFileId, noteId, text) {
  return api.put(`/patient_files/${patientFileId}/notes/${noteId}`, { text })
}

/**
 * @param {string} patientFileId
 * @param {string} noteId
 * @returns {Promise<{data: NoteData}>}
 */
export function archiveNote(patientFileId, noteId) {
  return api.put(`/patient_files/${patientFileId}/notes/${noteId}/archive`)
}

/**
 * @param {string} patientFileId
 * @returns {Promise<{
 *   data: {
 *     data: CustomizableIndicatorData[],
 *     totalItems: int
 *   }
 * }>}
 */
export function listCustomizableIndicators(patientFileId) {
  return api.get(`/patient_files/${patientFileId}/customizable_indicators?pageSize=100`)
}

export function createCustomActivity(patientFileId, activity) {
  return api.post(`/patient_files/${patientFileId}/custom_activities`, activity)
}

export function getCustomActivityTypes(patientFileId) {
  return api.get(`/patient_files/${patientFileId}/custom_activities/types`)
}

export function getReferenceValue(patientFileId, filters = {}) {
  return api.post(`/patient_files/${patientFileId}/reference-value`, filters)
}
