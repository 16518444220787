export default {
  tasks: {
    title: 'مهامي',
    subtitle: 'أفعالي التي يجب إتمامها'
  },
  monitoring: {
    title: 'متابعتي',
    subtitle: 'بياناتي ومحتوياتي'
  },
  messenger: {
    title: 'رسائلي',
    subtitle: 'تبادلاتي مع الفريق'
  },
  documents: {
    title: 'مستنداتي',
    subtitle: 'مستنداتي المتبادلة مع الفريق'
  },
  agenda: 'جدولي',
  newTasks: 'مهمة يجب إتمامها | مهام يجب إتمامها',
  newMessages: 'رسالة جديدة | رسائل جديدة',
  newDocuments: 'مستند جديد | مستندات جديدة',
  closed: 'الملف مغلق',
  noPendingTasks: 'لا توجد مهام معلقة',
  inPriority: 'أولوية',
  scheduledToday: 'مجدول اليوم',
  upcoming: 'قادم',
  passwordGeneration: {
    title: 'بيانات اعتماد تسجيل دخول المريض',
    firstLoginUsername: 'معرّفات الدخول الأولى الخاصة بك',
    email: 'عنوان البريد الإلكتروني',
    mobilePhone: 'الهاتف المحمول',
    password: 'كلمة المرور',
    info: {
      part1: 'يرجى ',
      part2: 'حفظ كلمة المرور هذه',
      part3: ' بشكل آمن قبل المتابعة.',
      part4: ' كلمة المرور هذه مطلوبة لـ ',
      part5: 'المريض عند تسجيل الدخول الأول.'
    }
  },
  form: {
    generalInfo: 'معلومات عامة',
    firstNames: 'الأسماء الأولى',
    firstName: 'الاسم الأول',
    birthName: 'اسم الميلاد',
    firstNameUsed: 'الاسم الأول المستخدم',
    lastNameUsed: 'الاسم الأخير المستخدم',
    sex: 'الجنس',
    birthDate: 'تاريخ الميلاد',
    birthPlaceInsee: 'مكان الولادة - رمز INSEE',
    coordinates: 'الإحداثيات',
    email: 'عنوان البريد الإلكتروني',
    mobilePhone: 'الهاتف المحمول',
    digitalIdentity: 'الهوية الرقمية',
    ins: 'رقم INS',
    oid: 'OID',
    insiIdentityAttribute: 'سمة حالة الهوية الرقمية',
    insi: {
      search: {
        info: {
          part1: 'لإجراء بحث على INSi,',
          part2: 'يجب إدخال اسم العائلة والاسم (الأسماء) الأولى (الأسماء),',
          part3: 'تاريخ الميلاد والجنس (رجل أو امرأة).'
        },
        search: 'البحث في INSi'
      },
      verification: {
        info: {
          part1: 'لإجراء التحقق من الهوية على INSi,',
          part2: 'يجب إدخال اسم العائلة والاسم (الأسماء) الأولى (الأسماء),',
          part3: 'تاريخ الميلاد، والجنس (رجل أو امرأة)، ورقم INS و OID.'
        },
        verification: 'التحقق من INSi'
      }
    },
    error: {
      alreadyUsedEmailAddress: 'عنوان البريد الإلكتروني مستخدم بالفعل',
      emailOrMobilePhone: 'بريد إلكتروني أو هاتف محمول إلزامي'
    },
    updateWithIdentityProof: 'أنا أخصائي رعاية صحية وأرغب في تغيير هوية هذا المريض على أساس إثبات الهوية',
    additionalInfo: 'ومات إضافيةمعل',
    globalReferenceValue: 'القيمة المرجعية العالمية:',
    identity: {
      doubtful: 'هوية مشكوك فيها',
      fictitious: 'هوية وهمية',
      namesake: 'هوية الاسم المستعار'
    },
    success: {
      creation: 'تم إنشاء المريض بنجاح',
      update: 'تم تحديث المريض بنجاح'
    }
  }
}
