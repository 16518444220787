export default {
  required: 'Ce champ est obligatoire',
  email: 'Adresse e-mail incorrecte',
  birthName: 'Le nom de naissance est incorrect. Il ne doit pas contenir d\'accents ni de signes diacritiques. Vous pouvez saisir jusqu\'à 100 caractères',
  firstNames: 'Prénoms invalides',
  specialCharacters: 'Ce champ ne doit pas contenir de caractères spéciaux',
  emailLength: 'L\'adresse e-mail ne doit pas faire plus de 180 caractères',
  textFieldLength: 'Ce champ doit être compris entre 2 et 100 caractères',
  textAreaLength: 'Ce champ doit être compris entre 2 et 500 caractères',
  phone: 'Numéro de téléphone incorrect',
  patientIdentifier: 'Numéro de sécurité sociale incorrect, il doit contenir 13 chiffres',
  inseeCode: 'Le code INSEE doit être un code alphanumérique de 5 caractères',
  ins: 'Numéro invalide',
  activeGlobalReferenceValue: 'La valeur de référence doit être un entier'
}
