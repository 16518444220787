export default {
  center: {
    title: 'Centre de notifications',
    noNotifications: 'Aucune notification',
    markAll: 'Tout marquer comme vu',
    deleteAll: 'Effacer tout',
    loadFailure: 'Les notifications n\'ont pas pu être chargées'
  },
  PATIENT_FILE: {
    NEW: {
      title: 'Nouveau dossier patient',
      content: {
        patient: 'Un nouveau dossier patient a été créé',
        others: 'Un nouveau dossier patient a été créé pour le patient {fullName}'
      }
    }
  },
  MESSAGE: {
    NEW: {
      title: 'Nouveau message',
      content: {
        patient: 'Vous avez reçu un nouveau message dans votre dossier patient',
        others: 'Vous avez reçu un nouveau message dans le dossier du patient {fullName}'
      }
    }
  },
  FORM_OCCURRENCE: {
    NEW: {
      title: 'Nouveau questionnaire',
      content: 'Le questionnaire {formName} est disponible jusqu\'au {endDate}'
    },
    REMIND: {
      title: 'Rappel de questionnaire',
      content: 'Il vous reste {duration} pour répondre au questionnaire {formName}'
    }
  },
  CONTENT: {
    NEW: {
      title: 'Nouveau contenu thérapeutique',
      content: 'Le contenu thérapeutique {contentName} a été ajouté à votre dossier patient'
    }
  },
  PROTOCOL: {
    ASSIGN: {
      title: 'Nouveau protocole',
      content: 'Le protocole {protocolName} a été assigné à votre dossier patient'
    },
    FINISH_ASSIGNMENT: {
      title: 'Assignation d\'un protocole',
      content: 'L\'assignation du protocole {name} est à présent finalisée.'
    }
  },
  DOCUMENT: {
    NEW: {
      title: 'Nouveau document',
      content: {
        patient: 'Un nouveau document a été ajouté dans votre dossier patient',
        others: 'Un nouveau document a été déposé pour le patient {fullName}'
      }
    },
    REMIND: {
      title: 'Prescription à renouveler',
      content: 'La prescription pour le patient {fullName} se termine bientôt'
    }
  },
  ALERT: {
    NEW: {
      title: 'Nouvelles alertes',
      content: {
        others: {
          application: 'De nouvelles alertes ont été générées pour le patient {fullName} le {date} sur l\'indicateur {indicatorName}',
          form: 'De nouvelles alertes ont été générées pour le patient {fullName} sur le formulaire {formName}',
          non_observance: 'De nouvelles alertes de non-observance ont été générées pour le patient {fullName}'
        }
      }
    }
  },
  CALCULATION: {
    SUBMIT_VALIDATION: {
      title: 'Validation de donnée calculée',
      content: 'La donnée calculée {calculationName} requiert votre validation pour le service {serviceName} de l\'établissement {establishmentName}'
    }
  },
  FORM: {
    SUBMIT_VALIDATION: {
      title: 'Validation de formulaire',
      content: 'Le formulaire {formName} requiert votre validation pour le service {serviceName} de l\'établissement {establishmentName}'
    },
    NEW: {
      title: 'Nouveau questionnaire',
      content: 'Le questionnaire {formName} a été ajouté à votre dossier patient'
    }
  },
  PROPERTY: {
    SUBMIT_VALIDATION: {
      title: 'Validation de paramètre supplémentaire',
      content: 'Le paramètre supplémentaire {propertyName} requiert votre validation pour le service {serviceName} de l\'établissement {establishmentName}'
    }
  },
  RENDEZ_VOUS: {
    NEW: {
      title: 'Nouveau rendez-vous',
      content: {
        patient: 'Vous avez un nouveau rendez-vous {rdvTitle}',
        others: 'Un nouveau rendez-vous {rdvTitle} a été ajouté pour le patient {fullName}'
      }
    },
    DELETE: {
      title: 'Suppression d\'un rendez-vous',
      content: {
        patient: 'Votre rendez-vous {rdvTitle} a été supprimé',
        others: 'Le rendez-vous {rdvTitle} a été supprimé pour le patient {fullName}'
      }
    },
    UPDATE: {
      title: 'Modification d\'un rendez-vous',
      content: {
        patient: 'Votre rendez-vous {rdvTitle} a été modifié',
        others: 'Le rendez-vous {rdvTitle} a été modifié pour le patient {fullName}'
      }
    },
    REMIND: {
      title: 'Rappel d\'un rendez-vous',
      content: {
        patient: 'Rappel de votre rendez-vous {rdvTitle}',
        others: 'Rappel du rendez-vous {rdvTitle} pour le patient {fullName}'
      }
    }
  },
  REMINDER: {
    NEW: {
      title: 'Nouveau rappel',
      content: {
        patient: 'Vous avez un nouveau rappel {reminderTitle}',
        others: 'Un nouveau rappel {reminderTitle} a été ajouté pour le patient {fullName}'
      }
    },
    DELETE: {
      title: 'Suppression d\'un rappel',
      content: {
        patient: 'Votre rappel {reminderTitle} a été supprimé',
        others: 'Le rappel {reminderTitle} a été supprimé pour le patient {fullName}'
      }
    },
    UPDATE: {
      title: 'Modification d\'un rappel',
      content: {
        patient: 'Votre rappel {reminderTitle} a été modifié',
        others: 'Le rappel {reminderTitle} a été modifié pour le patient {fullName}'
      }
    },
    REMIND: {
      title: 'Rappel',
      content: {
        patient: 'Rappel de {reminderTitle}',
        others: 'Rappel de {reminderTitle} pour le patient {fullName}'
      }
    }
  }
}
