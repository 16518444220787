export default {
  title: 'Calculated Data',
  subtitle: 'List of Calculated Data',
  create: 'Add Calculated Data',
  errors: {
    indicator: {
      NOT_UNIQUE_ERROR: 'This indicator is already linked to calculated data in this establishment'
    },
    name: {
      NOT_UNIQUE_ERROR: 'Calculated data with the same name already exists in this establishment'
    },
    calculation: {
      unique_undeactivated_calculation: 'Only one calculated data can be activated for an indicator and a service in the establishment.'
    }
  }
}
