export default {
  errors: {
    invalid_operand: 'عامل غير صالح',
    invalid_mathematical_expression: 'التعبير الرياضي يحتوي على أخطاء في العوامل',
    invalid_duration: 'العامل يحتوي على مدة غير صالحة',
    missing_offset: 'العامل لا يحتوي على إزاحة',
    missing_indicator: 'العامل لا يحتوي على مؤشر',
    missing_scalar: 'العامل لا يحتوي على قيمة عددية',
    missing_property: 'العامل لا يحتوي على معلمة إضافية'
  }
}
