export default {
  plannedActivities: 'Activité planifiée | Activités planifiées',
  noPlannedActivity: {
    title: 'Aucune activité planifiée',
    subtitle: 'Vous n\'avez aucune activité planifiée ce jour. Pour voir les activités à venir, veuillez consulter votre agenda.',
    alternativeSubtitle: 'Vous n\'avez aucune activité planifiée ce jour.'
  },
  freeActivities: 'Activité libre | Activités libres',
  myFreeActivities: 'Mes activités libres',
  add: 'Ajouter une activité',
  seeAll: 'voir toutes les activités libres',
  less: 'voir moins d\'activités libres'
}
