export default {
  title: 'Paramètres supplémentaires',
  subtitle: 'Liste des paramètres supplémentaires',
  create: 'Ajouter un paramètre supplémentaire',
  errors: {
    name: {
      NOT_UNIQUE_ERROR: 'Un paramètre supplémentaire du même nom existe déjà dans cet établissement'
    }
  }
}
