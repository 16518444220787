export default {
  channels: {
    APPLICATION: 'الويب',
    EMAIL: 'البريد الإلكتروني',
    PUSH: 'الجوال'
  },
  notifications: {
    PATIENT_FILE: {
      NEW: 'ملف مريض جديد'
    },
    DOCUMENT: {
      NEW: 'مستند جديد',
      REMIND: 'تجديد الوصفة الطبية'
    },
    MESSAGE: {
      NEW: 'رسالة جديدة'
    },
    ALERT: {
      NEW: 'تنبيه جديد'
    },
    CONTENT: {
      NEW: 'محتوى علاجي جديد'
    },
    PROTOCOL: {
      ASSIGN: 'بروتوكول جديد'
    },
    FORM_OCCURRENCE: {
      NEW: 'نموذج جديد متاح',
      REMIND: 'تذكير بنموذج متاح'
    },
    CALCULATION: {
      SUBMIT_VALIDATION: 'التحقق من البيانات المحسوبة'
    },
    FORM: {
      SUBMIT_VALIDATION: 'التحقق من النموذج'
    },
    PROPERTY: {
      SUBMIT_VALIDATION: 'التحقق من المعامل الإضافي'
    },
    CALENDAR_EVENTS: {
      NEW: 'إنشاء مواعيد/تذكيرات',
      UPDATE: 'تعديل مواعيد/تذكيرات',
      DELETE: 'حذف مواعيد/تذكيرات',
      REMIND: 'تذكير بالمواعيد/التذكيرات'
    }
  }
}
