export default {
  title: 'ملف المريض',
  subtitle: 'معلومات الملف',
  close: 'إغلاق الملف',
  creationDate: 'تم الإنشاء في ',
  history: {
    entries: {
      user: {
        update: {
          title: 'تعديل الملف الشخصي',
          subtitle: 'قام بتعديل ملف المريض'
        },
        insiSearch: {
          title: 'بحث عن الهوية',
          subtitle: 'بحث عن هوية المريض عبر INSi'
        },
        insiVerification: {
          title: 'التحقق من الهوية',
          subtitle: 'تحقق من هوية المريض عبر INSi'
        },
        insiVerificationFailure: {
          title: 'خطأ في التحقق من الهوية',
          subtitle: 'لم يتمكن من التحقق من هوية المريض عبر INSi'
        }
      },
      NonObservanceAlert: {
        create: {
          title: 'تنبيه عدم الالتزام',
          subtitle: 'لم يرد المريض على الاستبيان'
        },
        update: {
          title: 'تنبيه عدم الالتزام',
          subtitle: 'لم يرد المريض بعد على الاستبيان'
        }
      },
      formResponse: {
        submit: {
          title: 'رد على استبيان',
          subtitle: 'أجاب على الاستبيان'
        }
      },
      cpiaPayload: {
        create: {
          title: 'بيانات CPIA جديدة',
          subtitle: 'قياسات جديدة لمؤشرات CPIA'
        }
      },
      customReferenceValue: {
        create: {
          title: 'تعديل قيمة مرجعية',
          subtitle: 'أدخل قيمة مرجعية {referenceValue} للمؤشر'
        },
        delete: {
          title: 'تعطيل قيمة مرجعية',
          subtitle: 'تم تعطيل القيمة المرجعية للمؤشر'
        }
      },
      document: {
        stream: {
          title: 'مراجعة {documentType}',
          subtitle: 'راجع {documentType}'
        },
        download: {
          title: 'تحميل {documentType}',
          subtitle: 'تم تحميل {documentType}'
        },
        acquit: {
          title: 'إقرار بوثيقة',
          subtitle: 'أقر الوثيقة'
        }
      },
      file: {
        stream: {
          title: 'مراجعة {documentType}',
          subtitle: 'تم مراجعة {documentType}'
        }
      },
      medialPayload: {
        create: {
          title: 'بيانات الجلسة الجديدة',
          subtitle: 'قياسات جديدة لمؤشرات الجلسة'
        }
      },
      propertyValue: {
        create: {
          title: 'إدخال معلمة إضافية',
          subtitle: 'أدخل قيمة المعلمة الإضافية {propertyName}'
        },
        update: {
          title: 'تعديل معلمة إضافية',
          subtitle: 'تم تعديل قيمة المعلمة الإضافية {propertyName}'
        },
        delete: {
          title: 'تعطيل معلمة إضافية',
          subtitle: 'تم تعطيل المعلمة الإضافية'
        }
      },
      patientFile: {
        addPatientFileCaregiver: {
          title: 'إضافة مساعد',
          subtitle: 'تم إضافة مساعد بواسطة {sourceFirstName} {sourceLastName} إلى القائمة'
        },
        removePatientFileCaregiver: {
          title: 'إزالة مساعد',
          subtitle: 'تم إزالة مساعد بواسطة {sourceFirstName} {sourceLastName} من القائمة'
        },
        createIndicatorBoundary: {
          title: 'تخصيص حد التنبيه',
          subtitle: 'تم تخصيص حد تنبيه للمؤشر {target}. يتم تفعيل تنبيه {alertLevel} إذا كانت قيمة المؤشر {operator} {subtitleSuffix}'
        },
        updateIndicatorBoundary: {
          title: 'تعديل تخصيص حد التنبيه',
          subtitle: 'تم تعديل تخصيص حد التنبيه للمؤشر {target}. يتم تفعيل تنبيه {alertLevel} إذا كانت قيمة المؤشر {operator} {subtitleSuffix}'
        },
        deleteIndicatorBoundary: {
          title: 'حذف تخصيص حد التنبيه',
          subtitle: 'تم إزالة تخصيص حد التنبيه {alertLevel} للمؤشر {target}'
        },
        deactivateIndicatorBoundary: {
          title: 'تعطيل حد التنبيه',
          subtitle: 'تم تعطيل حد التنبيه {alertLevel} للمؤشر {target}'
        },
        close: {
          title: 'إغلاق الملف',
          subtitle: 'تم إغلاق الملف'
        },
        automaticallyClose: {
          title: 'إغلاق الملف تلقائيًا',
          subtitle: 'تم إغلاق الملف تلقائيًا لأن تاريخ الانتهاء قد مر.'
        }
      },
      rendezVous: {
        create: {
          title: 'إنشاء موعد',
          subtitle: 'تم إنشاء الموعد'
        },
        update: {
          title: 'تعديل موعد',
          subtitle: 'تم تعديل الموعد'
        },
        delete: {
          title: 'حذف موعد',
          subtitle: 'تم حذف الموعد'
        },
        title: 'موعد',
        subtitle: 'تمكين تخطيط الموعد',
        new: 'إنشاء موعد جديد',
        form: {
          shareWithMedicalTeam: 'مشاركة مع الفريق الطبي',
          date: 'التاريخ',
          from: 'من',
          to: 'إلى',
          addReminder: 'إضافة تذكير',
          delay: 'تأخير',
          success: 'تم إنشاء الموعد بنجاح',
          failure: 'تعذر إنشاء الموعد'
        }
      },
      reminder: {
        create: {
          title: 'إنشاء تذكير',
          subtitle: 'تم إنشاء التذكير'
        },
        update: {
          title: 'تعديل تذكير',
          subtitle: 'تم تعديل التذكير'
        },
        delete: {
          title: 'حذف تذكير',
          subtitle: 'تم حذف التذكير'
        },
        title: 'تذكير',
        subtitle: 'تمكين تخطيط التذكير',
        new: 'إنشاء تذكير جديد',
        form: {
          date: 'تاريخ التذكير',
          time: 'وقت التذكير',
          shareWithMedicalTeam: 'مشاركة مع الفريق الطبي',
          success: 'تم إنشاء التذكير بنجاح',
          failure: 'تعذر إنشاء التذكير'
        }
      },
      note: {
        addNote: {
          title: 'إضافة ملاحظة',
          subtitle: 'تم إضافة ملاحظة'
        },
        editNote: {
          title: 'تحرير ملاحظة',
          subtitle: 'تم تحرير ملاحظة'
        },
        archiveNote: {
          title: 'أرشفة ملاحظة',
          subtitle: 'تم أرشفة ملاحظة'
        }
      },
      patientFileProtocol: {
        activate: {
          title: 'تفعيل بروتوكول',
          subtitle: 'تم تفعيل البروتوكول'
        },
        deactivate: {
          title: 'تعطيل بروتوكول',
          subtitle: 'تم تعطيل البروتوكول'
        },
        restore: {
          title: 'استعادة بروتوكول',
          subtitle: 'تم استعادة البروتوكول'
        }
      },
      protocol: {
        assign: {
          title: 'تخصيص بروتوكول',
          subtitle: 'تم تخصيص البروتوكول'
        },
        externalAssign: {
          title: 'تخصيص بروتوكول',
          subtitle: 'تخصيص البروتوكول'
        }
      },
      custom: {
        hospitalization: {
          title: 'استشفاء',
          subtitle: 'تم استشفاء المريض'
        },
        other: {
          title: 'أنشطة أخرى',
          subtitle: '{description}'
        }
      }
    }
  },
  content: {
    item: {
      restore: 'استعادة المحتوى للملف',
      restoreError: 'حدث خطأ أثناء استعادة المحتوى.'
    }
  },
  about: 'تم إنشاء ملف المريض في {creationDate} في خدمة {serviceName} بمؤسسة {establishmentName}',
  monitoring: {
    loading: 'جارٍ تحميل متابعتك',
    history: {
      title: 'تاريخي',
      noData: 'لا توجد بيانات',
      actor: {
        you: 'أنت',
        him: 'هو '
      },
      consulted: 'تمت المراجعة في ',
      responded: 'تم الرد في '
    },
    data: 'بياناتي الأخيرة',
    content: 'محتواي العلاجي',
    noContent: {
      firstLine: 'لا يوجد محتوى متاح في الوقت الحالي.',
      secondLine: 'سيكون تعليمك العلاجي متاحًا بمجرد إضافته من قبل مختص صحي.'
    },
    noData: {
      firstLine: 'لا توجد بيانات متاحة في الوقت الحالي.',
      secondLine: 'ستكون الرسوم البيانية لقياساتك وتعليمك العلاجي وتاريخك متاحة بمجرد إضافة القياسات.'
    }
  },
  payloads: {
    question: 'سؤال',
    answer: 'إجابة',
    alertStatus: 'حالة التنبيه',
    showQuestionsLabel: 'عرض تسميات الأسئلة',
    receivedImage: 'الصورة المستلمة: ',
    loadingFailure: 'تعذر تحميل البيانات',
    alert: 'تنبيه',
    closed: 'مغلق',
    pending: 'قيد الانتظار',
    comment: 'تعليق',
    alertConditions: {
      show: 'عرض شروط التنبيه',
      hide: 'إخفاء شروط التنبيه'
    },
    newMeasureCondition: {
      display: 'عرض شرط القياس الجديد',
      hide: 'إخفاء شرط القياس الجديد'
    },
    formCondition: 'شرط الاستبيان الذي أنشأته',
    condition: 'الشرط الذي أدى إلى التنبيه: '
  },
  graphs: {
    lastMeasures: 'جاري استرجاع أحدث قياساتك',
    noData: 'لا توجد بيانات متاحة',
    error: {
      load: 'تعذر استرجاع قياسات الرسم البياني {chart}',
      graphSettings: 'تعذر تحميل إعدادات الرسومات البيانية'
    },
    in: 'في',
    referenceValue: 'القيمة المرجعية',
    minimum: 'الحد الأدنى{markLineNameSuffix}',
    maximum: 'الحد الأقصى{markLineNameSuffix}',
    median: 'الوسيط{markLineNameSuffix}',
    average: 'المتوسط{markLineNameSuffix}'
  },
  contextSelector: {
    service: 'الخدمة:',
    createdOn: 'تم الإنشاء في {date}',
    inService: 'في الخدمة',
    closed: 'مغلق'
  }
}
