import { Dialog, throttle } from 'quasar'
import { api } from 'boot/axios'
import { ref } from 'vue'
import { setImmediateInterval } from 'src/utils/loop'
import { config } from 'boot/config'
import { user } from 'src/modules/core/composables/useAuth'
import SessionExpirationWarnDialog from 'src/modules/core/components/SessionExpirationWarnDialog.vue'

// Warn other tabs that they must open dialog about
const bc = new BroadcastChannel('sessionExpiration')

export const sessionExpiresAt = ref()
export const sessionRemainingTime = ref()
export const sessionExpired = ref(false)

export async function onSessionKeepAlive() {
  try {
    await api.get('/me')
    return true
  } catch {
    return false
  }
}

const shareSessionExpiresToOtherTabs = throttle(() => {
  bc.postMessage(sessionExpiresAt.value)
}, 1000)

function saveResponseSessionExpires(response) {
  if (typeof response.headers['x-session-expires'] !== 'undefined') {
    const sessionExpires = parseInt(response.headers['x-session-expires'])

    if (!isNaN(sessionExpires)) {
      sessionExpiresAt.value = sessionExpires + Math.floor(Date.now() / 1000)
      shareSessionExpiresToOtherTabs()
    }
  }
}

// Save session expires time send by api
api.interceptors.response.use(response => {
  saveResponseSessionExpires(response)
  return response
}, async error => {
  if (error.response) {
    saveResponseSessionExpires(error.response)
  }

  if (error?.response?.status === 401 && user.value !== null) {
    sessionExpired.value = true
  }

  return Promise.reject(error)
})

bc.addEventListener('message', event => {
  // A "session expires at" received from another tab. Save it if is more recent than the current one
  const parsedExpiresAt = parseInt(event.data)
  if (!isNaN(parsedExpiresAt) && (sessionExpiresAt.value === null || sessionExpiresAt.value < parsedExpiresAt)) {
    sessionExpiresAt.value = parsedExpiresAt
  }
})

let sessionExpiresWarnDialog = null

function showSessionExpiresWarnDialog() {
  if (!sessionExpiresWarnDialog && user.value) {
    sessionExpiresWarnDialog = Dialog
      .create({
        component: SessionExpirationWarnDialog
      })
      .onOk(() => {
        sessionExpired.value = false
      })
      .onDismiss(() => {
        sessionExpiresWarnDialog = null
      })
  }
}

function hideSessionExpiresWarnDialog() {
  if (sessionExpiresWarnDialog) {
    sessionExpiresWarnDialog.hide()
    sessionExpiresWarnDialog = null
  }
}

function onSessionExpires() {
  sessionExpired.value = true
  showSessionExpiresWarnDialog()
}

setImmediateInterval(() => {
  if (sessionExpired.value) {
    onSessionExpires()
    return
  }

  if (sessionExpiresAt.value === null || isNaN(sessionExpiresAt.value)) {
    hideSessionExpiresWarnDialog()
    return
  }

  sessionRemainingTime.value = sessionExpiresAt.value - Math.floor(Date.now() / 1000)

  if (sessionRemainingTime.value <= 0) {
    onSessionExpires()
  } else if (sessionRemainingTime.value > config.session.timeRemainingBeforeUserWarn) {
    hideSessionExpiresWarnDialog()
  } else if (sessionRemainingTime.value < config.session.timeRemainingBeforeUserWarn && user.value) {
    // Session expiration warn must be visible if remaining time is running out soon
    showSessionExpiresWarnDialog()
  }
}, 1000)
