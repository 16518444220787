export default {
  title: 'المؤشرات',
  subtitle: 'قائمة المؤشرات',
  create: 'إضافة مؤشر',
  errors: {
    name: {
      NOT_UNIQUE_ERROR: 'مؤشر بنفس الاسم موجود بالفعل'
    }
  }
}
