export default {
  channels: {
    APPLICATION: 'Web',
    EMAIL: 'Email',
    PUSH: 'Mobile'
  },
  notifications: {
    PATIENT_FILE: {
      NEW: 'New patient file'
    },
    DOCUMENT: {
      NEW: 'New document',
      REMIND: 'Prescription renewal'
    },
    MESSAGE: {
      NEW: 'New message'
    },
    ALERT: {
      NEW: 'New alert'
    },
    CONTENT: {
      NEW: 'New therapeutic content'
    },
    PROTOCOL: {
      ASSIGN: 'New protocol'
    },
    FORM_OCCURRENCE: {
      NEW: 'New form available',
      REMIND: 'Form reminder available'
    },
    CALCULATION: {
      SUBMIT_VALIDATION: 'Calculated data validation'
    },
    FORM: {
      SUBMIT_VALIDATION: 'Form validation'
    },
    PROPERTY: {
      SUBMIT_VALIDATION: 'Additional parameter validation'
    },
    CALENDAR_EVENTS: {
      NEW: 'Appointment/reminder creation',
      UPDATE: 'Appointment/reminder update',
      DELETE: 'Appointment/reminder deletion',
      REMIND: 'Appointment/reminder reminder'
    }
  }
}
