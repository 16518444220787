export default {
  edit: {
    title: 'تعديل الملف الشخصي',
    subTitle: 'نموذج التعديل',
    editButton: 'تعديل'
  },
  generalInformations: 'المعلومات العامة',
  myProfile: 'ملفي الشخصي',
  myInformations: 'معلوماتي',
  caregivers: 'مساعدي',
  changePassword: 'تغيير كلمة المرور',
  notifications: 'إدارة الإشعارات',
  iot: 'الأجهزة المتصلة',
  chooseLanguage: 'اختيار اللغة',
  help: 'التوثيق والمساعدة',
  onlineHelp: 'مساعدة عبر الإنترنت',
  userManual: 'دليل المستخدم',
  legalMentions: 'الشروط القانونية',
  eula: 'اتفاقية المستخدم النهائية',
  about: 'عن التطبيق',
  disconnect: 'تسجيل الخروج',
  menu: 'الرجوع إلى القائمة',
  password: 'كلمة المرور',
  editProfile: {
    title: 'تعديل ملفي الشخصي',
    emailAddress: 'عنوان البريد الإلكتروني',
    addAddress: 'إضافة عنوان بريد إلكتروني',
    phone: 'الهاتف',
    addPhone: 'إضافة رقم هاتف',
    phoneNumber: 'رقم الهاتف'
  },
  endUserLevelAgreement: 'شروط الاستخدام',
  otp: {
    invalidCode: 'الرمز غير صالح'
  },
  emailUpdate: {
    info: 'يمكنك تحديد عنوان بريد إلكتروني جديد لحسابك.',
    validationCode: 'لتأكيد أن هذا العنوان، سنرسل لك رمز التحقق من صحة العنوان.',
    waitBeforeRetry: 'الرجاء الانتظار {duration} قبل إعادة المحاولة',
    sentValidationCode: 'لتأكيد أن هذا العنوان, هو عنوانك بالفعل، لقد قمنا بإرسال رمز التحقق إلى عنوانك الإلكتروني الجديد.',
    verifyCode: 'التحقق من الرمز',
    success: 'تم تحديث عنوان البريد الإلكتروني بنجاح',
    error: {
      alreadyUsedEmailAddress: 'لقد تم استخدام هذا العنوان الإلكتروني مسبقًا'
    }
  },
  mobilePhoneUpdate: {
    info: 'يمكنك تحديد رقم هاتف جديد لحسابك.',
    validationCode: 'لتأكيد أن هذا الرقم هو رقمك بالفعل، سنرسل لك رمز التحقق عبر الرسائل النصية.',
    waitBeforeRetry: 'الرجاء الانتظار {duration} قبل إعادة المحاولة',
    sentValidationCode: 'لتأكيد أن هذا الرقم هو رقمك بالفعل، قمنا بإرسال رمز التحقق إلى رقمك الجديد.',
    verifyCode: 'التحقق من الرمز',
    success: 'تم تحديث رقم الهاتف بنجاح'
  }
}
