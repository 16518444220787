export default {
  plannedActivities: 'Planned activity | Planned activities',
  noPlannedActivity: {
    title: 'No planned activity',
    subtitle: 'You do not have any planned activity this day. To see upcoming activities, look at your agenda.',
    alternativeSubtitle: 'You do not have any planned activity this day.'
  },
  freeActivities: 'Free activity | Free activities',
  myFreeActivities: 'My free activities',
  add: 'Add activity',
  seeAll: 'see all the free activities',
  less: 'see less free activities'
}
