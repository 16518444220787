export default {
  pageTitle: 'Éditeur d\'interface',
  pageHelper: 'Pages personnalisables',
  sectionHelper: 'Sections de page',
  saveButton: 'Enregistrer les modifications',
  saveSuccess: 'Vos modifications d\'interface ont bien été enregistrées.',
  saveFailure: 'Vos modifications n\'ont pas pu être enregistrées',
  page: {
    patientFileSynthesis: 'Synthèse de dossier patient',
    proDashboard: 'Tableau de bord / Accueil'
  },
  block: {
    patientFilesGraph: 'Graphiques',
    patientFilesContentScheduler: 'Planification et contenu',
    patientFilesIncomingData: 'Données de télésurveillance',
    patientFilesTableValues: 'Tableaux de valeurs',
    proDashboardGraphs: 'Connexions et activités',
    proDashboardAlerts: 'Alertes',
    proDashboardObservanceAlerts: 'Alertes de non-observance',
    proDashboardDocsMessenger: 'Documents et messagerie'
  }
}
