export default {
  tasks: {
    title: 'Mes Tâches',
    subtitle: 'Mes actions à compléter'
  },
  monitoring: {
    title: 'Mon Suivi',
    subtitle: 'Mes données et mes contenus'
  },
  messenger: {
    title: 'Ma Messagerie',
    subtitle: 'Mes échanges avec l\'équipe'
  },
  documents: {
    title: 'Mes Documents',
    subtitle: 'Mes documents échangés avec l\'équipe'
  },
  agenda: 'Mon Agenda',
  newTasks: 'Tâche à accomplir | Tâches à accomplir',
  newMessages: 'Nouveau message | Nouveaux messages',
  newDocuments: 'Nouveau document | Nouveaux documents',
  closed: 'Dossier Clôturé',
  noPendingTasks: 'Aucune tâche en attente',
  inPriority: 'En priorité',
  scheduledToday: 'Planifié aujourd\'hui',
  upcoming : 'À venir',
  passwordGeneration: {
    title: 'Identifiant de connexion du patient',
    firstLoginUsername: 'Identifiant de première connexion :',
    email: 'Adresse e-mail :',
    mobilePhone: 'Téléphone mobile :',
    password: 'Mot de passe :',
    info: {
      part1: 'Veuillez ',
      part2: 'sauvegarder ce mot de passe',
      part3: ' en toute sécurité avant de continuer.',
      part4: ' Ce mot de passe est nécessaire pour la ',
      part5: 'première connexion du patient'
    }
  },
  form: {
    generalInfo: 'Informations générales',
    firstNames: 'Prénom(s) de naissance',
    firstName: '1er prénom de naissance',
    birthName: 'Nom de naissance',
    firstNameUsed: 'Prénom utilisé',
    lastNameUsed: 'Nom utilisé',
    sex: 'Sexe',
    birthDate: 'Date de naissance',
    birthPlaceInsee: 'Lieu de naissance - Code INSEE',
    coordinates: 'Coordonnées',
    email: 'Adresse email',
    mobilePhone: 'Numéro de téléphone',
    digitalIdentity: 'Identité numérique',
    ins: 'Matricule INS',
    oid: 'OID',
    insiIdentityAttribute: 'Attribut du statut de l’identité numérique',
    insi: {
      search: {
        info: {
          part1: 'Pour effectuer une recherche sur l\'INSi,',
          part2: 'vous devez au moins renseigner le nom de naissance, le(s) prénom(s) de naissance,',
          part3: 'la date de naissance ainsi que le sexe (homme ou femme).'
        },
        search: 'Recherche INSI'
      },
      verification: {
        info: {
          part1: 'Pour effectuer une vérification d\'identité sur l\'INSI,',
          part2: 'vous devez au moins renseigner le nom de naissance, le(s) prénom(s) de naissance,',
          part3: 'la date de naissance ainsi que le sexe (homme ou femme), le matricule INS et l\'OID.'
        },
        verification: 'Vérification INSI'
      }
    },
    error: {
      alreadyUsedEmailAddress: 'Cette adresse email est déjà utilisée',
      emailOrMobilePhone: 'Email ou téléphone requis'
    },
    updateWithIdentityProof: 'Je suis un professionnel de santé et je souhaite modifier l\'identité de ce patient sur la base d\'un justificatif d\'identité',
    additionalInfo: 'Informations complémentaires',
    globalReferenceValue: 'Valeur de référence globale :',
    identity: {
      doubtful: 'Identité douteuse',
      fictitious: 'Identité fictive',
      namesake: 'Identité homonyme'
    },
    success: {
      creation: 'Patient créé avec succès',
      update: 'Patient édité avec succès'
    }
  }
}
