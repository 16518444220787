import { ref } from 'vue'
import { defineRouter } from '#q-app/wrappers'
import { createMemoryHistory, createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import { addCommonRoutes } from './common-routes.js'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

const ready = ref(false)

export function onReady() {
  ready.value = true
}

export default defineRouter(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const router = createRouter({
    scrollBehavior: () => ({
      left: 0,
      top: 0
    }),
    routes: [ // You MUST NOT edit this, use router-{profile}.js to define routes!
      {
        path: '/:pathMatch(.*)*',
        name: 'loading'
      }
    ],
    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE)
  })

  addCommonRoutes(router)

  router.beforeEach((to, from, next) => {
    if (ready.value) {
      next()
    } else {
      next(false)
    }
  })

  /**
   * Handle missing chunks after a deployment of new static files that replace previous ones
   * the current user might need and hasn't loaded yet.
   */
  const loadingChunkFailedErrorRegex = /loading .*?chunk .*?failed/i
  router.onError((error, to/*, from */) => {
    if (loadingChunkFailedErrorRegex.test(error.message)) {
      window.location.assign(to.fullPath)
    }
  })

  return router
})
