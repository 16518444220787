<template>
  <template v-if="loading">
    Chargement...
  </template>
  <router-view :key="path" />
</template>

<script setup>
  import { useAuth } from 'src/modules/core/composables/useAuth'
  import { onMounted } from 'vue'
  import { useSessionExpiration } from 'src/modules/core/composables/useSessionExpiration'
  import { useRoute } from 'vue-router'
  import { useQuasar } from 'quasar'
  import dayjs from 'dayjs'

  const languages = import.meta.glob('../node_modules/quasar/lang/(fr|en-US|ar).js')

  const { onCheckSession, loading } = useAuth()
  const route = useRoute()
  const $q = useQuasar()

  // The router view of app (the layout of the app) must only be redrawn if the prefix of the route change and must not change when only the child router change.
  let path = route.matched[0]?.path || ''
  for (const param in route.params) {
    path = path.replace(':' + param, route.params[param] || '')
  }

  onMounted(() => {
    onCheckSession()

    let language = localStorage.getItem('lang') || 'fr'
    const locale = new Intl.Locale(language)

    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', language)
    }

    if (locale.textInfo) {
      document.documentElement.setAttribute('dir', locale.textInfo.direction ?? 'ltr')
    } else {
      document.documentElement.setAttribute('dir', language === 'ar' ? 'rtl' : 'ltr')
    }

    if (language === 'en') {
      language += '-US'
    }
    dayjs.locale(language)

    languages[`../node_modules/quasar/lang/${language}.js`]().then(lang => {
      $q.lang.set(lang.default)
      $q.lang.rtl = language === 'ar'
    })
  })

  useSessionExpiration()
</script>
