export default {
  title: 'Ajouter des documents',
  subtitle: 'Vous pouvez ajouter un titre ainsi qu\'une description à vos documents',
  uploaderTitle: 'Déposez un fichier ou cliquez ici pour télécharger vos documents',
  externalFileUrl: 'Lien',
  addFiles: 'Ajouter un fichier',
  addMyDocument: 'Ajouter mon document',
  addDocument: 'Ajouter un document',
  status: 'Statut d\'acquittement',
  showToPatientAndCaregivers: 'Afficher le document au patient et à ses aidants',
  identityDocument: 'Document d\'identité de référence',
  startDate: 'Date de début',
  endDate: 'Date de fin',
  reminderDate: 'Date de rappel',
  file: 'Fichier',
  link: 'Lien Youtube',
  additionSuccess: 'Document ajouté avec succès',
  close: {
    search: 'Fermer la recherche',
    sortOptions: 'Fermer les options de tri',
    filters: 'Fermer les filtres'
  },
  listOfDocument: {
    type: 'Type',
    appointment: 'Rendez-vous',
    document: 'Document',
    information: 'Information',
    teleconsultation: 'Téléconsultation',
    report: 'Compte rendu'
  },
  listOfTypeOfDocuments: {
    typeOfDocument: 'Type de document',
    telemonitoringPrescription: 'Prescription de télésurveillance',
    prescription: 'Prescription de télésurveillance',
    medicationPrescription: 'Ordonnance',
    analysisResults: 'Résultats d\'analyse',
    image: 'Imagerie',
    therapeutic: 'Document thérapeutique',
    information: 'Document d\'information',
    report: 'Compte-rendu',
    other: 'Autres',
    identityDocument: 'Document d\'identité'
  },
  displayDocumentList: {
    title: 'Liste des documents',
    subtitle: 'Vous pouvez voir la liste de tous vos documents',
    tableInput: {
      title: 'Liste de mes documents',
      titleOfDocument: 'Titre',
      typeOfDocument: 'Type de document',
      addDate: 'Date d\'ajout',
      addBy: 'Ajouté par',
      created: 'Créé le',
      end: 'Fin le',
      tooltipForEdit: 'Modifier',
      tooltipForDelete: 'Supprimer',
      sortByDate: 'Trier par date',
      noData: 'Aucun document ne correspond à votre recherche'
    }
  },
  acquittal: {
    acquit: 'Acquitter',
    message: 'Message',
    acquitted: 'Acquitté'
  },
  noDocument: {
    title: 'Vous n\'avez pas de document à consulter.',
    subtitle: 'Vous pouvez dès à présent échanger des documents avec votre équipe médicale',
    subtitle2: ', en appuyant sur le bouton "ajouter un document" ci-dessous.'
  },
  documentOrderBy: {
    name: 'Nom',
    creationDate: 'Date d\'ajout'
  },
  displayDocument: {
    sentBy: '{fileSize} envoyé par {provider}',
    acknowledgmentFailure: 'Impossible d\'acquitter le fichier. Veuillez réessayer.',
    deletionConfirm: 'Vous êtes sur le point de supprimer ce fichier. Continuer ?',
    deletionSuccess: 'Fichier supprimé avec succès.',
    deletionFailure: 'Impossible de supprimer le fichier.',
    waitingForAcknowledgment: 'En attente d\'acquittement par un professionnel',
    generalInformations: {
      title: 'Informations générales',
      patient: 'Patient',
      documentType: 'Type de document',
      creationDate: 'Date d\'ajout',
      prescriptionDate: 'Date de début de prescription',
      endPrescriptionDate: 'Date de fin de prescription',
      reminder: 'Date de rappel',
      actions: 'Actions disponibles'
    },
    error: 'Le document n\'a pas pu être affiché,\nvous pouvez essayer de le télécharger.',
    download: 'Télécharger le document',
    isOpening: 'Le document va s\'ouvrir dans la visionneuse par défaut de votre mobile.'
  },
  dialog: {
    edit: 'Éditer un document',
    acquit: 'Acquitter un document',
    renew: 'Renouveler un document'
  },
  description: 'Description',
  prescription: {
    deletion: {
      confirmation: 'Vous êtes sur le point de supprimer la prescription. Voulez-vous continuer ?',
      success: 'La prescription a bien été supprimée.',
      error: 'Impossible de supprimer la prescription.'
    },
    status: {
      waiting: 'À venir',
      ongoing: 'En cours',
      expired: 'Expirée',
      awaitingRenewal: 'En attente de renouvellement'
    }
  },
  identityType: {
    errors: {
      load: 'Impossible de charger les types de document d\'identité',
      creation: 'Impossible de créer le type de document d\'identité {label}',
      edition: 'Impossible de modifier le type de document d\'identité {label}',
      deletion: 'Le type de document d\'identité {label} est utilisé, il ne peut pas être supprimé',
      used: 'Impossible de supprimer le type de document d\'identité {label}'
    }
  },
  therapeuticContent: {
    addedOn: 'Ajouté le {date}',
    video: 'Vidéo',
    document: 'Document',
    image: 'Image',
    youtube: 'Vidéo Youtube'
  }
}
