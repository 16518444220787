export default {
  title: 'Patient File',
  subtitle: 'File Information',
  close: 'Close the file',
  creationDate: 'Created on ',
  history: {
    entries: {
      user: {
        update: {
          title: 'Profile Update',
          subtitle: 'updated the patient\'s profile'
        },
        insiSearch: {
          title: 'Identity Search',
          subtitle: 'searched the patient\'s identity via INSi'
        },
        insiVerification: {
          title: 'Identity Verification',
          subtitle: 'verified the patient\'s identity via INSi'
        },
        insiVerificationFailure: {
          title: 'Identity Verification Error',
          subtitle: 'could not verify the patient\'s identity via INSi'
        }
      },
      NonObservanceAlert: {
        create: {
          title: 'Non-Compliance Alert',
          subtitle: 'The patient did not respond to the questionnaire'
        },
        update: {
          title: 'Non-Compliance Alert',
          subtitle: 'The patient still hasn\'t responded to the questionnaire'
        }
      },
      formResponse: {
        submit: {
          title: 'Questionnaire Response',
          subtitle: 'responded to the questionnaire'
        }
      },
      cpiaPayload: {
        create: {
          title: 'New CPIA Data',
          subtitle: 'New measures for CPIA indicators'
        }
      },
      customReferenceValue: {
        create: {
          title: 'Reference Value Update',
          subtitle: 'entered the reference value {referenceValue} for the indicator'
        },
        delete: {
          title: 'Reference Value Deactivation',
          subtitle: 'deactivated the reference value for the indicator'
        }
      },
      document: {
        stream: {
          title: 'Consulting a {documentType}',
          subtitle: 'consulted the {documentType}'
        },
        download: {
          title: 'Downloading a {documentType}',
          subtitle: 'downloaded the {documentType}'
        },
        acquit: {
          title: 'Document Acknowledgment',
          subtitle: 'acknowledged the document'
        }
      },
      file: {
        stream: {
          title: 'Consulting a {documentType}',
          subtitle: 'consulted the {documentType}'
        }
      },
      medialPayload: {
        create: {
          title: 'New Session Data',
          subtitle: 'New measures for session indicators'
        }
      },
      propertyValue: {
        create: {
          title: 'Additional Parameter Entry',
          subtitle: 'entered the value for the additional parameter {propertyName}'
        },
        update: {
          title: 'Additional Parameter Update',
          subtitle: 'updated the value for the additional parameter {propertyName}'
        },
        delete: {
          title: 'Additional Parameter Deactivation',
          subtitle: 'deactivated the additional parameter'
        }
      },
      patientFile: {
        addPatientFileCaregiver: {
          title: 'Adding a Caregiver',
          subtitle: 'was added by {sourceFirstName} {sourceLastName} to the caregiver list'
        },
        removePatientFileCaregiver: {
          title: 'Removing a Caregiver',
          subtitle: 'was removed by {sourceFirstName} {sourceLastName} from the caregiver list'
        },
        createIndicatorBoundary: {
          title: 'Alert Threshold Customization',
          subtitle: 'customized an alert threshold on the indicator {target}. An alert {alertLevel} is triggered if the value of the indicator is {operator} at {subtitleSuffix}'
        },
        updateIndicatorBoundary: {
          title: 'Alert Threshold Customization Update',
          subtitle: 'modified the alert threshold customization of the indicator {target}. An alert {alertLevel} is triggered if the value of the indicator is {operator} at {subtitleSuffix}'
        },
        deleteIndicatorBoundary: {
          title: 'Alert Threshold Customization Deletion',
          subtitle: 'removed the alert threshold customization {alertLevel} for operator {operator} on the indicator {target}'
        },
        deactivateIndicatorBoundary: {
          title: 'Deactivation of Alert Threshold',
          subtitle: 'deactivated the alert threshold {alertLevel} for operator {operator} on the indicator {target}'
        },
        close: {
          title: 'File Closure',
          subtitle: 'closed the file'
        },
        automaticallyClose: {
          title: 'Automatic File Closure',
          subtitle: 'The file was automatically closed because its end date was exceeded.'
        }
      },
      rendezVous: {
        create: {
          title: 'Creating an Appointment',
          subtitle: 'created the appointment'
        },
        update: {
          title: 'Updating an Appointment',
          subtitle: 'updated the appointment'
        },
        delete: {
          title: 'Deleting an Appointment',
          subtitle: 'deleted the appointment'
        },
        title: 'Rendez-vous',
        subtitle: 'Allows to plan a rendez-vous',
        new: 'Create a rendez-vous',
        form: {
          shareWithMedicalTeam: 'Share with medical team',
          date: 'Date',
          from: 'from',
          to: 'to',
          addReminder: 'Add a reminder',
          delay: 'Delay',
          success: 'Rendez-vous was created succesfully',
          failure: 'Unable to create rendez-vous'
        }
      },
      reminder: {
        create: {
          title: 'Creating a Reminder',
          subtitle: 'created the reminder'
        },
        update: {
          title: 'Updating a Reminder',
          subtitle: 'updated the reminder'
        },
        delete: {
          title: 'Deleting a Reminder',
          subtitle: 'deleted the reminder'
        },
        title: 'Reminder',
        subtitle: 'Allows to plan a reminder',
        new: 'Create a reminder',
        form: {
          date: 'Date of reminder',
          time: 'Time of reminder',
          shareWithMedicalTeam: 'Share with medical team',
          success: 'Reminder was created succesfully',
          failure: 'Unable to create reminder'
        }
      },
      note: {
        addNote: {
          title: 'Adding a Note',
          subtitle: 'added a note'
        },
        editNote: {
          title: 'Editing a Note',
          subtitle: 'edited a note'
        },
        archiveNote: {
          title: 'Archiving a Note',
          subtitle: 'archived a note'
        }
      },
      patientFileProtocol: {
        activate: {
          title: 'Activating a Protocol',
          subtitle: 'activated the protocol'
        },
        deactivate: {
          title: 'Deactivating a Protocol',
          subtitle: 'deactivated the protocol'
        },
        restore: {
          title: 'Restoring a Protocol',
          subtitle: 'restored the protocol'
        }
      },
      protocol: {
        assign: {
          title: 'Assigning a Protocol',
          subtitle: 'assigned the protocol'
        },
        externalAssign: {
          title: 'Assigning a Protocol',
          subtitle: 'Protocol assignment'
        }
      },
      custom: {
        hospitalization: {
          title: 'Hospitalization',
          subtitle: 'The patient was hospitalized'
        },
        other: {
          title: 'Other Activities',
          subtitle: '{description}'
        }
      }
    }
  },
  content: {
    item: {
      restore: 'Restore the content for the file',
      restoreError: 'An error occurred while restoring the content.'
    }
  },
  about: 'Your patient file has been created on {creationDate} in service {serviceName} of establishment {establishmentName}',
  monitoring: {
    loading: 'Your monitoring is loading',
    history: {
      title: 'My history',
      noData: 'No data',
      actor: {
        you: 'You have',
        him: 'has '
      },
      consulted: ' consulted on',
      responded: ' responded on '
    },
    data: 'My last data',
    content: 'My therapeutic content',
    noContent: {
      firstLine: 'No content available yet.',
      secondLine: 'Your therapeutic education will be available when the healthcare professional will add it.'
    },
    noData: {
      firstLine: 'No data available yet',
      secondLine: 'Your graph measures, your therapeutic education and your history will be available as soon as measures will be added.'
    }
  },
  payloads: {
    question: 'Question',
    answer: 'Answer',
    alertStatus: 'Alert status',
    showQuestionsLabel: 'Show questions label',
    receivedImage: 'Received image: ',
    loadingFailure: 'Data lading failed',
    alert: 'Alert',
    closed: 'Closed',
    pending: 'Pending',
    comment: 'Comment',
    alertConditions: {
      show: 'Show alert conditions',
      hide: 'Hide alert conditions'
    },
    newMeasureCondition: {
      display: 'Display new measure condition',
      hide: 'Hide new measure condition'
    },
    formCondition: 'Form condition that generated this measure: ',
    condition: 'Condition that generated this alert: '
  },
  graphs: {
    lastMeasures: 'Retrieving your latest measurements',
    noData: 'No data available',
    error: {
      load: 'Unable to retrieve chart measurements {chart}',
      graphSettings: 'Unable to load chart settings'
    },
    in: 'in',
    referenceValue: 'Reference value',
    minimum: 'Minimum{markLineNameSuffix}',
    maximum: 'Maximum{markLineNameSuffix}',
    median: 'Median{markLineNameSuffix}',
    average: 'Average{markLineNameSuffix}'
  },
  contextSelector: {
    service: 'Service:',
    createdOn: 'Created on {date}',
    inService: 'in the service',
    closed: 'Closed'
  }
}
