export const baseRoutes = [
  {
    path: '/context-selector',
    name: 'context-selector',
    component: () => import('src/modules/core/views/ContextSelector.vue')
  },
  {
    path: '/',
    name: 'homepage',
    redirect: '/context-selector'
  },
  {
    name: 'not-authorized',
    path: '/not-authorized',
    component: () => import('src/modules/core/views/ErrorNotAuthorized.vue')
  },
  // MUST be loaded after all others routes
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: '/'
  }
]
