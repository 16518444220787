export default {
  title: 'Parcours de soins',
  subtitle: 'Vous pourrez voir l\'ensemble de vos documents, prescriptions, séances de téléconsultation et rendez-vous',
  creationDate: 'Date de création',
  startDate: 'Date de début',
  endDate: 'Date de fin',
  addBy: 'Ajouté par ',
  viewBy: 'Vu par ',
  today: 'Aujourd\'hui',
  scheduledToday: 'Prévu aujourd\'hui',
  noScheduledToday: 'Pas d\'événement aujourd\'hui',
  allEvents: 'Tous mes événements',
  status: {
    available: 'Disponible',
    ongoing: 'En cours',
    completed: 'Complété',
    expired: 'Expiré',
    upcoming: 'À venir',
    free: 'Libre'
  },
  acceptedDelay: 'Réponse acceptée jusqu\'au {limitDate}',
  answeredOn: 'Répondu le {answerDate}',
  form: 'Questionnaire',
  myForm: 'Mon questionnaire',
  formInfo: 'Informations sur ce questionnaire',
  summary: 'Résumé de votre questionnaire',
  freeSubmission: 'Soumission libre',
  sendingForm: 'Envoi de votre questionnaire en cours',
  loadingForm: 'Chargement du questionnaire en cours',
  sendDelay: 'À envoyer avant le',
  sendSuccess: 'Le questionnaire a été envoyé avec succès',
  alreadySent: 'La réponse a déjà été envoyée',
  date: {
    allDay: 'Toute la journée',
    sameDate: 'Le {date}',
    differentTime: 'Le {startDate} - {endDate}',
    from: 'Du {startDate} au {endDate}',
    at: 'à',
    included: 'inclus',
    on: 'le'
  },
  unknownEvent: 'Événement non reconnu',
  lateEvents: {
    title: 'Vous avez des questionnaires en retard',
    subtitle: 'Vous devriez répondre aux questionnaires en retard dans l\'ordre en commençant par :',
    oldestLateResponse: 'Je réponds au questionnaire le plus en retard',
    continue: 'Je souhaite continuer (les précédents questionnaires en retard ne seront plus disponibles)'
  },
  mandatoryFieldError: 'Il y a un champ obligatoire vide | Il y a des champs obligatoires vides',
  answerSubmit: {
    title: 'Questionnaire envoyé avec succès !',
    subtitle: 'Consultez votre tableau de bord pour voir les autres activités qui vous sont proposées',
    goToDashboard: 'Tableau de bord'
  },
  chooseLanguageForm: 'Choisissez dans quelle langue répondre au formulaire',
  formLanguageTitle: 'Langue du formulaire',
  answerDate: 'Date de réponse : {date}',
  formResponseDataDeactivated: 'Une donnée désactivée invalide également les caluls qui l\'utilisent',
  formResponseLoadFailure: 'Impossible de charger les réponses du formulaire'
}
