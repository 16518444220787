export default {
  required: 'This field is required',
  email: 'Incorrect email address',
  birthName: 'The birth name is incorrect, it must not contain accents or diacritical marks. You can enter up to 100 characters',
  firstNames: 'Invalid first names',
  specialCharacters: 'This field must not contain special characters',
  emailLength: 'The email address must not exceed 180 characters',
  textFieldLength: 'This field must be between 2 and 100 characters',
  textAreaLength: 'This field must be between 2 and 500 characters',
  phone: 'Incorrect phone number',
  patientIdentifier: 'Incorrect social security number, it must contain 13 digits',
  inseeCode: 'The INSEE code must be a 5-character alphanumeric code',
  ins: 'Invalid number',
  activeGlobalReferenceValue: 'The reference value must be an integer'
}
