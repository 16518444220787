export default {
  title: 'Mon établissement',
  subtitle: 'Mon établissement avec ses services et ses membres',
  create: 'Ajouter un établissement',
  services: {
    title: 'Services',
    subtitle: 'Liste des services',
    create: 'Ajouter un service',
    noServicesTitle: 'Aucun service',
    noServicesSubtitleCanCreate: 'Aucun service trouvé pour cet établissement, vous pouvez en créer un en cliquant sur le bouton ci-dessus.',
    noServicesSubtitleCantCreate: 'Aucun service trouvé pour cet établissement, référez-vous à votre administrateur pour en créer un.'
  }
}
