export default {
  title: 'My Establishment',
  subtitle: 'My establishment with its services and members',
  create: 'Add an establishment',
  services: {
    title: 'Services',
    subtitle: 'List of services',
    create: 'Add a service',
    noServicesTitle: 'No services',
    noServicesSubtitleCanCreate: 'No services found for this establishment, you can create one by clicking the button above.',
    noServicesSubtitleCantCreate: 'No services found for this establishment, please refer to your administrator to create one.'
  }
}
