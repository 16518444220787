export default {
  title: 'Messaging',
  subtitle: 'Find all your discussions with your healthcare staff',
  writeMessage: 'Enter your message',
  addMultimediaMessage: 'Attach a file',
  sendMessage: 'Send',
  qDialogMessageTitle: 'MMS',
  qDialogMessageClose: 'Close',
  messageImage: {
    thumbnail: 'Image sent in chat',
    image: 'Image in full size'
  },
  inactive: 'Messenger is not activated on your patient file',
  caution: 'Attention: it is not an emergency channel. Please contact emergencies in case of worrying events',
  noMessage: 'No message.',
  addDocument: 'Add a document',
  loadFailure: 'Unable to load message list',
  sendFailure: 'Unable to send a message',
  deletionFailure: 'Unable to delete attachment',
  acknowledge: 'I take acknowledgment of this message',
  imageUnavailable: 'The image is no longer available'
}
