import { areSame, empty } from 'src/utils/variable'

export function deepCopy(object) {
  return JSON.parse(JSON.stringify(object))
}

export function shallowCopy(object) {
  return { ...object }
}

export function pick(object, array) {
  const result = {}
  for (const key of array) {
    if (typeof object[key] !== 'undefined') {
      result[key] = object[key]
    }
  }
  return result
}

/**
 * Call it with and empty formData and your object
 *
 * @param formData
 * @param data
 * @param _key
 */
export function objectToFormData(formData, data, _key) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      objectToFormData(formData, data[key], _key ? `${_key}[${key}]` : key)
    })
  } else {
    const value = data == null ? '' : data

    formData.append(_key, value)
  }
}

export function getLastIndex(obj) {
  return Object.keys(obj)[Object.keys(obj).length - 1]
}

export function objectFromArray(array, key) {
  const initialValue = {}
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item
    }
  }, initialValue)
}

export function clean(obj, replacement, recursive = true) {
  for (const key in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      continue
    }

    if (empty(obj[key])) {
      if (typeof replacement !== 'undefined') {
        obj[key] = replacement
      } else {
        delete obj[key]
      }
    } else if (recursive && typeof obj[key] === 'object') {
      clean(obj[key], replacement)
      if (!Object.keys(obj[key]).length) {
        if (typeof replacement !== 'undefined') {
          obj[key] = replacement
        } else {
          delete obj[key]
        }
      }
    }
  }
  return obj
}

export function combine(keys, values) {
  const entries = []
  for (const i in keys) {
    if (!Object.prototype.hasOwnProperty.call(keys, i)) {
      continue
    }
    entries.push([keys[i], values[i]])
  }
  return Object.fromEntries(entries)
}

/**
 * Apply a map method to an object
 * The callable will receive as argument the key and the value and it must return the new key and new value
 */
export function mapObject(object, callable) {
  const result = {}
  Object.keys(object).map((key) => {
    const child = callable(key, object[key])
    result[child[0]] = child[1]
  })
  return result
}

/**
 * Flatten an object passed as the first parameter using the callable as the second parameter.
 * The callable will receive as argument the key, the value,
 * a "push" callable called to push the current value into the results (no argument),
 * a "flatten" callable to recursively flatten the object which is passed to it as an argument.
 */
export function flattenObjectByCallable(object, callable) {
  let flattened = {}
  const cb = object => Object.entries(object).forEach(([key, value]) => {
    const push = (key, value) => {
      flattened[key] = value
    }
    const flattenChildren = (children) => {
      flattened = { ...flattened, ...flattenObjectByCallable(children, callable) }
    }
    callable(key, value, push, flattenChildren)
  })
  cb(object)
  return flattened
}

export function areSameObject(a, b) {
  const aEmpty = empty(a)
  const bEmpty = empty(b)

  if (aEmpty && bEmpty) {
    return true
  }

  // One of the 2 objects is empty
  if ((aEmpty && !bEmpty) || (!aEmpty && bEmpty)) {
    return false
  }

  const aKeys = Object.keys(a)
  const bKeys = Object.keys(b)

  // The keys of the 2 objects are different
  if (Object.keys(b).filter(bKey => aKeys.includes(bKey)).length !== aKeys.length) {
    return false
  }

  if (Object.keys(a).filter(aKey => bKeys.includes(aKey)).length !== bKeys.length) {
    return false
  }

  // One of the object values differs
  return aKeys.every(key => areSame(a[key], b[key]))
}
