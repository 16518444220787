/*
 * Copyright (C) TMM Software 2021 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

const LEVELS = ['verbose', 'info', 'warning', 'error']
let minimumLevel = 'info'

const handlers = []

// Call every registered handlers if log level is sufficient
function createLog(level, messages) {
  const levelIndex = LEVELS.indexOf(level)
  const MINIMUM_LEVEL_INDEX = LEVELS.indexOf(minimumLevel)

  if (levelIndex < MINIMUM_LEVEL_INDEX) {
    return
  }

  for (const handler of handlers) {
    handler(level, ...messages)
  }
}

export function setMinimumLevel(level) {
  minimumLevel = level
}

// Register a new log handler : A callback that will be called with theses parameters :
// level, ...messages
export function addHandler(handler) {
  handlers.push(handler)
}

// noinspection JSUnusedGlobalSymbols
export default {
  log: (...messages) => {
    createLog('verbose', messages)
  },
  info: (...messages) => {
    createLog('info', messages)
  },
  warn: (...messages) => {
    createLog('warning', messages)
  },
  error: (...messages) => {
    createLog('error', messages)
  }
}
