export default {
  title: 'Questionnaires',
  subtitle: 'List of Questionnaires',
  create: 'Add a Questionnaire',
  submissionType: {
    type: 'Submission type',
    free: 'Free',
    planned: 'Planned'
  },
  questions: {
    fileType: {
      hint: 'Accepted formats: png, jpeg, pdf'
    }
  },
  computed: {
    noValue: 'No value'
  },
  errors: {
    name: {
      NOT_UNIQUE_ERROR: 'A form with the same name already exists in this establishment'
    },
    reference: {
      NOT_UNIQUE_ERROR: 'A form with the same reference already exists in this establishment'
    },
    load: 'An error occurred while opening the form. Please refresh the page and try again.'
  }
}
