export default {
  errors: {
    name: {
      NOT_UNIQUE_ERROR: 'A unit with the same name already exists'
    },
    symbol: {
      NOT_UNIQUE_ERROR: 'A unit with the same symbol already exists'
    }
  }
}
