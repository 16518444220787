export default {
  required: 'هذا الحقل إلزامي',
  email: 'عنوان البريد الإلكتروني غير صحيح',
  birthName: 'اسم الميلاد غير صحيح، يجب ألا يحتوي على حروف مشددة أو علامات تشكيل. يمكنك إدخال حتى 100 حرف',
  firstNames: 'الأسماء غير صالحة',
  specialCharacters: 'يجب ألا يحتوي هذا الحقل على أحرف خاصة',
  emailLength: 'يجب ألا يتجاوز عنوان البريد الإلكتروني 180 حرفًا',
  textFieldLength: 'يجب أن يتراوح هذا الحقل بين 2 و 100 حرف',
  textAreaLength: 'يجب أن يتراوح هذا الحقل بين 2 و 500 حرف',
  phone: 'رقم الهاتف غير صحيح',
  patientIdentifier: 'رقم التأمين الاجتماعي غير صحيح، يجب أن يحتوي على 13 رقمًا',
  inseeCode: 'رمز INSEE يجب أن يكون رمزًا أبجديًا رقميًا من 5 أحرف',
  ins: 'رقم غير صالح',
  activeGlobalReferenceValue: 'قيمة المرجعية يجب أن تكون عددًا صحيحًا'
}
