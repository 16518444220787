export default {
  if: 'Si :',
  then: 'Alors :',
  is: 'est ',
  referenceValue: 'La valeur de référence',
  indicatorBoundary: 'la borne de l\'indicateur',
  defined: 'définie pour une alerte',
  plus: 'plus',
  minus: 'moins',
  atLeastOne: 'au moins une de ces conditions est vraie',
  all: 'toutes les conditions sont vraies',
  answer: 'La réponse à la question',
  positive: 'Positive',
  negative: 'Négative',
  formElementType: {
    calculation: 'Le calcul',
    property: 'Le paramètre supplémentaire',
    score: 'Le score'
  },
  unnamed: 'sans nom renseigné',
  notSpecified: 'n\'a pas été renseigné',
  specified: 'a été renseigné',
  containChoice: 'contient le choix',
  matchChoice: 'correspond au choix',
  responseValue: {
    noValue: 'n\'ayant pas de valeur au moment de la réponse',
    value: 'valant {value} au moment de la réponse'
  },
  createMesure: 'Créer une nouvelle mesure pour l\'indicateur',
  worth: 'valant',
  conditionOperators: {
    equals: 'égal à',
    greater: 'supérieur à',
    less: 'inférieur à',
    greatEqual: 'supérieur ou égal à',
    lessEqual: 'inférieur ou égal à'
  }
}
