export default {
  title: 'البيانات الخارجية',
  subtitle: 'قائمة البيانات الخارجية',
  create: 'إضافة بيانات خارجية',
  displayHistoryTooltip: 'عرض تاريخ الإصدارات',
  disableDataTooltip: 'تعطيل هذه البيانات',
  disableDataDate: 'تم التعطيل في',
  activateDataTooltip: 'تفعيل هذه البيانات',
  alertDisable: 'هل أنت متأكد من أنك تريد تعطيل البيانات؟'
}
