export default {
  errors: {
    name: {
      not_unique_error: 'مورد IoT بنفس الاسم موجود بالفعل'
    }
  },
  title: 'أشيائي المتصلة',
  noDevice: {
    patientSide: 'لا يوجد لديك جهاز متصل.',
    withings: 'المريض ليس لديه جهاز أو لم يقم بربط حسابه مع Withings',
    elsewhere: 'لا يوجد جهاز متصل.'
  },
  connectMedisante: {
    patientSide: 'اختَر الجهاز Medisante لربطه بسجل المريض الخاص بك',
    elsewhere: 'اختَر الجهاز Medisante لربطه بسجل المريض'
  },
  connectWithings: {
    patientSide: 'اختَر الجهاز Withings لربطه بسجل المريض الخاص بك',
    elsewhere: 'اختَر الجهاز Withings لربطه بسجل المريض'
  },
  connectWithingsCellular: {
    patientSide: 'اختَر الجهاز Withings Cellular لربطه بسجل المريض الخاص بك',
    elsewhere: 'اختَر الجهاز Withings Cellular لربطه بسجل المريض'
  },
  add: {
    title: 'إضافة جهاز متصل',
    subtitle: {
      medisante: 'إضافة جهاز Medisante',
      withingsCellular: 'إضافة جهاز Withings Cellular'
    }
  },
  withingsConfirm: {
    connect: 'سيتم توجيهك إلى موقع Withings لربط حسابك بـ Aptelecare. إذا لم يكن لديك حساب Withings، يمكنك إنشاء واحد. هل ترغب في المتابعة؟',
    disconnect: 'أنت على وشك فصل حسابك من Withings. لن يكون لدى سجلات المرضى أي وصول إلى البيانات من جهازك Withings. هل ترغب في المتابعة؟'
  },
  battery: {
    low: 'البطارية ضعيفة، يُنصح بشحنها أو تغييرها في أقرب وقت ممكن',
    aboveThirty: 'البطارية مشحونة بأكثر من 30% من سعتها',
    aboveSeventy: 'البطارية مشحونة بأكثر من 75% من سعتها'
  }
}
