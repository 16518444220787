import { baseRoutes } from 'src/router/base-routes'
import { Privileges } from 'src/modules/core/composables/useProfessionalPrivileges'

export const professionalsRoute = [
  {
    path: '/s/:structureIndex',
    component: () => import('src/modules/core/layout/LayoutProfessional.vue'),
    props: true,
    children: [
      // Accueil
      {
        path: '',
        name: 'professional-homepage',
        component: () => import('src/modules/core/views/ProfessionalHomepage.vue')
      },
      // Structure
      {
        path: 'structure',
        name: 'my-structure',
        meta: {
          title: 'Mon établissement',
          requiredPrivileges: [Privileges.AdminEstablishment]
        },
        component: () => import('src/modules/structures/views/Establishment.vue')
      },
      // Objets connectés
      // {
      //   path: 'devices',
      //   name: 'my-devices',
      //   meta: {
      //     title: 'Mon objets connectés',
      //     requiredPrivileges: [Privileges.AdminEstablishment]
      //   },
      //   component: () => import('src/modules/iot/views/DevicesView.vue')
      // },
      // Alertes
      {
        path: 'alerts',
        name: 'alerts',
        redirect: { name: 'alerts-list' },
        children: [
          {
            path: '',
            name: 'alerts-list',
            meta: {
              title: 'Alertes'
            },
            component: () => import('src/modules/alerts/views/AlertsPage.vue')
          },
          {
            path: 'alerts-batch/:id',
            name: 'alerts-batch-context',
            props: true,
            meta: {
              title: 'Contexte de l\'alerte'
            },
            component: () => import('src/modules/alerts/views/AlertsBatchPage.vue')
          }
        ]
      },
      {
        path: 'patient/:patientId/documents/:documentId',
        name: 'patient-document-view',
        props: true,
        meta: {
          title: 'Consulter un document d\'identité',
          requiredPrivileges: [Privileges.SuperAdmin, Privileges.Operator, Privileges.Prescriber]
        },
        component: () => import('src/modules/documents/views/DocumentView.vue')
      },
      // Protocoles médicaux
      {
        path: 'medical-protocols',
        name: 'medical-protocols',
        redirect: { name: 'medical-protocols-list' },
        children: [
          {
            path: '',
            name: 'medical-protocols-list',
            meta: {
              title: 'Protocoles Médicaux',
              requiredPrivileges: [Privileges.SuperAdmin, Privileges.Prescriber, Privileges.Manager]
            },
            component: () => import('src/modules/medical-protocol/views/MedicalProtocols.vue')
          },
          {
            path: ':medicalProtocolId',
            name: 'medical-protocol-view',
            props: true,
            meta: {
              title: 'Protocole Médical',
              requiredPrivileges: [Privileges.SuperAdmin, Privileges.Prescriber, Privileges.Manager]
            },
            component: () => import('src/modules/medical-protocol/views/MedicalProtocolView.vue')
          },
          {
            path: ':medicalProtocolId/documents/:documentId',
            name: 'medical-protocol-view-document-view',
            props: true,
            meta: {
              title: 'Consulter un document',
              requiredPrivileges: [Privileges.SuperAdmin, Privileges.Prescriber, Privileges.Manager]
            },
            component: () => import('src/modules/documents/views/DocumentView.vue')
          }
        ]
      },
      // Dossiers de télésuivi
      {
        path: 'patient-file',
        name: 'patient-file',
        redirect: { name: 'patient-file-list' },
        children: [
          {
            path: '',
            name: 'patient-file-list',
            meta: {
              title: 'Dossiers patients',
              requiredPrivileges: [Privileges.SuperAdmin, Privileges.Operator, Privileges.Prescriber]
            },
            component: () => import('src/modules/patient-files/views/PatientFiles.vue')
          },
          {
            path: ':patientFileId/:currentTab?',
            name: 'patient-file-view',
            props: true,
            meta: {
              title: 'Dossier patient',
              requiredPrivileges: [Privileges.SuperAdmin, Privileges.Operator, Privileges.Prescriber]
            },
            component: () => import('src/modules/patient-files/views/PatientFileView.vue')
          },
          {
            path: ':patientFileId/documents/:documentId',
            name: 'patient-file-view-document-view',
            props: true,
            meta: {
              title: 'Consulter un document',
              requiredPrivileges: [Privileges.SuperAdmin, Privileges.Operator, Privileges.Prescriber]
            },
            component: () => import('src/modules/documents/views/DocumentView.vue')
          },
          {
            path: ':patientFileId/form-responses/:formResponseId',
            name: 'patient-file-form-response-view',
            props: true,
            meta: {
              title: 'Réponses à un questionnaire',
              requiredPrivileges: [Privileges.SuperAdmin, Privileges.Operator, Privileges.Prescriber]
            },
            component: () => import('src/modules/patient-files/views/FormResponse.vue')
          },
          {
            path: ':patientFileId/cpia-payload/:cpiaPayloadId',
            name: 'patient-file-cpia-payload-view',
            props: true,
            meta: {
              title: 'Données CPIA',
              requiredPrivileges: [Privileges.SuperAdmin, Privileges.Operator, Privileges.Prescriber]
            },
            component: () => import('src/modules/patient-files/views/PatientFileCpiaPayload.vue')
          },
          {
            path: ':patientFileId/medial-payload/:medialPayloadId',
            name: 'patient-file-medial-payload-view',
            props: true,
            meta: {
              title: 'Données Médial',
              requiredPrivileges: [Privileges.SuperAdmin, Privileges.Operator, Privileges.Prescriber]
            },
            component: () => import('src/modules/patient-files/views/PatientFileMedialPayload.vue')
          },
          {
            path: ':patientFileId/clinical_document_payloads/:clinicalPayloadId',
            name: 'patient-file-clinical-payload-view',
            props: true,
            meta: {
              title: 'Données de biologie',
              requiredPrivileges: [Privileges.SuperAdmin, Privileges.Operator, Privileges.Prescriber]
            },
            component: () => import('src/modules/patient-files/views/PatientFileBiologyPayload.vue')
          }
        ]
      },
      // Validation de contenu
      {
        path: 'surveys/:id/validation',
        name: 'survey-validation',
        props: true,
        meta: {
          title: 'Questionnaires',
          requiredPrivileges: [Privileges.SuperAdmin, Privileges.Manager]
        },
        component: () => import('src/modules/surveys/views/SurveyValidation.vue')
      },
      {
        path: 'content-validation',
        name: 'content-validation',
        meta: {
          title: 'Validation de contenu',
          requiredPrivileges: [Privileges.SuperAdmin, Privileges.Manager]
        },
        component: () => import('src/modules/content-validation/views/ContentValidation.vue')
      },
      {
        path: 'condition/:id',
        name: 'condition-view',
        props: true,
        meta: {
          title: 'Condition',
          requiredPrivileges: [Privileges.SuperAdmin, Privileges.Operator, Privileges.Prescriber, Privileges.Manager]
        },
        component: () => import('src/modules/conditions/views/ConditionView')
      },
      {
        path: 'patient/:id',
        name: 'patient-edit-profile',
        props: true,
        meta: {
          title: 'Édition du profil'
        },
        component: () => import('src/modules/patients/views/EditProfile.vue')
      },
      {
        path: 'calculated-data/:id/preview',
        name: 'calculated-data-edit',
        props: true,
        meta: {
          title: 'Données calculées',
          requiredPrivileges: [Privileges.SuperAdmin, Privileges.Manager]
        },
        component: () => import('src/modules/calculated-data/views/CalculatedDataView.vue')
      },
      {
        path: 'calculated-data/:id/validation',
        name: 'calculated-data-validation',
        props: true,
        meta: {
          title: 'Données calculées',
          requiredPrivileges: [Privileges.SuperAdmin, Privileges.Manager]
        },
        component: () => import('src/modules/calculated-data/views/CalculatedDataValidation.vue')
      },
      // Statistiques
      {
        path: 'statistics',
        name: 'statistics',
        meta: {
          title: 'Statistiques',
          requiredPrivileges: [Privileges.Manager]
        },
        component: () => import('src/modules/stats/views/Statistics.vue')
      },
      {
        path: 'layout-builder',
        name: 'layout-builder',
        meta: {
          title: 'Edition d\'interface',
          requiredPrivileges: [Privileges.Operator, Privileges.Prescriber]
        },
        component: () => import('src/modules/layout-builder/views/layoutBuilderFrame.vue')
      },
      {
        path: 'prescriptions',
        name: 'manage-prescriptions',
        component: () => import('src/modules/prescriptions/views/Prescriptions.vue')
      }
    ]
  },
  ...baseRoutes
]
