export function trim(string, char = ' ') {
  if (string[string.length - 1] === char) {
    return trim(string.substring(0, string.length - 1), char)
  }
  return string
}

export function rtrim(string, char = ' ') {
  if (string[0] === char) {
    return trim(string.substring(1, string.length), char)
  }
  return string
}

export function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function nameToInitials(string) {
  const names = string.split(' ')

  let initials = names[0][0].toUpperCase()

  if (names.length > 1) {
    initials += names[names.length - 1][0].toUpperCase()
  }
  return initials
}

export function pluralize(word, numberOfElements, plural = 's') {
  return typeof numberOfElements === 'number' && numberOfElements > 1 ? word + plural : word
}

export function truncateText(input, length = 100, truncateSign = '(...)') {
  return input.length > length || length === 0 ? input.substring(0, length) + truncateSign : input
}

export function stringToInt(str) {
  let hash = 0
  str.split('').forEach(char => {
    hash += char.charCodeAt(0)
  })
  return hash
}

export function stringToColor(str, colors) {
  return colors[stringToInt(str) % colors.length]
}

export function removeAccents(str) {
  return str
    .replace(/[áàãâä]/gi, 'a')
    .replace(/[éè¨ê]/gi, 'e')
    .replace(/[íìïî]/gi, 'i')
    .replace(/[óòöôõ]/gi, 'o')
    .replace(/[úùüû]/gi, 'u')
    .replace(/[ç]/gi, 'c')
    .replace(/[ñ]/gi, 'n')
    .replace(/[^a-zA-Z0-9]/g, ' ')
}

/**
 * Search the string substr in str, starting from optional offset.
 */
export function stripos(str, substr, offset) {
  const haystack = removeAccents((str + '').toLowerCase())
  const needle = removeAccents((substr + '').toLowerCase())
  let index

  if ((index = haystack.indexOf(needle, offset)) !== -1) {
    return index
  }
  return false
}
