export default {
  errors: {
    invalid_operand: 'Opérande invalide',
    invalid_mathematical_expression: 'L\'expression mathématique contient des erreurs dans ses opérandes',
    invalid_duration: 'L\'opérande a une durée invalide',
    missing_offset: 'L\'opérande n\'a pas d\'offset',
    missing_indicator: 'L\'opérande n\'a pas d\'indicateur',
    missing_scalar: 'L\'opérande n\'a pas de valeur scalaire',
    missing_property: 'L\'opérande n\'a pas de paramètre supplémentaire'
  }
}
