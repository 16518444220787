import { api } from 'boot/axios'

/**
 * @typedef {{
 *   identifier: string,
 *   finess: string,
 *   udiDiCode: ?string,
 *   name: string,
 *   commercialName: ?string,
 *   clinicalStudy: boolean,
 *   branding: ?string,
 *   theme: ?Object,
 *   customOptions: ?Object,
 *   anonymousPatients: boolean,
 *   creationDate: string,
 *   updateDate: ?string
 * }} EstablishmentData
 */

/**
 * @typedef {{
 *   id: string,
 *   name: string,
 *   gamCode: ?string,
 *   establishmentId: string,
 *   pathologyId: ?string,
 *   externalIdentifiers: string[],
 *   pathology?: PathologyData,
 *   prescriptionsVisibleForPatients: ?boolean
 *   prescriptionsFeatureEnabled: boolean
 * }} EstablishmentServiceData
 */
/**
 * @typedef {{
 *   page?: int,
 *   pageSize?: int,
 *   establishmentId?: ?string,
 *   finess?: ?string,
 *   personPrivileges?: ?string[],
 *   withEstablishment?: boolean,
 *   withPathology?: boolean,
 * }} ListEstablishmentServicesParams
 */

/**
 * @return {Promise<import("axios").AxiosResponse<{count: int, items: EstablishmentData[]}>>}
 */
export function listEstablishments(page, pageSize) {
  return api.get('/establishments', {
    params: {
      page,
      pageSize
    }
  })
}

export function updateEstablishment(establishment) {
  return api.put(`/establishments/${establishment.identifier}`, establishment)
}

export function createEstablishment(establishment) {
  return api.post('/establishments', establishment)
}

/**
 * @param {ListEstablishmentServicesParams} params
 * @return {Promise<{
 *   data: {totalItems: int, items: EstablishmentServiceData[]}
 * }>}
 */
export function listEstablishmentServices(params) {
  return api.get('/establishment_services', { params })
}

export function updatePersonLinkWithEstablishment(identifier, personIdentifier, privileges) {
  if (!privileges) {
    privileges = []
  }
  return api.put(`/establishments/${identifier}/members/${personIdentifier}`, { privileges })
}

export function removePersonFromEstablishment(identifier, personIdentifier) {
  return api.delete(`/establishments/${identifier}/members/${personIdentifier}`)
}

export function updatePersonLinkWithEstablishmentService(identifier, personIdentifier, privileges) {
  if (!privileges) {
    privileges = []
  }
  return api.put(`/establishment_services/${identifier}/members/${personIdentifier}`, { privileges })
}

export function removePersonFromEstablishmentService(identifier, personIdentifier) {
  return api.delete(`/establishment_services/${identifier}/members/${personIdentifier}`)
}

export function updateEstablishmentService(id, establishmentService) {
  return api.put(`/establishment_services/${id}`, establishmentService)
}

export function createEstablishmentService(establishmentService) {
  return api.post('/establishment_services', establishmentService)
}

export function addInterventionLevelUser(interventionLevelId, identifier, establishmentService) {
  return api.post(`/persons/${identifier}/intervention_levels/${interventionLevelId}`, { establishmentService })
}

export function removeInterventionLevelUser(interventionLevelId, identifier, establishmentService) {
  return api.delete(`/persons/${identifier}/intervention_levels/${interventionLevelId}`, { data: { establishmentService } })
}

export function listIdentityDocumentTypes(establishmentId) {
  return api.get(`/establishments/${establishmentId}/identity_document_types`)
}

export function createIdentityDocumentType(establishmentId, identityDocumentType) {
  return api.post(`/establishments/${establishmentId}/identity_document_types`, identityDocumentType)
}

export function updateIdentityDocumentType(establishmentId, identityDocumentType) {
  return api.put(`/establishments/${establishmentId}/identity_document_types/${identityDocumentType.id}`, identityDocumentType)
}

export function deleteIdentityDocumentType(establishmentId, identityDocumentTypeId) {
  return api.delete(`/establishments/${establishmentId}/identity_document_types/${identityDocumentTypeId}`)
}

export function getClients() {
  return api.get('/establishments/clients')
}

export function getTheme(establishmentId) {
  return api.get(`/establishments/${establishmentId}/theme`)
}
