import { computed, ref } from 'vue'
import { api } from 'boot/axios'
import { useContext } from 'src/modules/core/composables/useContext'

let instance = null

/**
 * @typedef {('Operator'|'AdminEstablishment'|'Prescriber'|'Manager'|'SuperAdmin')} Privilege
 */

/**
 * @type {Object.<string, Privilege>}
 */
export const Privileges = {
  Operator: 'OPERATOR',
  Prescriber: 'PRESCRIBER',
  Manager: 'MANAGER',
  AdminEstablishment: 'ADMIN_ESTABLISHMENT',
  SuperAdmin: 'SUPER_ADMIN'
}

export function useProfessionalPrivileges() {
  if (instance) {
    return instance
  }

  const { activeStructure } = useContext()
  const allPrivileges = ref([])

  async function onloadPrivileges() {
    allPrivileges.value = (await api.get('/me/privileges')).data.establishments
  }

  const privilegesInStructure = computed(() => {
    if (!activeStructure.value) {
      return []
    }

    const structurePrivileges = allPrivileges.value.find(establishmentPrivileges => establishmentPrivileges.id === activeStructure.value.identifier)
    return structurePrivileges ? structurePrivileges.privileges.concat(...structurePrivileges.services.map(service => service.privileges || [])) : []
  })

  const servicesInStructure = computed(() => {
    if (!activeStructure.value) {
      return []
    }

    const structurePrivileges = allPrivileges.value.find(establishmentPrivileges => establishmentPrivileges.id === activeStructure.value.identifier)
    return structurePrivileges ? structurePrivileges.services : []
  })
  function hasPrivilegeInService(privileges, serviceId) {
    const service= servicesInStructure.value.find(service => service.id === serviceId)
    if(!service) {
      return false
    }
    return service.privileges.some(privilege => privileges.includes(privilege))
  }
  function hasPrivileges(requestedPrivileges) {
    if (!activeStructure.value) {
      return false
    }

    if (!Array.isArray(requestedPrivileges)) {
      requestedPrivileges = [requestedPrivileges]
    }

    for (const requestedPrivilege of requestedPrivileges) {
      if (privilegesInStructure.value.includes(requestedPrivilege)) {
        return true
      }
    }

    return false
  }

  // Check wether the professional has any of the privileges in any of the structures
  function hasPrivilegesInAnyStructure(requestedPrivileges) {
    if (!Array.isArray(requestedPrivileges)) {
      requestedPrivileges = [requestedPrivileges]
    }

    return allPrivileges.value.filter(structurePrivilege => {
      return structurePrivilege.privileges.filter(privilege => requestedPrivileges.includes(privilege)).length !== 0 ||
        structurePrivilege.services.filter(service => {
          return service.privileges.filter(privilege => requestedPrivileges.includes(privilege)).length !== 0
        }).length !== 0
    }).length !== 0
  }

  function hasPrivilegeKey(privileges, privilegeKey) {
    if (!privileges) {
      return false
    }

    if (!Array.isArray(privileges)) {
      privileges = [privileges]
    }

    for (const privilege of privileges) {
      if (privilege === privilegeKey) {
        return true
      }
    }

    return false
  }

  function getPrivilegeLabel(privilege) {
    switch (privilege) {
      case Privileges.AdminEstablishment:
        return 'Administrateur d\'établissement'
      case Privileges.Manager:
        return 'Manager'
      case Privileges.Prescriber:
        return 'Prescripteur'
      case Privileges.Operator:
        return 'Opérateur de télésuivi'
      case Privileges.SuperAdmin:
        return 'Super admin'
    }
  }

  instance = {
    allPrivileges,
    privilegesInStructure,
    servicesInStructure,
    hasPrivileges,
    hasPrivilegesInAnyStructure,
    onloadPrivileges,
    getPrivilegeLabel,
    Privileges,
    hasPrivilegeKey,
    hasPrivilegeInService
  }

  return instance
}
