export default {
  title: 'Add Documents',
  subtitle: 'You can add a title and a description to your documents',
  uploaderTitle: 'Drop a file or click here to upload your documents',
  externalFileUrl: 'Link',
  addFiles: 'Add a file',
  addMyDocument: 'Add my document',
  addDocument: 'Add a document',
  status: 'Acknowledgment status',
  showToPatientAndCaregivers: 'Show the document to the patient and their caregivers',
  identityDocument: 'Reference identity document',
  startDate: 'Start date',
  endDate: 'End date',
  reminderDate: 'Reminder date',
  file: 'File',
  link: 'Youtube link',
  additionSuccess: 'Document added successfully',
  close: {
    search: 'Close search',
    sortOptions: 'Close sort options',
    filters: 'Close filters'
  },
  listOfDocument: {
    type: 'Type',
    appointment: 'Appointment',
    document: 'Document',
    information: 'Information',
    teleconsultation: 'Teleconsultation',
    report: 'Report'
  },
  listOfTypeOfDocuments: {
    typeOfDocument: 'Type of Document',
    telemonitoringPrescription: 'Telemonitoring Prescription',
    prescription: 'Prescription',
    medicationPrescription: 'Medication Prescription',
    analysisResults: 'Analysis Results',
    image: 'Imaging',
    therapeutic: 'Therapeutic Document',
    information: 'Information Document',
    report: 'Report',
    other: 'Other',
    identityDocument: 'Indentity document'
  },
  displayDocumentList: {
    title: 'Document List',
    subtitle: 'You can view the list of all your documents',
    tableInput: {
      title: 'My Documents List',
      titleOfDocument: 'Title',
      typeOfDocument: 'Type of Document',
      addDate: 'Date Added',
      addBy: 'Added By',
      created: 'Created on',
      end: 'End on',
      tooltipForEdit: 'Edit',
      tooltipForDelete: 'Delete',
      sortByDate: 'Sort by Date',
      noData: 'No documents match your search'
    }
  },
  acquittal: {
    acquit: 'Acknowledge',
    message: 'Message',
    acquitted: 'Acquitted'
  },
  noDocument: {
    title: 'You do not have any document to consult.',
    subtitle: 'nou can now share documents with your medical team',
    subtitle2: ', by clicking on "add document" button above.'
  },
  documentOrderBy: {
    name: 'Name',
    creationDate: 'Creation date'
  },
  displayDocument: {
    sentBy: '{fileSize} sent by {provider}',
    acknowledgmentFailure: 'Unable to acquit the file. Please try again.',
    deletionConfirm: 'You are about to delete this file. Continue?',
    deletionSuccess: 'File was deleted successfully.',
    deletionFailure: 'Unable to delete file.',
    waitingForAcknowledgment: 'Waiting for acknowledgment by a professional',
    generalInformations: {
      title: 'General information',
      patient: 'Patient',
      documentType: 'Type of document',
      creationDate: 'Creation date',
      prescriptionDate: 'Start date of prescription',
      endPrescriptionDate: 'End date of prescription',
      reminder: 'Reminder date',
      actions: 'Available actions'
    },
    error: 'The document could not be displayed,\nyou can try to download it.',
    download: 'Download the document',
    isOpening: 'The document will open in your mobile’s default viewer.'
  },
  dialog: {
    edit: 'Edit a document',
    acquit: 'Acquit a document',
    renew: 'Renew a prescription'
  },
  description: 'Description',
  prescription: {
    deletion: {
      confirmation: 'You are about to delete this prescription. Continue?',
      success: 'The prescription has been successfully delete.',
      error: 'Impossible to delete this prescription.'
    },
    status: {
      waiting: 'Waiting',
      ongoing: 'Ongoing',
      expired: 'Expired',
      awaitingRenewal: 'Awaiting renewal'
    }
  },
  identityType: {
    errors: {
      load: 'Unable to load identity document types',
      creation: 'Unable to create identity document type {label}',
      edition: 'Unable to edit identity document type {label}',
      deletion: 'The identity document type {label} is in use and cannot be deleted',
      used: 'Unable to delete identity document type {label}'
    }
  },
  therapeuticContent: {
    addedOn: 'Added on {date}',
    video: 'Video',
    document: 'Document',
    image: 'Image',
    youtube: 'Youtube video'
  }
}
