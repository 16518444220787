import { ref, watch } from 'vue'
import { listEstablishments } from 'src/modules/structures/api'
import { empty } from 'src/utils/variable'
import { useRoute, useRouter } from 'vue-router'
import { listMyPatientFiles } from 'src/modules/patient-files/api'
import { getCssVar, setCssVar, useQuasar } from 'quasar'

const allPatientFiles = ref([])
const ownPatientFiles = ref([])
const patientFilesAsCaregiver = ref([])
const nonAwaitingPatientFiles = ref([])
const awaitingPatientFiles = ref([])
const activePatientFile = ref(null)
const allStructures = ref([])
/**
 * @type {{value: EstablishmentData}}
 */
const activeStructure = ref(null)
let loadPatientFileCalled = false
let loadStructureCalled = false

const COLORS_NAME = ['primary', 'secondary', 'accent', 'dark', 'positive', 'negative', 'info', 'warning']
const DEFAULT_COLORS = Object.fromEntries(COLORS_NAME.map(colorName => [colorName, getCssVar(colorName)]))

let context = null

export function useContext() {
  if (context !== null) {
    return context
  }

  const router = useRouter()
  const route = useRoute()
  const $q = useQuasar()

  async function onLoadPatientFiles() {
    if (loadPatientFileCalled) {
      throw new Error('You MUST NOT call this method more than one time.')
    }

    loadPatientFileCalled = true
    allPatientFiles.value = (await listMyPatientFiles({ page: 1, rowsPerPage: 200 })).data.items
    // the API doesn't return the patient key if it's the patient file of the user who make the call
    ownPatientFiles.value = allPatientFiles.value.filter(patientFile => patientFile.patient === undefined)
    patientFilesAsCaregiver.value = allPatientFiles.value.filter(patientFile => patientFile.patient !== undefined)
    nonAwaitingPatientFiles.value = allPatientFiles.value.filter(patientFile => patientFile.status === 'OPEN' || patientFile.status === 'CLOSED')
    awaitingPatientFiles.value = allPatientFiles.value.filter(patientFile => patientFile.status === 'AWAITING')

    // Don't use vue router current route because plugin is still loading and params are not computed yet
    const urlParts = window.location.pathname.split('/')
    if (urlParts.length >= 2 && urlParts[1] === 'p' && Number.isInteger(parseInt(urlParts[2])) && !empty(allPatientFiles.value[parseInt(urlParts[2])])) {
      activePatientFile.value = allPatientFiles.value[parseInt(urlParts[2])]
    }

    // Select first patient file if there is only one
    router.beforeEach((to, from, next) => {
      if (
        to.name === 'context-selector' &&
        empty(activePatientFile.value) &&
        nonAwaitingPatientFiles.value.length === 1 &&
        allStructures.value.length === 0
      ) {
        activePatientFile.value = nonAwaitingPatientFiles.value[0]
        next('/p/0')
      } else {
        next()
      }
    })

    // Current active structure is set by navigation
    router.beforeEach((to, from, next) => {
      let patientFileIndex = null

      // Selected structure index is present in route params and valid
      if (!empty(to.params) && !empty(to.params.patientFileIndex) && !isNaN(parseInt(to.params.patientFileIndex, 10))) {
        patientFileIndex = to.params.patientFileIndex

        // Structure index points to an existing structure of the user
        if (patientFileIndex && !empty(allPatientFiles.value[patientFileIndex])) {
          // It differs from the active structure (none or another one)
          if (activePatientFile.value !== allPatientFiles.value[patientFileIndex]) {
            activePatientFile.value = allPatientFiles.value[patientFileIndex]
          }
          next()
        } else {
          // The user does not have this structure in his list
          next({ name: 'context-selector' })
        }
      } else {
        // No structure required for the target route
        next()
      }
    })

    router.afterEach((to) => {
      if (empty(to.params) || empty(to.params.patientFileIndex) || isNaN(parseInt(to.params.patientFileIndex, 10))) {
        activePatientFile.value = null
      }
    })

    // Current active structure is modified by setting a new value to the ref
    watch(activePatientFile, async (newValue, oldValue) => {
      if (empty(newValue) || oldValue === newValue) {
        return
      }

      let newRoute
      const patientFileIndex = allPatientFiles.value.findIndex(patientFile => patientFile.id === activePatientFile.value.id)

      if (!empty(route.params) && !empty(route.params.patientFileIndex)) {
        newRoute = {
          params: {
            ...route.params,
            patientFileIndex
          }
        }
      } else {
        newRoute = '/p/' + patientFileIndex
      }

      activeStructure.value = null
      await router.push(newRoute)
    })
  }

  function selectPatientFile(patientFileId) {
    const patientFileIndex = allPatientFiles.value.findIndex(patientFile => patientFile.id === patientFileId)
    if (patientFileIndex >= 0) {
      activePatientFile.value = allPatientFiles.value[patientFileIndex]
    }
  }

  async function updatePatientFiles() {
    allPatientFiles.value = (await listMyPatientFiles({ page: 1, rowsPerPage: 100 })).data.items
    nonAwaitingPatientFiles.value = allPatientFiles.value.filter(patientFile => patientFile.status === 'OPEN' || patientFile.status === 'CLOSED')
    awaitingPatientFiles.value = allPatientFiles.value.filter(patientFile => patientFile.status === 'AWAITING')
  }

  function isIdOfOwnPatientFile(id) {
    return ownPatientFiles.value.some(patientFile => patientFile.id === id)
  }

  async function onLoadStructures() {
    if (loadStructureCalled) {
      throw new Error('You MUST NOT call this method more than one time.')
    }

    loadStructureCalled = true
    allStructures.value = (await listEstablishments(1, 999)).data.data

    // Don't use vue router current route because plugin is still loading and params are not computed yet
    const urlParts = window.location.pathname.split('/')
    if (urlParts.length >= 2 && urlParts[1] === 's' && Number.isInteger(parseInt(urlParts[2])) && !empty(allStructures.value[parseInt(urlParts[2])])) {
      activeStructure.value = allStructures.value[parseInt(urlParts[2])]
    }

    // Select first structure if there is only one
    router.beforeEach((to, from, next) => {
      if (
        to.name === 'context-selector' &&
        empty(activeStructure.value) &&
        allStructures.value.length === 1 &&
        allPatientFiles.value.length === 0
      ) {
        activeStructure.value = allStructures.value[0]
        next('/s/0')
      } else {
        next()
      }
    })

    // Current active structure is set by navigation
    router.beforeEach((to, from, next) => {
      let structureIndex = null

      // Selected structure index is present in route params and valid
      if (!empty(to.params) && !empty(to.params.structureIndex) && !isNaN(parseInt(to.params.structureIndex, 10))) {
        structureIndex = to.params.structureIndex

        // Structure index points to an existing structure of the user
        if (structureIndex && !empty(allStructures.value[structureIndex])) {
          // It differs from the active structure (none or another one)
          if (activeStructure.value !== allStructures.value[structureIndex]) {
            activeStructure.value = allStructures.value[structureIndex]
          }
          next()
        } else {
          // The user does not have this structure in his list
          next({ name: 'context-selector' })
        }
      } else {
        // No structure required for the target route
        next()
      }
    })

    router.afterEach((to) => {
      if (empty(to.params) || empty(to.params.structureIndex) || isNaN(parseInt(to.params.structureIndex, 10))) {
        activeStructure.value = null
      }
    })

    // Current active structure is modified by setting a new value to the ref
    watch(activeStructure, async (newValue, oldValue) => {
      if (empty(newValue) || oldValue === newValue) {
        return
      }

      let newRoute
      const structureIndex = allStructures.value.findIndex(structure => structure.identifier === activeStructure.value.identifier)

      if (!empty(route.params) && !empty(route.params.structureIndex)) {
        newRoute = {
          params: {
            ...route.params,
            structureIndex
          }
        }
      } else {
        newRoute = '/s/' + structureIndex
      }
      activePatientFile.value = null
      await router.push(newRoute)
    })
  }

  function selectStructure(structureIdentifier) {
    const structureIndex = allStructures.value.findIndex(structure => structure.identifier === structureIdentifier)
    if (structureIndex >= 0) {
      activeStructure.value = allStructures.value[structureIndex]
      return true
    }
    return false
  }

  watch([activeStructure, activePatientFile, () => $q.dark.isActive], () => {
    const contextEstablishment = activeStructure.value ? activeStructure.value : activePatientFile.value?.service?.establishment
    for (const colorName of COLORS_NAME) {
      setCssVar(
        colorName,
        contextEstablishment?.theme?.[$q.dark.isActive ? 'dark' : 'light']?.[colorName] ||
        DEFAULT_COLORS[colorName] ||
        ''
      )
    }
  }, {
    immediate: true
  })

  context = {
    onLoadPatientFiles,
    allPatientFiles,
    activePatientFile,
    selectPatientFile,
    onLoadStructures,
    updatePatientFiles,
    allStructures,
    activeStructure,
    selectStructure,
    awaitingPatientFiles,
    nonAwaitingPatientFiles,
    isIdOfOwnPatientFile
  }

  return context
}
