import { baseRoutes } from 'src/router/base-routes'

export const patientRoutes = [
  {
    path: '/p/:patientFileIndex',
    component: () => import('src/modules/core/layout/LayoutPatient.vue'),
    children: [
      {
        path: '',
        name: 'patient-homepage',
        meta: {
          isIndex: true
        },
        component: () => import('src/modules/core/views/PatientHomepage.vue')
      },
      {
        path: 'my-tasks',
        name: 'patient-tasks',
        meta: {
          title: 'routes.tasks'
        },
        component: () => import('src/modules/events/views/PatientInterfaceTasks.vue')
      },
      {
        path: 'health-path',
        name: 'health-path',
        meta: {
          title: 'routes.agenda',
          onlyOpened: true
        },
        component: () => import('src/modules/events/views/PatientEvents.vue')
      },
      {
        path: 'health-path-data',
        name: 'health-path-data',
        meta: {
          title: 'routes.monitoring'
        },
        component: () => import('src/modules/patient-files/views/PatientFileInformations.vue')
      },
      {
        path: 'health-path-history/form-responses/:formResponseId',
        name: 'health-path-response-view',
        props: true,
        meta: {
          title: 'routes.formResponse',
          back: { name: 'health-path-data' }
        },
        component: () => import('src/modules/events/views/PatientEventResponse.vue')
      },
      {
        path: 'profile',
        name: 'patient-profile',
        meta: {
          title: 'routes.profileEdit'
        },
        component: () => import('src/modules/patients/views/EditProfile')
      },
      {
        path: 'iot',
        name: 'patient-iot',
        meta: {
          title: 'routes.iot'
        },
        component: () => import('src/modules/patients/views/PatientIoT')
      },
      {
        path: 'survey-answers/:id/:free?/:locale?',
        name: 'survey-answer',
        meta: {
          title: 'routes.myForm',
          back: { name: 'patient-tasks' }
        },
        component: () => import('src/modules/surveys/components/answer/SurveyAnswerCard'),
        props: true
      },
      {
        path: 'messages',
        name: 'messages',
        meta: {
          title: 'routes.messenger'
        },
        component: () => import('src/modules/messages/views/PatientInterfaceMessenger.vue')
      },
      {
        path: 'documents/add',
        name: 'documents-add',
        meta: {
          title: 'routes.addDocument',
          back: { name: 'documents-list' }
        },
        props: true,
        component: () => import('src/modules/documents/views/PatientInterface/AddDocument.vue')
      }, {
        path: 'documents',
        name: 'documents-list',
        meta: {
          title: 'routes.myDocuments'
        },
        props: true,
        component: () => import('src/modules/documents/views/PatientInterface/DocumentsList.vue')
      },
      {
        path: 'documents/:documentId',
        name: 'document-view',
        props: true,
        meta: {
          title: 'routes.documentConsult',
          doctype: 'regular',
          back: { name: 'documents-list' }
        },
        component: () => import('src/modules/documents/views/PatientInterface/DocumentView.vue')
      },
      {
        path: 'education/:documentId',
        name: 'education-view',
        props: true,
        meta: {
          title: 'routes.therapeuticEducation',
          doctype: 'education',
          back: { name: 'health-path-data' }
        },
        component: () => import('src/modules/documents/views/PatientInterface/DocumentView.vue')
      }
    ]
  },
  ...baseRoutes
]
