import { defineBoot } from '#q-app/wrappers'
import { createI18n } from 'vue-i18n'
import messages from 'src/i18n'
import { empty } from 'src/utils/variable'

const SUPPORTED_LANGUAGES = ['fr', 'en', 'ar']
const userPreferredLanguage = localStorage.getItem('lang') || window?.navigator?.language?.split('-')[0]

const i18n = createI18n({
  locale: !empty(userPreferredLanguage) && SUPPORTED_LANGUAGES.includes(userPreferredLanguage) ? userPreferredLanguage : 'fr',
  pluralRules: {
    ar: arabicRule
  },
  messages
})

function arabicRule(choice) {
  // plural
  if (choice >= 2 && choice <= 10) {
    return 1
  }

  // singular
  return 0
}

export default defineBoot(({ app }) => {
  // Set i18n instance on app
  app.use(i18n)
})

export { i18n }
