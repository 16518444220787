export default {
  center: {
    title: 'مركز الإشعارات',
    noNotifications: 'لا توجد إشعارات.',
    markAll: 'تحديد الكل كمشاهد',
    deleteAll: 'حذف الكل',
    loadFailure: 'تعذر تحميل الإشعارات.'
  },
  PATIENT_FILE: {
    NEW: {
      title: 'ملف مريض جديد',
      content: {
        patient: 'تم إنشاء ملف مريض جديد',
        others: 'تم إنشاء ملف مريض جديد للمريض {fullName}'
      }
    }
  },
  MESSAGE: {
    NEW: {
      title: 'رسالة جديدة',
      content: {
        patient: 'لقد تلقيت رسالة جديدة في ملف مريضك',
        others: 'لقد تلقيت رسالة جديدة في ملف المريض {fullName}'
      }
    }
  },
  FORM_OCCURRENCE: {
    NEW: {
      title: 'استبيان جديد',
      content: 'الاستبيان {formName} متاح حتى {endDate}'
    },
    REMIND: {
      title: 'تذكير بالاستبيان',
      content: 'يتبقى لك {duration} للإجابة على الاستبيان {formName}'
    }
  },
  CONTENT: {
    NEW: {
      title: 'محتوى علاجي جديد',
      content: 'تم إضافة المحتوى العلاجي {contentName} إلى ملف مريضك'
    }
  },
  PROTOCOL: {
    ASSIGN: {
      title: 'بروتوكول جديد',
      content: 'تم تعيين البروتوكول {protocolName} إلى ملف مريضك'
    },
    FINISH_ASSIGNMENT: {
      title: 'إتمام تعيين بروتوكول',
      content: 'تم الانتهاء من تعيين البروتوكول {name}.'
    }
  },
  DOCUMENT: {
    NEW: {
      title: 'مستند جديد',
      content: {
        patient: 'تم إضافة مستند جديد إلى ملف مريضك',
        others: 'تم رفع مستند جديد للمريض {fullName}'
      }
    },
    REMIND: {
      title: 'وصفة طبية للتجديد',
      content: 'الوصفة الطبية للمريض {fullName} ستنتهي قريباً'
    }
  },
  ALERT: {
    NEW: {
      title: 'تنبيهات جديدة',
      content: {
        others: {
          application: 'تم إنشاء تنبيهات جديدة للمريض {fullName} بتاريخ {date} على المؤشر {indicatorName}',
          form: 'تم إنشاء تنبيهات جديدة للمريض {fullName} على النموذج {formName}',
          non_observance: 'تم إنشاء تنبيهات جديدة لعدم الالتزام للمريض {fullName}'
        }
      }
    }
  },
  CALCULATION: {
    SUBMIT_VALIDATION: {
      title: 'التحقق من البيانات المحسوبة',
      content: 'البيانات المحسوبة {calculationName} تحتاج إلى تحققك من الخدمة {serviceName} في المؤسسة {establishmentName}'
    }
  },
  FORM: {
    SUBMIT_VALIDATION: {
      title: 'التحقق من النموذج',
      content: 'النموذج {formName} يحتاج إلى تحققك من الخدمة {serviceName} في المؤسسة {establishmentName}'
    },
    NEW: {
      title: 'استبيان جديد',
      content: 'تم إضافة الاستبيان {formName} إلى ملف مريضك'
    }
  },
  PROPERTY: {
    SUBMIT_VALIDATION: {
      title: 'التحقق من المعامل الإضافي',
      content: 'المعامل الإضافي {propertyName} يحتاج إلى تحققك من الخدمة {serviceName} في المؤسسة {establishmentName}'
    }
  },
  RENDEZ_VOUS: {
    NEW: {
      title: 'موعد جديد',
      content: {
        patient: 'لديك موعد جديد {rdvTitle}',
        others: 'تم إضافة موعد جديد {rdvTitle} للمريض {fullName}'
      }
    },
    DELETE: {
      title: 'حذف موعد',
      content: {
        patient: 'تم حذف موعدك {rdvTitle}',
        others: 'تم حذف موعد {rdvTitle} للمريض {fullName}'
      }
    },
    UPDATE: {
      title: 'تعديل موعد',
      content: {
        patient: 'تم تعديل موعدك {rdvTitle}',
        others: 'تم تعديل موعد {rdvTitle} للمريض {fullName}'
      }
    },
    REMIND: {
      title: 'تذكير بالموعد',
      content: {
        patient: 'تذكير بموعدك {rdvTitle}',
        others: 'تذكير بموعد {rdvTitle} للمريض {fullName}'
      }
    }
  },
  REMINDER: {
    NEW: {
      title: 'تذكير جديد',
      content: {
        patient: 'لديك تذكير جديد {reminderTitle}',
        others: 'تم إضافة تذكير جديد {reminderTitle} للمريض {fullName}'
      }
    },
    DELETE: {
      title: 'حذف تذكير',
      content: {
        patient: 'تم حذف تذكيرك {reminderTitle}',
        others: 'تم حذف تذكير {reminderTitle} للمريض {fullName}'
      }
    },
    UPDATE: {
      title: 'تعديل تذكير',
      content: {
        patient: 'تم تعديل تذكيرك {reminderTitle}',
        others: 'تم تعديل تذكير {reminderTitle} للمريض {fullName}'
      }
    },
    REMIND: {
      title: 'تذكير',
      content: {
        patient: 'تذكير بـ {reminderTitle}',
        others: 'تذكير بـ {reminderTitle} للمريض {fullName}'
      }
    }
  }
}
