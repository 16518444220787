import rules from './rules.js'
import profile from './profile.js'
import user from './user.js'
import structures from './structures.js'
import documents from './documents.js'
import events from './events.js'
import protocols from './protocols.js'
import surveys from './surveys.js'
import professionals from './professionals.js'
import indicators from './indicators.js'
import calculatedData from './calculated-data.js'
import externalData from './external-data.js'
import properties from './properties.js'
import messages from './messages.js'
import patientFile from './patient-file.js'
import durations from './durations.js'
import alertStatus from './alert-status.js'
import interventionLevel from './intervention-level.js'
import indicatorUnit from './indicator-unit.js'
import indicatorType from './indicator-type.js'
import surveyType from './survey-type.js'
import notifications from './notifications.js'
import notificationSettings from './notification-settings.js'
import iot from './iot.js'
import mathematicalExpression from './mathematical-expression.js'
import operand from './operand.js'
import layoutBuilder from './layout-builder.js'
import userParameters from './user-parameters.js'
import common from './common.js'
import patient from './patient.js'
import activities from './activities.js'
import condition from './condition.js'
import routes from './routes.js'

export default {
  common,
  rules,
  profile,
  user,
  structures,
  documents,
  events,
  protocols,
  surveys,
  professionals,
  indicators,
  calculatedData,
  externalData,
  properties,
  messages,
  patientFile,
  durations,
  alertStatus,
  interventionLevel,
  indicatorUnit,
  indicatorType,
  surveyType,
  notifications,
  notificationSettings,
  iot,
  mathematicalExpression,
  operand,
  layoutBuilder,
  userParameters,
  patient,
  activities,
  condition,
  routes
}
