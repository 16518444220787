export default {
  title: 'Dossier patient',
  subtitle: 'Informations du dossier',
  close: 'Clore le dossier',
  creationDate: 'Créé le ',
  history: {
    entries: {
      user: {
        update: {
          title: 'Modification de profil',
          subtitle: 'a modifié le profil du patient'
        },
        insiSearch: {
          title: 'Recherche d\'identité',
          subtitle: 'a recherché l\'identité du patient via l\'INSi'
        },
        insiVerification: {
          title: 'Vérification d\'identité',
          subtitle: 'a vérifié l\'identité du patient via l\'INSi'
        },
        insiVerificationFailure: {
          title: 'Erreur de vérification d\'identité',
          subtitle: 'n\'a pas pu vérifier l\'identité du patient via l\'INSi'
        }
      },
      NonObservanceAlert: {
        create: {
          title: 'Alerte de non-observance',
          subtitle: 'Le patient n\'a pas répondu au questionnaire'
        },
        update: {
          title: 'Alerte de non-observance',
          subtitle: 'Le patient n\'a toujours pas répondu au questionnaire'
        }
      },
      formResponse: {
        submit: {
          title: 'Réponse à un questionnaire',
          subtitle: 'a répondu au questionnaire'
        }
      },
      cpiaPayload: {
        create: {
          title: 'Nouvelles données CPIA',
          subtitle: 'Nouvelles mesures pour les indicateurs de CPIA'
        }
      },
      customReferenceValue: {
        create: {
          title: 'Modification d\'une valeur de référence',
          subtitle: 'a renseigné la valeur de référence {referenceValue} pour l\'indicateur'
        },
        delete: {
          title: 'Désactivation d\'une valeur de référence',
          subtitle: 'a désactivé la valeur de référence pour l\'indicateur'
        }
      },
      document: {
        stream: {
          title: 'Consultation d\'un {documentType}',
          subtitle: 'a consulté le {documentType}'
        },
        download: {
          title: 'Téléchargement d\'un {documentType}',
          subtitle: 'a téléchargé le {documentType}'
        },
        acquit: {
          title: 'Acquittement d\'un document',
          subtitle: 'a acquitté le document'
        }
      },
      file: {
        stream: {
          title: 'Consultation d\'un {documentType}',
          subtitle: 'a consulté le {documentType}'
        }
      },
      medialPayload: {
        create: {
          title: 'Nouvelles données de séance',
          subtitle: 'Nouvelles mesures pour les indicateurs de séance'
        }
      },
      propertyValue: {
        create: {
          title: 'Renseignement d\'un paramètre supplémentaire',
          subtitle: 'a renseigné la valeur du paramètre supplémentaire {propertyName}'
        },
        update: {
          title: 'Modification d\'un paramètre supplémentaire',
          subtitle: 'a modifié la valeur du paramètre supplémentaire {propertyName}'
        },
        delete: {
          title: 'Désactivation d\'un paramètre supplémentaire',
          subtitle: 'a désactivé le paramètre supplémentaire'
        }
      },
      patientFile: {
        addPatientFileCaregiver: {
          title: 'Ajout d\'un aidant',
          subtitle: 'a été ajouté par {sourceFirstName} {sourceLastName} à la liste des'
        },
        removePatientFileCaregiver: {
          title: 'Retrait d\'un aidant',
          subtitle: 'a été retiré par {sourceFirstName} {sourceLastName} de la liste des'
        },
        createIndicatorBoundary: {
          title: 'Personnalisation du seuil d\'alerte',
          subtitle: 'a personnalisé un seuil d\'alerte sur l\'indicateur {target}. Une alerte {alertLevel} est déclenchée si la valeur de l\'indicateur est {operator} à {subtitleSuffix}'
        },
        updateIndicatorBoundary: {
          title: 'Modification de la personnalisation du seuil d\'alerte',
          subtitle: 'a modifié la personnalisation du seuil d\'alerte de l\'indicateur {target}. Une alerte {alertLevel} est déclenchée si la valeur de l\'indicateur est {operator} à {subtitleSuffix}'
        },
        deleteIndicatorBoundary: {
          title: 'Suppression de la personnalisation du seuil d\'alerte',
          subtitle: 'a retiré la personnalisation du seuil d\'alerte {alertLevel} pour l\'opérateur {operator} sur l\'indicateur {target}'
        },
        deactivateIndicatorBoundary: {
          title: 'Désactivation du seuil d\'alerte',
          subtitle: 'a désactivé le seuil d\'alerte {alertLevel} pour l\'opérateur {operator} sur l\'indicateur {target}'
        },
        close: {
          title: 'Clôture du dossier',
          subtitle: 'a clôturé le dossier'
        },
        automaticallyClose: {
          title: 'Clôture automatique du dossier',
          subtitle: 'Le dossier a été clôturé automatiquement car sa date de fin a été dépassée'
        },
        reopen: {
          title: 'Réouverture du dossier',
          subtitle: 'a rouvert le dossier'
        }
      },
      rendezVous: {
        create: {
          title: 'Création d\'un rendez-vous',
          subtitle: 'a créé le rendez-vous'
        },
        update: {
          title: 'Modification d\'un rendez-vous',
          subtitle: 'a modifié le rendez-vous'
        },
        delete: {
          title: 'Suppression d\'un rendez-vous',
          subtitle: 'a supprimé le rendez-vous'
        },
        title: 'Rendez-vous',
        subtitle: 'Permet de planifier un rendez-vous',
        new: 'Créer un nouveau rendez-vous',
        form: {
          shareWithMedicalTeam: 'Partager à l\'équipe médicale',
          date: 'Date',
          from: 'de',
          to: 'à',
          addReminder: 'Ajouter un rappel',
          delay: 'Délai',
          success: 'Le rendez-vous a été créé avec succès',
          failure: 'Impossible de créer le rendez-vous'
        }
      },
      reminder: {
        create: {
          title: 'Création d\'un rappel',
          subtitle: 'a créé le rappel'
        },
        update: {
          title: 'Modification d\'un rappel',
          subtitle: 'a modifié le rappel'
        },
        delete: {
          title: 'Suppression d\'un rappel',
          subtitle: 'a supprimé le rappel'
        },
        title: 'Rappel',
        subtitle: 'Permet de planifier un rappel',
        new: 'Créer un nouveau rappel',
        form: {
          date: 'Date de rappel',
          time: 'Heure de rappel',
          shareWithMedicalTeam: 'Partager à l\'équipe médicale',
          success: 'Le rappel a été créé avec succès',
          failure: 'Impossible de créer le rappel'
        }
      },
      note: {
        addNote: {
          title: 'Ajout d\'une note',
          subtitle: 'a ajouté une note'
        },
        editNote: {
          title: 'Édition d\'une note',
          subtitle: 'a édité une note'
        },
        archiveNote: {
          title: 'Archivage d\'une note',
          subtitle: 'a archivé une note'
        }
      },
      patientFileProtocol: {
        activate: {
          title: 'Activation d\'un protocole',
          subtitle: 'a activé le protocole'
        },
        deactivate: {
          title: 'Désactivation d\'un protocole',
          subtitle: 'a désactivé le protocole'
        },
        restore: {
          title: 'Restauration d\'un protocole',
          subtitle: 'a restauré le protocole'
        }
      },
      protocol: {
        assign: {
          title: 'Assignation d\'un protocole',
          subtitle: 'a assigné le protocole'
        },
        externalAssign: {
          title: 'Assignation d\'un protocole',
          subtitle: 'Assignation du protocole'
        }
      },
      custom: {
        hospitalization: {
          title: 'Hospitalisation',
          subtitle: 'Le patient a été hospitalisé'
        },
        other: {
          title: 'Autres activités',
          subtitle: '{description}'
        },
        therapeutic: {
          title: 'Accompagnement thérapeutique',
          subtitle: 'Un accompagnement thérapeutique a été réalisé'
        }
      }
    }
  },
  content: {
    item: {
      restore: 'Restaurer le contenu pour le dossier',
      restoreError: 'Une erreur est survenue durant la restauration du contenu'
    }
  },
  about: 'Votre dossier patient a été créé le {creationDate} dans le service {serviceName} de l\'établissement {establishmentName}',
  monitoring: {
    loading: 'Chargement de votre suivi en cours',
    history: {
      title: 'Mon historique',
      noData: 'Pas de données',
      actor: {
        you: 'Vous avez',
        him: 'a '
      },
      consulted: 'consulté le ',
      responded: 'répondu le '
    },
    data: 'Mes dernières données',
    content: 'Mon contenu thérapeutique',
    noContent: {
      firstLine: 'Aucun contenu disponible pour le moment',
      secondLine: 'Votre éducation thérapeutique sera accessible dès qu\'elle sera ajoutée par un professionnel de santé'
    },
    noData: {
      firstLine: 'Aucune donnée disponible pour le moment',
      secondLine: 'Vos graphiques de mesure, votre éducation thérapeutique et votre historique seront disponibles dès que des mesures seront ajoutées'
    }
  },
  payloads: {
    question: 'Question',
    answer: 'Réponse',
    alertStatus: 'Statut d\'alerte',
    showQuestionsLabel: 'Afficher le libellé des questions',
    receivedImage: 'Image reçue : ',
    loadingFailure: 'Les données n\'ont pas pu être chargées',
    alert: 'Alerte',
    closed: 'Clôturée',
    pending: 'En attente',
    comment: 'Commentaire',
    alertConditions: {
      show: 'Afficher les conditions d\'alerte',
      hide: 'Masquer les conditions d\'alerte'
    },
    newMeasureCondition: {
      display: 'Afficher la condition de nouvelle mesure',
      hide: 'Masquer la condition de nouvelle mesure'
    },
    formCondition: 'Condition de questionnaire ayant généré cette mesure : ',
    condition: 'Condition ayant généré l\'alerte : '
  },
  graphs: {
    lastMeasures: 'Récupération de vos dernières mesures',
    noData: 'Aucune donnée disponible',
    error: {
      load: 'Impossible de récupérer les mesures du graphique {chart}',
      graphSettings: 'Impossible de charger les paramètres des graphiques'
    },
    in: 'en',
    referenceValue: 'Valeur de référence',
    minimum: 'Minimum{markLineNameSuffix}',
    maximum: 'Maximum{markLineNameSuffix}',
    median: 'Médiane{markLineNameSuffix}',
    average: 'Moyenne{markLineNameSuffix}'
  },
  contextSelector: {
    service: 'Service :',
    createdOn: 'Créé le {date}',
    inService: 'dans le service',
    closed: 'Fermé'
  }
}
