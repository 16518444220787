export default {
  title: 'مسار الرعاية',
  subtitle: 'يمكنك رؤية جميع مستنداتك، وصفاتك، جلسات الاستشارة عن بُعد، والمواعيد',
  creationDate: 'تاريخ الإنشاء',
  startDate: 'تاريخ البداية',
  endDate: 'تاريخ النهاية',
  addBy: 'أضيف بواسطة ',
  viewBy: 'تمت رؤيته بواسطة ',
  today: 'اليوم',
  scheduledToday: 'مجدول اليوم',
  noScheduledToday: 'لا توجد أحداث اليوم',
  allEvents: 'جميع أحداثي',
  status: {
    available: 'متاح',
    ongoing: 'قيد التنفيذ',
    completed: 'مكتمل',
    expired: 'منتهي الصلاحية',
    upcoming: 'قادم',
    free: 'حر'
  },
  acceptedDelay: 'الإجابة مقبولة حتى {limitDate}',
  answeredOn: 'تمت الإجابة في {answerDate}',
  form: 'الاستبيان',
  myForm: 'استبياني',
  formInfo: 'معلومات عن هذا الاستبيان',
  summary: 'ملخص استبيانك',
  freeSubmission: 'تقديم حر',
  sendingForm: 'جاري إرسال استبيانك',
  loadingForm: 'جاري تحميل الاستبيان',
  sendDelay: 'يجب الإرسال قبل',
  sendSuccess: 'تم إرسال الاستبيان بنجاح',
  alreadySent: 'تم إرسال الإجابة مسبقًا',
  date: {
    allDay: 'طوال اليوم',
    sameDate: 'في {date}',
    differentTime: 'من {startDate} إلى {endDate}',
    from: 'من {startDate} إلى {endDate}',
    at: 'في',
    included: 'مشمولا',
    on: 'في'
  },
  unknownEvent: 'حدث غير معروف',
  lateEvents: {
    title: 'لديك استبيانات متأخرة',
    subtitle: 'يجب عليك الإجابة على الاستبيانات المتأخرة بالترتيب بدءًا من:',
    oldestLateResponse: 'أجيب على الاستبيان الأقدم تأخيرًا',
    continue: 'أريد المتابعة (لن تكون الاستبيانات السابقة المتأخرة متاحة بعد الآن)'
  },
  mandatoryFieldError: 'هناك حقل إلزامي فارغ | هناك حقول إلزامية فارغة',
  answerSubmit: {
    title: 'تم إرسال الاستبيان بنجاح!',
    subtitle: 'قم بزيارة لوحة التحكم للاطلاع على الأنشطة الأخرى المقترحة لك',
    goToDashboard: 'لوحة التحكم'
  },
  chooseLanguageForm: 'اختر اللغة التي تريد الرد بها على النموذج',
  formLanguageTitle: 'لغة النموذج',
  answerDate: 'تاريخ الإجابة: {date}',
  formResponseDataDeactivated: 'تعطيل البيانات يؤدي إلى إبطال العمليات الحسابية التي تعتمد عليها',
  formResponseLoadFailure: 'تعذر تحميل إجابات النموذج'
}
