export default {
  plannedActivities: 'نشاط مخطط | أنشطة مخططة',
  noPlannedActivity: {
    title: 'لا توجد أنشطة مخططة',
    subtitle: 'ليس لديك أي أنشطة مخططة لهذا اليوم. لعرض الأنشطة القادمة، يرجى مراجعة جدولك.',
    alternativeSubtitle: 'ليس لديك أي أنشطة مخططة لهذا اليوم.'
  },
  freeActivities: 'نشاط حر | أنشطة حرة',
  myFreeActivities: 'أنشطتي الحرة',
  add: 'إضافة نشاط',
  seeAll: 'شاهد جميع الأنشطة المجانية',
  less: 'رؤية أنشطة مجانية أقل'
}
