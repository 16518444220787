export default {
  agenda: 'My schedule',
  tasks: 'My tasks',
  monitoring: 'My monitoring',
  formResponse: 'Form responses',
  profileEdit: 'Profile edition',
  iot: 'Connected devices',
  myForm: 'My form',
  messenger: 'My messenger',
  addDocument: 'Add a document',
  myDocuments: 'My documents',
  documentConsult: 'Consult a document',
  therapeuticEducation: 'Therapeutic education'
}
