<template>
  <q-dialog ref="dialogRef" persistent @hide="onDialogHide">
    <q-card class="q-dialog-plugin q-pa-sm" style="max-width: inherit">
      <!-- No max width, adapt dialog to the size of text-->
      <q-item>
        <q-item-section avatar>
          <q-avatar icon="fa-duotone fa-alarm-exclamation" color="primary" text-color="white" />
        </q-item-section>
        <q-item-section>
          <div class="q-pl-sm text-blue-10 col">
            <template v-if="!sessionExpired">
              <div class="text-body1">
                {{ t('user.session.soonExpired') }}
              </div>
              <div class="text-body2">
                {{ t('user.session.timer', { remainingTime: sessionRemainingTime }) }}
              </div>
            </template>
            <template v-else>
              <div class="text-center text-h6">
                {{ t('user.session.expired') }}
              </div>
            </template>
          </div>
        </q-item-section>
      </q-item>

      <q-card-actions align="right">
        <template v-if="!sessionExpired">
          <q-btn
            flat :label="t('user.session.extend')" color="primary" :loading="loggingIn"
            @click.stop="onLogin"
          />
          <q-btn
            flat :label="t('common.disconnect')" color="secondary" :loading="loggingOut"
            @click.stop="onLogout"
          />
        </template>
        <template v-else>
          <q-btn
            flat :label="t('common.reconnect')" color="secondary" :loading="loggingIn"
            @click.stop="onLogin"
          />
        </template>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
  import { ref } from 'vue'
  import { useDialogPluginComponent } from 'quasar'
  import { useAuth } from 'src/modules/core/composables/useAuth'
  import { useSessionExpiration } from 'src/modules/core/composables/useSessionExpiration'
  import { useI18n } from 'vue-i18n'

  const { onLogout, loggingOut } = useAuth()
  const { t } = useI18n()

  const {
    sessionExpired,
    sessionRemainingTime,
    onSessionKeepAlive
  } = useSessionExpiration()

  const loggingIn = ref(false)

  defineEmits([
    // REQUIRED; need to specify some events that your
    // component will emit through useDialogPluginComponent()
    ...useDialogPluginComponent.emits
  ])

  const {
    dialogRef,
    onDialogHide,
    onDialogOK
  } = useDialogPluginComponent()

  async function onLogin() {
    loggingIn.value = true

    if (await onSessionKeepAlive()) {
      loggingIn.value = false
      onDialogOK()
    } else {
      window.location.reload()
    }
  }
</script>
