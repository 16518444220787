import { baseRoutes } from 'src/router/base-routes'

export const adminRoutes = [
  {
    path: '/s/:structureIndex',
    component: () => import('src/modules/core/layout/LayoutAdmin.vue'),
    children: [
      // Accueil
      {
        path: 'pathologies',
        name: 'pathologies-manage',
        meta: {
          title: 'Liste des pathologies'
        },
        component: () => import('src/modules/pathologies/views/ManagePathologies.vue')
      },
      {
        path: 'pathologies/:slug',
        name: 'pathology-manage',
        meta: {
          title: 'Éditer une pathologie'
        },
        component: () => import('src/modules/pathologies/views/ManagePathology.vue'),
        props: true
      },
      {
        path: '',
        name: 'admin-homepage',
        meta: {
          title: 'Mon établissement'
        },
        component: () => import('src/modules/structures/views/Establishment.vue')
      },
      // List patients
      {
        path: 'patients-list',
        name: 'patients-list',
        meta: {
          title: 'Liste des patients'
        },
        component: () => import('src/modules/patients/views/AdminPatientsList.vue')
      },
      // Status d'alertes
      {
        path: 'alert-statuses',
        name: 'alert-statuses-list',
        meta: {
          title: 'Statut d\'alerte'
        },
        component: () => import('src/modules/alert-status-intervention-level/views/AlertStatusPage.vue')
      },
      // Indicateurs
      {
        path: 'indicators',
        name: 'indicators-list',
        meta: {
          title: 'Indicateurs'
        },
        component: () => import('src/modules/indicators/views/Indicators.vue')
      },
      // Données calculés
      {
        path: 'calculated-data',
        name: 'calculated-data-list',
        meta: {
          title: 'Données calculées'
        },
        component: () => import('src/modules/calculated-data/views/CalculatedData.vue')
      },
      {
        path: 'calculated-data/:id/editor',
        name: 'calculated-data-edit',
        meta: {
          title: 'Données calculées'
        },
        props: true,
        component: () => import('src/modules/calculated-data/views/CalculatedDataView.vue')
      },
      // Données externes
      {
        path: 'external-data',
        name: 'external-data-list',
        meta: {
          title: 'Données externes'
        },
        component: () => import('src/modules/external-data/views/ExternalDataMapping.vue')
      },
      // Questionnaires
      {
        path: 'surveys',
        name: 'surveys-list',
        meta: {
          title: 'Questionnaires'
        },
        component: () => import('src/modules/surveys/views/Surveys.vue')
      },
      {
        path: 'surveys/:id/editor',
        name: 'survey-edit',
        meta: {
          title: 'Questionnaires'
        },
        component: () => import('src/modules/surveys/views/SurveyEditor.vue'),
        props: true
      },
      // Paramètres supplémentaires
      {
        path: 'properties',
        name: 'properties-list',
        meta: {
          title: 'Paramètres supplémentaires'
        },
        component: () => import('src/modules/properties/views/Properties.vue')
      },
      // Objets connectés
      {
        path: 'iot-devices',
        name: 'iot-devices-list',
        meta: {
          title: 'Objets connectés'
        },
        component: () => import('src/modules/iot/views/DevicesView.vue')
      }
    ]
  },
  {
    path: '/structure-create',
    name: 'create-structure',
    meta: {
      title: 'Mon établissement'
    },
    component: () => import('src/modules/structures/components/StructureStart.vue')
  },
  ...baseRoutes
]
