export default {
  title: 'Additional Parameters',
  subtitle: 'List of Additional Parameters',
  create: 'Add an Additional Parameter',
  errors: {
    name: {
      NOT_UNIQUE_ERROR: 'An additional parameter with the same name already exists in this establishment'
    }
  }
}
