export default {
  title: 'Questionnaires',
  subtitle: 'Liste des questionnaires',
  create: 'Ajouter un questionnaire',
  submissionType: {
    type: 'Type de soumission',
    free: 'Libre',
    planned: 'Planifié'
  },
  questions: {
    fileType: {
      hint: 'Formats acceptés : png, jpeg, pdf'
    }
  },
  computed: {
    noValue: 'Pas de valeur'
  },
  errors: {
    name: {
      NOT_UNIQUE_ERROR: 'Un formulaire du même nom existe déjà dans cet établissement'
    },
    reference: {
      NOT_UNIQUE_ERROR: 'Un formulaire avec la même référence existe déjà dans cet établissement'
    },
    load: 'Une erreur est survenue lors de l\'ouverture du questionnaire. Veuillez actualiser la page et réessayer'
  }
}
