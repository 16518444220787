export default {
  title: 'External Data',
  subtitle: 'List of external data',
  create: 'Add external data',
  displayHistoryTooltip: 'Display version history',
  disableDataTooltip: 'Disable this data',
  disableDataDate: 'Disabled on',
  activateDataTooltip: 'Activate this data',
  alertDisable: 'Are you sure you want to disable the data'
}
