export default {
  title: 'Messagerie',
  subtitle: 'Retrouvez toutes vos discussions avec votre personnel de santé',
  writeMessage: 'Saisir votre message',
  addMultimediaMessage: 'Joindre un fichier',
  sendMessage: 'Envoyer',
  qDialogMessageTitle: 'MMS',
  qDialogMessageClose: 'Fermer',
  messageImage: {
    thumbnail: 'Image envoyée dans le chat',
    image: 'Image en grand format'
  },
  inactive: 'La messagerie n\'est pas activée pour votre dossier patient',
  caution: 'Attention : il ne s\'agit en aucun cas d\'un canal d\'urgence. Veuillez contacter le 112 ou le 15 en cas d\'événements inquiétants.',
  noMessage: 'Aucun message.',
  addDocument: 'Ajouter un document',
  loadFailure: 'Impossible de charger la liste des messages',
  sendFailure: 'Impossible d\'envoyer le message',
  deletionFailure: 'Impossible de supprimer le fichier joint',
  acknowledge: 'J\'ai pris connaissance de ce message',
  imageUnavailable: 'L\'image n\'est plus disponible'
}
