export default {
  center: {
    title: 'Notifications center',
    noNotifications: 'No notification.',
    markAll: 'Mark all as seen',
    deleteAll: 'Delete all',
    loadFailure: 'Notifications loading failed.'
  },
  PATIENT_FILE: {
    NEW: {
      title: 'New patient file',
      content: {
        patient: 'A new patient file has been created',
        others: 'A new patient file has been created for patient {fullName}'
      }
    }
  },
  MESSAGE: {
    NEW: {
      title: 'New message',
      content: {
        patient: 'You have received a new message in your patient file',
        others: 'You have received a new message in the patient file of {fullName}'
      }
    }
  },
  FORM_OCCURRENCE: {
    NEW: {
      title: 'New questionnaire',
      content: 'The questionnaire {formName} is available until {endDate}'
    },
    REMIND: {
      title: 'Questionnaire reminder',
      content: 'You have {duration} left to complete the questionnaire {formName}'
    }
  },
  CONTENT: {
    NEW: {
      title: 'New therapeutic content',
      content: 'The therapeutic content {contentName} has been added to your patient file'
    }
  },
  PROTOCOL: {
    ASSIGN: {
      title: 'New protocol',
      content: 'The protocol {protocolName} has been assigned to your patient file'
    },
    FINISH_ASSIGNMENT: {
      title: 'Protocol assignment',
      content: 'The assignment of the protocol {name} has now been finalized.'
    }
  },
  DOCUMENT: {
    NEW: {
      title: 'New document',
      content: {
        patient: 'A new document has been added to your patient file',
        others: 'A new document has been uploaded for patient {fullName}'
      }
    },
    REMIND: {
      title: 'Prescription renewal',
      content: 'The prescription for patient {fullName} is expiring soon'
    }
  },
  ALERT: {
    NEW: {
      title: 'New alerts',
      content: {
        others: {
          application: 'New alerts have been generated for patient {fullName} on {date} for indicator {indicatorName}',
          form: 'New alerts have been generated for patient {fullName} on form {formName}',
          non_observance: 'New non-compliance alerts have been generated for patient {fullName}'
        }
      }
    }
  },
  CALCULATION: {
    SUBMIT_VALIDATION: {
      title: 'Calculated data validation',
      content: 'The calculated data {calculationName} requires your validation for the service {serviceName} at the establishment {establishmentName}'
    }
  },
  FORM: {
    SUBMIT_VALIDATION: {
      title: 'Form validation',
      content: 'The form {formName} requires your validation for the service {serviceName} at the establishment {establishmentName}'
    },
    NEW: {
      title: 'New questionnaire',
      content: 'The questionnaire {formName} has been added to your patient file'
    }
  },
  PROPERTY: {
    SUBMIT_VALIDATION: {
      title: 'Additional parameter validation',
      content: 'The additional parameter {propertyName} requires your validation for the service {serviceName} at the establishment {establishmentName}'
    }
  },
  RENDEZ_VOUS: {
    NEW: {
      title: 'New appointment',
      content: {
        patient: 'You have a new appointment {rdvTitle}',
        others: 'A new appointment {rdvTitle} has been added for patient {fullName}'
      }
    },
    DELETE: {
      title: 'Appointment cancellation',
      content: {
        patient: 'Your appointment {rdvTitle} has been cancelled',
        others: 'The appointment {rdvTitle} has been cancelled for patient {fullName}'
      }
    },
    UPDATE: {
      title: 'Appointment update',
      content: {
        patient: 'Your appointment {rdvTitle} has been updated',
        others: 'The appointment {rdvTitle} has been updated for patient {fullName}'
      }
    },
    REMIND: {
      title: 'Appointment reminder',
      content: {
        patient: 'Reminder for your appointment {rdvTitle}',
        others: 'Reminder for appointment {rdvTitle} for patient {fullName}'
      }
    }
  },
  REMINDER: {
    NEW: {
      title: 'New reminder',
      content: {
        patient: 'You have a new reminder {reminderTitle}',
        others: 'A new reminder {reminderTitle} has been added for patient {fullName}'
      }
    },
    DELETE: {
      title: 'Reminder cancellation',
      content: {
        patient: 'Your reminder {reminderTitle} has been cancelled',
        others: 'The reminder {reminderTitle} has been cancelled for patient {fullName}'
      }
    },
    UPDATE: {
      title: 'Reminder update',
      content: {
        patient: 'Your reminder {reminderTitle} has been updated',
        others: 'The reminder {reminderTitle} has been updated for patient {fullName}'
      }
    },
    REMIND: {
      title: 'Reminder',
      content: {
        patient: 'Reminder for {reminderTitle}',
        others: 'Reminder for {reminderTitle} for patient {fullName}'
      }
    }
  }
}
