export default {
  tasks: {
    title: 'My Tasks',
    subtitle: 'My actions to complete'
  },
  monitoring: {
    title: 'My Monitoring',
    subtitle: 'My data and content'
  },
  messenger: {
    title: 'My Messenger',
    subtitle: 'My exchanges with the team'
  },
  documents: {
    title: 'My Documents',
    subtitle: 'My documents exchanged with the team'
  },
  agenda: 'My Schedule',
  newTasks: 'Task to accomplish | Tasks to accomplish',
  newMessages: 'New message | New messages',
  newDocuments: 'New document | New Documents',
  closed: 'Closed Case',
  noPendingTasks: 'No pending tasks',
  inPriority: 'In priority',
  scheduledToday: 'Scheduled today',
  upcoming: 'Upcoming',
  passwordGeneration: {
    title: 'Patient login credentials',
    firstLoginUsername: 'First login username:',
    email: 'Email address:',
    mobilePhone: 'Mobile phone:',
    password: 'Password:',
    info: {
      part1: 'Please ',
      part2: 'save this password',
      part3: ' safely before continue.',
      part4: ' This password is necessary for the ',
      part5: 'patient\'s first connection'
    }
  },
  form: {
    generalInfo: 'General information',
    firstNames: 'First names',
    firstName: 'First name',
    birthName: 'Birth name',
    firstNameUsed: 'First name used',
    lastNameUsed: 'Last name used',
    sex: 'Sex',
    birthDate: 'Birth date',
    birthPlaceInsee: 'Birth place - INSEE code',
    coordinates: 'Coordinates',
    email: 'Email address',
    mobilePhone: 'Mobile phone',
    digitalIdentity: 'Digital identity',
    ins: 'INS number',
    oid: 'OID',
    insiIdentityAttribute: 'Digital identity status attribute',
    insi: {
      search: {
        info: {
          part1: 'To perform a search on INSi,',
          part2: 'you must enter your last name, first name(s),',
          part3: 'birth date and sex (man or woman).'
        },
        search: 'INSi search'
      },
      verification: {
        info: {
          part1: 'To perform an identity verification on INSi,',
          part2: 'you must enter your last name, first name(s),',
          part3: 'birth date, sex (man or woman), INS number and OID.'
        },
        verification: 'INSi verification'
      }
    },
    error: {
      alreadyUsedEmailAddress: 'The email address is already used',
      emailOrMobilePhone: 'Mandatory email or mobile phone'
    },
    updateWithIdentityProof: 'I am a healthcare professional and I would like to change the identity of this patient on the basis of a proof of identity',
    additionalInfo: 'Additional information',
    globalReferenceValue: 'Global reference value:',
    identity: {
      doubtful: 'Doubtful identity',
      fictitious: 'Fictitious identity',
      namesake: 'Namesake identity'
    },
    success: {
      creation: 'Patient successfully created',
      update: 'Patient successfully updated'
    }
  }
}
