export default {
  title: 'Indicators',
  subtitle: 'List of indicators',
  create: 'Add an indicator',
  errors: {
    name: {
      NOT_UNIQUE_ERROR: 'An indicator with the same name already exists'
    }
  }
}
