export default {
  title: 'البيانات المحسوبة',
  subtitle: 'قائمة البيانات المحسوبة',
  create: 'إضافة بيانات محسوبة',
  errors: {
    indicator: {
      NOT_UNIQUE_ERROR: 'هذا المؤشر مرتبط بالفعل ببيانات محسوبة في هذه المؤسسة'
    },
    name: {
      NOT_UNIQUE_ERROR: 'يوجد بالفعل بيانات محسوبة بنفس الاسم في هذه المؤسسة'
    },
    calculation: {
      unique_undeactivated_calculation: 'يمكن تفعيل بيانات محسوبة واحدة فقط لمؤشر وخدمة في المؤسسة.'
    }
  }
}
