export default {
  title: 'الاستبيانات',
  subtitle: 'قائمة الاستبيانات',
  create: 'إضافة استبيان',
  submissionType: {
    type: 'نوع الإرسال',
    free: 'حر',
    planned: 'مخطط'
  },
  questions: {
    fileType: {
      hint: 'PNG، JPEG، PDF : التنسيقات المقبولة'
    }
  },
  computed: {
    noValue: 'لا توجد بيانات'
  },
  errors: {
    name: {
      NOT_UNIQUE_ERROR: 'استبيان بنفس الاسم موجود بالفعل في هذه المؤسسة'
    },
    reference: {
      NOT_UNIQUE_ERROR: 'استبيان بنفس المرجعية موجود بالفعل في هذه المؤسسة'
    },
    load: 'حدث خطأ أثناء فتح الاستبيان. يرجى تحديث الصفحة والمحاولة مرة أخرى'
  }
}
