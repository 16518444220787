export default {
  email: 'Email',
  password: 'Password',
  newPassword: 'New Password',
  givenName: 'Given Name(s)',
  hintGivenName: 'If you have multiple given names, separate them with a comma',
  firstName: 'First Given Name',
  givenNameUse: 'Preferred Given Name',
  lastName: 'Last Name',
  familyName: 'Family Name',
  middleName: 'Birth Name',
  middleNameUse: 'Preferred Name',
  birthPlaceInsee: 'Birth place - INSEE code',
  countryOfBirth: 'Country of Birth Code',
  streetAddress: 'Address',
  phoneNumber: 'Phone Number',
  otherPhoneNumber: 'Other Phone Number',
  birthDate: 'Date of Birth (YYYY/MM/DD)',
  admissionDate: 'Admission Date (YYYY/MM/DD)',
  operationDate: 'Operation Date (YYYY/MM/DD)',
  externalID: 'External ID',
  weight: 'Your Weight (in kg)',
  size: 'Your Height (in cm)',
  sex: 'Sex',
  sexChoice: {
    male: 'Male',
    female: 'Female',
    unknown: 'Unknown'
  },
  postalCode: 'Postal Code',
  locality: 'City',
  specificInformation: 'Specific Information',
  patient: {
    label: 'Patient',
    identifier: 'Social Security Number'
  },
  personalDataUseApproval: 'I give my explicit consent for the use of my personal data strictly for the use of the apTeleCare solution for remote monitoring by healthcare professionals from the establishment that opened my account',
  cguValidation: 'CGU Validation',
  cguDateValidation: 'on 01/07/2021 at 16:35',
  consent: 'Consent',
  consentDate: 'on 01/07/2021 at 16:36',
  contactDetails: 'Contact details',
  mailEditConfirm: 'You are about to change your email address for {email}. Are you sure to continue?',
  editionSuccess: 'Profile was edited successfully',
  changePassword: {
    title: 'Password change',
    previous: 'Previous password',
    new: 'New password',
    confirm: 'Confirm the new password',
    changeSuccess: 'Password was changed successfully',
    noMatch: 'Passwords do not match'
  },
  contextSelector: {
    choosePatientFile: {
      title: 'Choose a patient file',
      subtitle: 'Please choose a patient file',
      label: 'Choice of patient file',
      available: 'Your patient file will be available on : {date}'
    }
  },
  session: {
    expired: 'Session expired.',
    soonExpired: 'Your session is going to expire, would you like to extend it?',
    timer: 'Otherwise, you will be disconnected in {remainingTime} seconds',
    extend: 'Extend session'
  }
}
