import { adminRoutes } from 'src/router/admin-routes'
import { useRouter } from 'vue-router'
import { useContext } from 'src/modules/core/composables/useContext'

export function useAdminSessionFactory() {
  const router = useRouter()
  const { onLoadStructures } = useContext()

  async function onCreateAdminSession() {
    adminRoutes.forEach(router.addRoute)

    await onLoadStructures()
  }

  return { onCreateAdminSession }
}
