import { useContext } from 'src/modules/core/composables/useContext'
import { useRouter } from 'vue-router'
import { useProfessionalPrivileges } from 'src/modules/core/composables/useProfessionalPrivileges'
import { empty } from 'src/utils/variable'
import { patientRoutes } from 'src/router/patient-routes'
import { professionalsRoute } from 'src/router/professional-routes'

export function useProfessionalSessionFactory() {
  const router = useRouter()
  const { activePatientFile, onLoadPatientFiles, onLoadStructures } = useContext()
  const { hasPrivileges, onloadPrivileges } = useProfessionalPrivileges()

  function checkRequiredRights(route) {
    if (route.meta && !empty(route.meta.requiredPrivileges)) {
      return hasPrivileges(route.meta.requiredPrivileges)
    }
    if (route.meta && route.meta.onlyOpened) {
      return activePatientFile.value.status === 'OPEN'
    }
    return true
  }

  async function onCreateProfessionalSession() {
    // In case the professional is a caregiver, they should have access to the patient routes
    patientRoutes.forEach(router.addRoute)
    professionalsRoute.forEach(router.addRoute)

    await Promise.all([onLoadPatientFiles(), onLoadStructures(), onloadPrivileges()])

    // Here you can add global navigation guards specific to professionals
    router.beforeEach((to, from, next) => {
      if (to.matched.every(checkRequiredRights)) {
        next()
      } else {
        next({ path: to.path === '/' ? 'not-authorized' : '/' })
      }
    })
  }

  return { onCreateProfessionalSession }
}
