export default {
  edit: {
    title: 'Edit Profile',
    subTitle: 'Edit Form',
    editButton: 'Edit'
  },
  generalInformations: 'General informations',
  myProfile: 'My profile',
  myInformations: 'My informations',
  caregivers: 'My caregivers',
  changePassword: 'Changer my password',
  notifications: 'Notifications manager',
  iot: 'Connected devices',
  chooseLanguage: 'Choose language',
  help: 'Documentation and help',
  onlineHelp: 'Online help',
  userManual: 'User manuel',
  legalMentions: 'Legal mentions',
  eula: 'EULA',
  about: 'About',
  disconnect: 'Disconnect',
  menu: 'Back to menu',
  password: 'Password',
  editProfile: {
    title: 'Edit my profile',
    emailAddress: 'Email address',
    addAddress: 'Add an address',
    phone: 'Phone',
    addPhone: 'Add a phone number',
    phoneNumber: 'Phone number'
  },
  endUserLevelAgreement: 'End-user level agreement',
  otp: {
    invalidCode: 'Invalid code'
  },
  emailUpdate: {
    info: 'You can define a new email address for your account.',
    validationCode: 'To ensure that this address is really yours, we will send you a validation code.',
    waitBeforeRetry: 'Please wait {duration} before retry',
    sentValidationCode: 'To ensure that this address is really yours, we have sent a validation code to your new address.',
    verifyCode: 'Verify the code',
    success: 'The email address has been successfully updated',
    error: {
      alreadyUsedEmailAddress: 'The email address has already been used'
    }
  },
  mobilePhoneUpdate: {
    info: 'You can define a new phone number for your account.',
    validationCode: 'To ensure that this address is really yours, we will send you a validation code by SMS.',
    waitBeforeRetry: 'Please wait {duration} before retry',
    sentValidationCode: 'To ensure that this address is really yours, we have sent a validation code to your new phone number.',
    verifyCode: 'Verify the code',
    success: 'The phone number has been successfully updated'
  }
}
