// TODO différence clé de traduction, il en manque dans ar/en
export default {
  errors: {
    name: {
      not_unique_error: 'Un fournisseur IoT du même nom existe déjà'
    }
  },
  title: 'Objets connectés',
  myTitle: 'Mes objets connectés',
  subtitle: 'Liste des objets connectés',
  noDevice: {
    patientSide: 'Vous n\'avez pas d\'objet connecté',
    withings: 'Le patient n\'a pas de matériel ou il n\'a pas lié son compte Withings',
    elsewhere: 'Aucun objet connecté'
  },
  connectMedisante: {
    patientSide: 'Sélectionner le matériel Médisanté à connecter à votre dossier patient',
    elsewhere: 'Sélectionner le matériel Médisanté à connecter au dossier patient'
  },
  connectWithings: {
    patientSide: 'Sélectionner le matériel Withings à connecter à votre dossier patient',
    elsewhere: 'Sélectionner le matériel Withings à connecter au dossier patient'
  },
  connectWithingsCellular: {
    patientSide: 'Sélectionner le matériel Withings Cellular à connecter à votre dossier patient',
    elsewhere: 'Sélectionner le matériel Withings Cellular à connecter au dossier patient'
  },
  add: {
    title: 'Ajouter un objet connecté',
    subtitle: {
      medisante: 'Ajouter un appareil Médisanté',
      withingsCellular: 'Ajouter un appareil Withings Cellular'
    }
  },
  withingsConfirm: {
    connect: 'Vous allez être redirigé sur le site de Withings afin de connecter votre compte à Aptelecare. Si vous n\'avez pas encore de compte Withings, vous pourrez en créer un. Continuer ?',
    disconnect: 'Vous êtes sur le point de déconnecter votre compte Withings. Vos dossiers patients n\'auront plus accès aux données provenant de votre matériel Withings. Continuer ?'
  },
  battery: {
    low: 'Batterie faible, il est conseillé de la charger ou de la changer au plus tôt',
    aboveThirty: 'Batterie chargée à plus de 30% de sa capacité',
    aboveSeventy: 'Batterie chargée à plus de 75% de sa capacité'
  }
}
