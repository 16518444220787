export default {
  title: 'الرسائل',
  subtitle: 'استعرض جميع محادثاتك مع الطاقم الطبي الخاص بك',
  writeMessage: 'اكتب رسالتك',
  addMultimediaMessage: 'إرفاق ملف',
  sendMessage: 'إرسال',
  qDialogMessageTitle: 'MMS',
  qDialogMessageClose: 'إغلاق',
  messageImage: {
    thumbnail: 'الصورة المرسلة في الدردشة',
    image: 'الصورة بحجم كبير'
  },
  inactive: 'الرسائل غير مفعلة في ملفك الطبي',
  caution: 'تحذير: هذه ليست قناة طوارئ. يرجى الاتصال بالرقم 112 أو 15 في حالة الأحداث المثيرة للقلق.',
  noMessage: 'لا توجد رسائل.',
  addDocument: 'إضافة مستند',
  loadFailure: 'تعذر تحميل قائمة الرسائل',
  sendFailure: 'تعذر إرسال الرسالة',
  deletionFailure: 'تعذر حذف الملف المرفق',
  acknowledge: 'لقد اطلعت على هذه الرسالة',
  imageUnavailable: 'الصورة لم تعد متوفرة'
}
