export default {
  agenda: 'جدولي الزمني',
  tasks: 'مهامي',
  monitoring: 'متابعتي',
  formResponse: 'إجابات على استبيان',
  profileEdit: 'تحرير الملف الشخصي',
  iot: 'الأشياء المتصلة',
  myForm: 'استبياني',
  messenger: 'رسائلي',
  addDocument: 'إضافة مستند',
  myDocuments: 'مستنداتي',
  documentConsult: 'استشارة مستند',
  therapeuticEducation: 'التعليم العلاجي'
}
