export default {
  add: 'Add',
  confirm: 'Confirm',
  cancel: 'Cancel',
  close: 'Close',
  save: 'Save',
  back: 'Back',
  submit: 'Submit',
  clearSelection: 'Clear selection',
  possibleChoices: 'Possible choice | Possible choices',
  openInNewWindow: 'Open in a new window',
  search: 'Search',
  searchByName: 'Search by name',
  toSearch: 'Search',
  sort: 'Sort',
  sortBy: 'Sort by',
  filter: 'Filter',
  noResult: 'No result',
  chooseLanguage: 'Choose language',
  unknownError: 'An error occurred',
  welcome: 'Welcome',
  about: 'About',
  form: {
    title: 'Title',
    description: 'Description'
  },
  you: 'You',
  acknowledged: 'Acknowledged by {person} on {date}',
  waitingForAcknowledgment: 'Waiting for acknowledgment',
  validate: 'Validate',
  next: 'Next',
  previous: 'Previous',
  wait: 'Please wait',
  of: 'of',
  yes: 'Yes',
  no: 'No',
  and: 'And',
  other: 'other | others',
  notSpecified: 'Not specified',
  renew: 'Renew',
  edit: 'Edit',
  ignore: 'Ignore',
  download: 'Download',
  delete: 'Delete',
  connect: 'Connect',
  disconnect: 'Disconnect',
  reconnect: 'Reconnect',
  confirmRequired: 'Confirmation required',
  confirmMessage: 'Are you sure to do this action?',
  export: 'Export',
  continue: 'Continue',
  dateErrors: {
    incomplete: 'The date is incomplete',
    inThePast: 'The date cannot be earlier than today',
    inTheFuture: 'The date cannot be later than today',
    birthDateInTheFuture: 'The birth date cannot be later than today'
  },
  fileUnavailable: 'The file is no longer available'
}
