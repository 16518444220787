export default {
  email: 'E-mail',
  password: 'Mot de passe',
  newPassword: 'Nouveau mot de passe',
  givenName: 'Prénom(s)',
  hintGivenName: 'Si vous avez plusieurs prénoms, séparez-les par une virgule',
  firstName: 'Premier prénom',
  givenNameUse: 'Prénom utilisé',
  lastName: 'Nom',
  familyName: 'Nom de famille',
  middleName: 'Nom de naissance',
  middleNameUse: 'Nom utilisé',
  birthPlaceInsee: 'Lieu de naissance - Code INSEE',
  countryOfBirth: 'Code pays de naissance',
  streetAddress: 'Adresse',
  phoneNumber: 'Numéro de téléphone',
  otherPhoneNumber: 'Autre numéro de téléphone',
  birthDate: 'Date de naissance (AAAA/MM/JJ)',
  admissionDate: 'Date d\'admission (AAAA/MM/JJ)',
  operationDate: 'Date d\'opération (AAAA/MM/JJ)',
  externalID: 'ID externe',
  weight: 'Votre poids (en kg)',
  size: 'Votre taille (en cm)',
  sex: 'Sexe',
  sexChoice: {
    male: 'Homme',
    female: 'Femme',
    unknown: 'Non renseigné'
  },
  postalCode: 'Code postal',
  locality: 'Ville',
  specificInformation: 'Informations spécifiques',
  patient: {
    label: 'Patient',
    identifier: 'Numéro de sécurité sociale'
  },
  personalDataUseApproval: 'Je donne mon consentement explicite pour l\'utilisation des données personnelles me concernant dans le cadre strict de l\'utilisation de la solution apTeleCare pour la réalisation de mon télésuivi par les professionnels de santé de l\'établissement m\'ayant ouvert un compte',
  cguValidation: 'Validation CGU',
  cguDateValidation: 'le 01/07/2021 à 16h35',
  consent: 'Consentement',
  consentDate: 'le 01/07/2021 à 16h36',
  contactDetails: 'Coordonnées',
  mailEditConfirm: 'Vous êtes sur le point de modifier votre adresse e-mail pour {email}. Êtes-vous sûr de vouloir continuer ?',
  editionSuccess: 'Profil édité avec succès',
  changePassword: {
    title: 'Changement de mot de passe',
    previous: 'Ancien mot de passe',
    new: 'Nouveau mot de passe',
    confirm: 'Confirmer le nouveau mot de passe',
    changeSuccess: 'Le mot de passe a été modifié avec succès',
    noMatch: 'Les mots de passe ne correspondent pas'
  },
  contextSelector: {
    choosePatientFile: {
      title: 'Choisir un dossier patient',
      subtitle: 'Veuillez sélectionner un dossier patient',
      label: 'Choix du dossier patient',
      available: 'Votre dossier sera disponible le : {date}'
    }
  },
  session: {
    expired: 'Votre session est expirée.',
    soonExpired: 'Votre session va bientôt expirer, voulez-vous la prolonger ?',
    timer: 'Sinon, vous serez déconnecté dans {remainingTime} secondes',
    extend: 'Prolonger la session'
  }
}
