export default {
  if: 'If:',
  then: 'Then:',
  is: 'is ',
  referenceValue: 'reference value',
  indicatorBoundary: 'indicator\'s boundary',
  defined: 'defined for alert',
  plus: 'plus',
  minus: 'minus',
  atLeastOne: 'at least one of these conditions is true',
  all: 'all of the conditions are true',
  answer: 'The answer',
  positive: 'Positive',
  negative: 'Negative',
  formElementType: {
    calculation: 'Calculation',
    property: 'Property',
    score: 'Score'
  },
  unnamed: 'Unnamed',
  notSpecified: 'was not specified',
  specified: 'was specified',
  containsChoice: 'contains choice',
  matchesChoice: 'matches choice',
  responseValue: {
    noValue: 'having no value when answered',
    value: 'having a value when answered'
  },
  createMesure: 'Create a new measure for indicator',
  worth: 'worth',
  conditionOperators: {
    equals: 'equals to',
    greater: 'greater than',
    less: 'less than',
    greatEqual: 'greater or equals to',
    lessEqual: 'less or equals to'
  }
}
