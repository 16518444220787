export default {
  errors: {
    name: {
      NOT_UNIQUE_ERROR: 'Une unité du même nom existe déjà'
    },
    symbol: {
      NOT_UNIQUE_ERROR: 'Une unité avec le même symbole existe déjà'
    }
  }
}
