export default {
  pageTitle: 'Interface Editor',
  pageHelper: 'Customizable Pages',
  sectionHelper: 'Page Sections',
  saveButton: 'Save Changes',
  saveSuccess: 'Your interface changes have been successfully saved.',
  saveFailure: 'Your changes could not be saved',
  page: {
    patientFileSynthesis: 'Patient File Summary',
    proDashboard: 'Dashboard / Home'
  },
  block: {
    patientFilesGraph: 'Graphs',
    patientFilesContentScheduler: 'Scheduling and Content',
    patientFilesIncomingData: 'Telemonitoring Data',
    patientFilesTableValues: 'Value Tables',
    proDashboardGraphs: 'Connections and Activities',
    proDashboardAlerts: 'Alerts',
    proDashboardObservanceAlerts: 'Non-compliance Alerts',
    proDashboardDocsMessenger: 'Documents and Messaging'
  }
}
