export default {
  title: 'مؤسستي',
  subtitle: 'مؤسستي مع خدماتها وأعضائها',
  create: 'إضافة مؤسسة',
  services: {
    title: 'الخدمات',
    subtitle: 'قائمة الخدمات',
    create: 'إضافة خدمة',
    noServicesTitle: 'لا توجد خدمات',
    noServicesSubtitleCanCreate: 'لم يتم العثور على أي خدمة لهذه المؤسسة، يمكنك إنشاء واحدة من خلال النقر على الزر أعلاه.',
    noServicesSubtitleCantCreate: 'لم يتم العثور على أي خدمة لهذه المؤسسة، يرجى الرجوع إلى المسؤول لإنشاء واحدة.'
  }
}
