import { areSameArray } from 'src/utils/array'
import { areSameObject } from 'src/utils/object'

/**
 * Return the first non-empty var.
 * Can replace "empty(mixedVar) ? defaultValue : mixedVar"
 */
export function def(...values) {
  for (const value of values) {
    if (!empty(value)) {
      return value
    }
  }
  return values[values.length - 1]
}

export function empty(mixedVar, emptyValues = []) {
  let undef
  let key
  let i
  let len
  // noinspection JSUnusedAssignment
  emptyValues = [undef, null, '', ...emptyValues]

  for (i = 0, len = emptyValues.length; i < len; i++) {
    if (mixedVar === emptyValues[i]) {
      return true
    }
  }

  if (Array.isArray(mixedVar)) {
    return mixedVar.length === 0
  } else if (typeof mixedVar === 'object') {
    if (mixedVar instanceof File || mixedVar instanceof FileList || mixedVar instanceof Date) {
      return false
    }

    for (key in mixedVar) {
      if (Object.prototype.hasOwnProperty.call(mixedVar, key) && !empty(mixedVar[key])) {
        return false
      }
    }
    return true
  }

  return false
}

export function isTrue(value) {
  if (typeof (value) === 'string') {
    value = value.trim().toLowerCase()
  }
  switch (value) {
    case true:
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'yes':
      return true
    default:
      return false
  }
}

export function areSame(a, b) {
  if (Array.isArray(a) && Array.isArray(b)) {
    return areSameArray(a, b)
  } else if (typeof a === 'object' && typeof b === 'object') {
    return areSameObject(a, b)
  }
  return a === b
}
