export default {
  errors: {
    name: {
      not_unique_error: 'An IoT provider with the same name already exists'
    }
  },
  title: 'My connected devices',
  noDevice: {
    patientSide: 'You do not have any connected device.',
    withings: 'Patient does not have any material or he had not connected his Withings account',
    elsewhere: 'No connected device'
  },
  connectMedisante: {
    patientSide: 'Select Médisanté\'s material to connect to your patient file',
    elsewhere: 'Select Médisanté\'s material to connect to patient file'
  },
  connectWithings: {
    patientSide: 'Select Withings material to connect to your patient file',
    elsewhere: 'Select Withings material to connect to patient file'
  },
  connectWithingsCellular: {
    patientSide: 'Select Withings Cellular\'s material to connect to your patient file',
    elsewhere: 'Select Withings Cellular\'s material to connect to patient file'
  },
  add: {
    title: 'Add a connected device',
    subtitle: {
      medisante: 'Add a Médisanté\'s device',
      withingsCellular: 'Add a Withings Cellular\'s device'
    }
  },
  withingsConfirm: {
    connect: 'You are going to be redirected to Withings website to connect your account to Aptelecare. You will be able to create an account in case you do not have one. Continue?',
    disconnect: 'You are about to disconnect your Withings account. Your patient files would not have the access to data from your Withings material. Continue?'
  },
  battery: {
    low: 'Low battery, it is recommended to charge or replace it as soon as possible',
    aboveThirty: 'Battery charged to more than 30% of its capacity',
    aboveSeventy: 'Battery charged to more than 75% of its capacity'
  }
}
