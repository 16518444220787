import { useRouter } from 'vue-router'
import { patientRoutes } from 'src/router/patient-routes'
import { useContext } from 'src/modules/core/composables/useContext'

export function usePatientSessionFactory() {
  const router = useRouter()
  const { activePatientFile, onLoadPatientFiles } = useContext()

  function checkRequiredRights(route) {
    if (route.meta && route.meta.onlyOpened) {
      return activePatientFile.value.status === 'OPEN'
    }
    return true
  }

  async function onCreatePatientSession() {
    patientRoutes.forEach(router.addRoute)

    await Promise.all([onLoadPatientFiles()])

    // Here you can add global navigation guards specific to patients
    router.beforeEach((to, from, next) => {
      if (to.matched.every(checkRequiredRights)) {
        next()
      } else {
        next({ path: to.path === '/' ? 'not-authorized' : '/' })
      }
    })
  }

  return { onCreatePatientSession }
}
