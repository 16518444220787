export default {
  pageTitle: 'محرر الواجهة',
  pageHelper: 'الصفحات القابلة للتخصيص',
  sectionHelper: 'أقسام الصفحة',
  saveButton: 'حفظ التعديلات',
  saveSuccess: 'تم حفظ التعديلات الخاصة بالواجهة بنجاح.',
  saveFailure: 'لم تتمكن من حفظ التعديلات',
  page: {
    patientFileSynthesis: 'ملخص ملف المريض',
    proDashboard: 'لوحة القيادة / الصفحة الرئيسية'
  },
  block: {
    patientFilesGraph: 'الرسوم البيانية',
    patientFilesContentScheduler: 'جدولة المحتوى',
    patientFilesIncomingData: 'بيانات المراقبة عن بعد',
    patientFilesTableValues: 'جداول القيم',
    proDashboardGraphs: 'الاتصالات والأنشطة',
    proDashboardAlerts: 'التنبيهات',
    proDashboardObservanceAlerts: 'تنبيهات عدم الامتثال',
    proDashboardDocsMessenger: 'الوثائق والرسائل'
  }
}
