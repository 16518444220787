export default {
  title: 'Care Pathway',
  subtitle: 'You can view all your documents, prescriptions, teleconsultation sessions, and appointments',
  creationDate: 'Creation date',
  startDate: 'Start date',
  endDate: 'End date',
  addBy: 'Added by ',
  viewBy: 'Viewed by ',
  scheduledToday: 'Scheduled today',
  noScheduledToday: 'No events today',
  allEvents: 'All my events',
  today: 'today',
  status: {
    available: 'Available',
    ongoing: 'Ongoing',
    completed: 'Completed',
    expired: 'Expired',
    upcoming: 'Upcoming',
    free: 'Free'
  },
  acceptedDelay: 'Accepted response until {limitDate}',
  answeredOn: 'Answered on {answerDate}',
  form: 'Form',
  myForm: 'My form',
  formInfo: 'Informations about this form',
  sendingForm: 'Sending your form',
  loadingForm: 'Loading form',
  sendDelay: 'To send before',
  sendSuccess: 'The form was succesfully sent',
  alreadySent: 'Response was already sent',
  summary: 'Form\'s summary',
  freeSubmission: 'Free submission',
  date: {
    allDay: 'All day',
    sameDate: 'On {date}',
    differentTime: 'On {startDate} - {endDate}',
    from: 'from {startDate} to {endDate} included',
    startingFrom: 'Starting from',
    until: 'Until',
    included: 'included',
    at: 'at',
    on: 'on'
  },
  unknownEvent: 'Unknown event',
  lateEvents: {
    title: 'You have late forms',
    subtitle: 'You should respond to late forms from the oldest one',
    oldestLateResponse: 'I respond to the oldest form',
    continue: 'I want to continue (previous late forms will not be available)'
  },
  mandatoryFieldError: 'There is an empty mandatory field | There are empties mandatory fields',
  answerSubmit: {
    title: 'Form succesfully sent!',
    subtitle: 'Look at your dashboard to consult other activities that are proposed to you',
    goToDashboard: 'Dashboard'
  },
  chooseLanguageForm: 'Choose which language to answer to form',
  formLanguageTitle: 'Form language',
  answerDate: 'Response date: {date}',
  formResponseDataDeactivated: 'A deactivated data invalidates the calculations that use it',
  formResponseLoadFailure: 'Unable to load form responses'
}
