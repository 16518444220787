export default {
  add: 'Ajouter',
  confirm: 'Confirmer',
  cancel: 'Annuler',
  close: 'Fermer',
  save: 'Enregistrer',
  back: 'Retour',
  submit: 'Envoyer',
  clearSelection: 'Effacer la sélection',
  possibleChoices: 'Choix possible | Choix possibles',
  openInNewWindow: 'Ouvrir dans une nouvelle fenêtre',
  search: 'Recherche',
  toSearch: 'Rechercher',
  searchByName: 'Rechercher par nom',
  sort: 'Trier',
  sortBy: 'Trier par',
  filter: 'Filtrer',
  noResult: 'Aucun résultat',
  unknownError: 'Une erreur est survenue',
  chooseLanguage: 'Choisir une langue',
  welcome: 'Bienvenue',
  about: 'À propos',
  form: {
    title: 'Titre',
    description: 'Description'
  },
  you: 'Vous',
  acknowledged: 'Acquitté par {person} le {date}',
  waitingForAcknowledgment: 'En attente d\'acquittement',
  validate: 'Valider',
  next: 'Suivant',
  previous: 'Précédent',
  wait: 'Veuillez patienter',
  of: 'du',
  yes: 'Oui',
  no: 'Non',
  and: 'Et',
  other: 'autre | autres',
  notSpecified: 'Non renseigné',
  renew: 'Renouveler',
  edit: 'Éditer',
  ignore: 'Ignorer',
  download: 'Télécharger',
  delete: 'Supprimer',
  connect: 'Se connecter',
  disconnect: 'Se déconnecter',
  reconnect: 'Se reconnecter',
  confirmRequired: 'Confirmation nécessaire',
  confirmMessage: 'Êtes-vous sûr de vouloir effectuer cette action ?',
  export: 'Exporter',
  continue: 'Continuer',
  dateErrors: {
    incomplete: 'La date est incomplète',
    inThePast: 'La date ne peut être inférieure à aujourd\'hui',
    inTheFuture: 'La date ne peut être supérieure à aujourd\'hui',
    birthDateInTheFuture: 'La date de naissance ne peut être supérieure à aujourd\'hui'
  },
  fileUnavailable: 'Le fichier n\'est plus disponible'
}
