const routes = [
  {
    path: '/accept-cgu',
    name: 'accept-cgu',
    component: () => import('src/modules/core/views/AcceptCgu.vue')
  },
  {
    name: 'auth-error',
    path: '/auth-error',
    component: () => import('src/modules/core/views/ErrorAuth.vue')
  }
]

export function addCommonRoutes(router) {
  routes.forEach(router.addRoute)
}
