// TODO Should be removed
// Thoses functions should not be considered as composables.
// These are simple javascript functions. Composable is a concept of Vue.
// So these are two things that should be kept separate.
// See https://vuejs.org/guide/reusability/composables.html#conventions-and-best-practices

import * as uriUtils from '../uri.js'

export function useUriUtils() {
  return uriUtils
}
