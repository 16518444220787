import {
  sessionExpiresAt,
  sessionExpired,
  sessionRemainingTime,
  onSessionKeepAlive
} from 'src/services/session-expiration'

export function useSessionExpiration() {
  return {
    sessionExpiresAt,
    sessionExpired,
    sessionRemainingTime,
    onSessionKeepAlive
  }
}
