export default {
  displayMode: {
    title: 'Display Mode',
    light: 'Light',
    dark: 'Dark',
    auto: 'Automatic'
  },
  alertThemes: {
    title: 'Alert Themes',
    blue: 'Blue',
    red: 'Red',
    green: 'Green',
    pink: 'Pink'
  },
  layoutEditor: {
    title: 'Page Display and Organization',
    description: 'Access the interface editor'
  }
}
