export default {
  displayMode: {
    title: 'Mode d\'affichage',
    light: 'Clair',
    dark: 'Sombre',
    auto: 'Automatique'
  },
  alertThemes: {
    title: 'Thèmes des alertes',
    blue: 'Bleu',
    red: 'Rouge',
    green: 'Vert',
    pink: 'Rose'
  },
  layoutEditor: {
    title: 'Affichage et organisation des pages',
    description: 'Accéder à l\'éditeur d\'interface'
  }
}
