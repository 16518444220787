export default {
  title: 'Indicateurs',
  subtitle: 'Liste des indicateurs',
  create: 'Ajouter un indicateur',
  errors: {
    name: {
      NOT_UNIQUE_ERROR: 'Un indicateur du même nom existe déjà'
    }
  }
}
