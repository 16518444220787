import { defineBoot } from '#q-app/wrappers'
import axios from 'axios'
import { config } from './config.js'
import qs from 'qs'
import dayjs from 'dayjs'
import { i18n } from 'boot/i18n'
import { empty } from 'src/utils/variable'
import { Lang } from 'quasar'

const langList = import.meta.glob('../../node_modules/quasar/lang/*.js')


const api = axios.create({
  baseURL: config.urls.api,
  paramsSerializer: params => {
    return qs.stringify(params)
  },
  withCredentials: true,
  withXSRFToken: true
})

export default defineBoot(({ app }) => {
  const $q = app.config.globalProperties.$q

  // for use inside Vue files (Options API) through this.$axios and this.$api
  app.config.globalProperties.$axios = axios
  app.config.globalProperties.$api = api

  api.interceptors.request.use((config) => {
    const tz = dayjs.tz.guess()
    if (tz) {
      config.headers['x-timezone'] = dayjs.tz.guess()
    }
    if (!empty(i18n.global.locale) && (empty(config.fetchLocaleFromApi) || !config.fetchLocaleFromApi)) {
      if (typeof config.params !== 'object' || config.params === null) {
        config.params = {}
      }
      config.params.l = i18n.global.locale
    }
    return config
  }, null, { synchronous: true })

  api.interceptors.response.use((response) => {
    if(response.config.fetchLocaleFromApi) {
      const locale = response.headers['content-language']
      if (!empty(locale) && ['fr', 'en', 'ar'].includes(locale)) {

        let quasarLocale = locale
        if (locale === 'en') {
          quasarLocale += '-GB'
        }

        if ($q.lang.getLocale() !== locale) {
          localStorage.setItem('lang', locale)
          i18n.global.locale = locale

          langList[ `../../node_modules/quasar/lang/${ quasarLocale }.js` ]().then(lang => {
            Lang.set(lang.default)
          })
        }
      }
    }

    return response
  })
})

export { api }
