export default {
  errors: {
    name: {
      NOT_UNIQUE_ERROR: 'وحدة بنفس الاسم موجودة بالفعل'
    },
    symbol: {
      NOT_UNIQUE_ERROR: 'وحدة بنفس الرمز موجودة بالفعل'
    }
  }
}
