export default {
  title: 'Données calculées',
  subtitle: 'Liste des données calculées',
  create: 'Ajouter une donnée calculée',
  errors: {
    indicator: {
      NOT_UNIQUE_ERROR: 'Cet indicateur est déjà lié à une donnée calculée dans cet établissement'
    },
    name: {
      NOT_UNIQUE_ERROR: 'Une donnée calculée du même nom existe déjà dans cet établissement'
    },
    calculation: {
      unique_undeactivated_calculation: 'Une seule donnée calculée peut être activée pour un indicateur et un service de l\'établissement.'
    }
  }
}
