export default {
  channels: {
    APPLICATION: 'Web',
    EMAIL: 'Email',
    PUSH: 'Mobile'
  },
  notifications: {
    PATIENT_FILE: {
      NEW: 'Nouveau dossier patient'
    },
    DOCUMENT: {
      NEW: 'Nouveau document',
      REMIND: 'Renouvellement de prescription'
    },
    MESSAGE: {
      NEW: 'Nouveau message'
    },
    ALERT: {
      NEW: 'Nouvelle alerte'
    },
    CONTENT: {
      NEW: 'Nouveau contenu thérapeutique'
    },
    PROTOCOL: {
      ASSIGN: 'Nouveau protocole'
    },
    FORM_OCCURRENCE: {
      NEW: 'Nouveau formulaire disponible',
      REMIND: 'Rappel de formulaire disponible'
    },
    CALCULATION: {
      SUBMIT_VALIDATION: 'Validation de donnée calculée'
    },
    FORM: {
      SUBMIT_VALIDATION: 'Validation de formulaire'
    },
    PROPERTY: {
      SUBMIT_VALIDATION: 'Validation de paramètre supplémentaire'
    },
    CALENDAR_EVENTS: {
      NEW: 'Création de rendez-vous/rappels',
      UPDATE: 'Modification de rendez-vous/rappels',
      DELETE: 'Suppression de rendez-vous/rappels',
      REMIND: 'Rappel de rendez-vous/rappels'
    }
  }
}
