import { computed, ref, watch } from 'vue'
import { config } from 'boot/config'
import { api } from 'boot/axios'
import { Role } from 'src/modules/core/helpers/role'
import { useRouter } from 'vue-router'
import { onReady } from 'src/router'
import logger from 'src/services/logger'
import { useProfessionalSessionFactory } from 'src/modules/core/composables/useProfessionalSession'
import { useAdminSessionFactory } from 'src/modules/core/composables/useAdminSession'
import { usePatientSessionFactory } from 'src/modules/core/composables/usePatientSession'
import qs from 'qs'
import { currentHost, currentURL } from 'src/utils/uri'

export const user = ref(null)
const sessionStarted = ref(false)
const isAdmin = ref(false)
const isProfessional = ref(false)
const isPatient = ref(false)
const cguConsentRequired = ref(false)
const personalDataUseConsentRequired = ref(false)
const loading = ref(true)
const error = ref((new URLSearchParams(window.location.search)).get('error'))
const loggingOut = ref(false)
let instance = null

export function useAuth() {
  if (instance) {
    return instance
  }

  const router = useRouter()

  const { onCreatePatientSession } = usePatientSessionFactory()
  const { onCreateProfessionalSession } = useProfessionalSessionFactory()
  const { onCreateAdminSession } = useAdminSessionFactory()

  watch(loading, loading => {
    if (!loading) {
      onReady()
    }
  })

  router.beforeEach((to, from, next) => {
    if (!user.value && !error.value) {
      onLogin()
    } else if ((cguConsentRequired.value || personalDataUseConsentRequired.value)) {
      // Avoid infinite loop with this nested condition
      if (to.name !== 'accept-cgu') {
        next({ name: 'accept-cgu' })
      } else {
        next()
      }
    } else if (error.value) {
      // Avoid infinite loop with this nested condition
      if (to.name !== 'auth-error') {
        next({ name: 'auth-error', query: { error: error.value } })
      } else {
        next()
      }
    } else if (to.name === 'auth-error' || to.name === 'accept-cgu') {
      next('/')
    } else {
      next()
    }
  })

  // Return true if at least one role match current user roles
  const granted = computed(() => {
    return roles => {
      if (!Array.isArray(roles)) {
        roles = [roles]
      }

      const userRoles = user.value && Array.isArray(user.value.roles) ? user.value.roles.map(role => role.toLowerCase()) : []
      for (const role of roles) {
        if (typeof role !== 'string') {
          throw new Error('Expected role to be a string. Got ' + typeof role)
        }

        if (userRoles.includes(role.toLowerCase())) {
          return true
        }
      }

      return false
    }
  })

  async function onCheckSession(targetRoute, retry = false) {
    if (error.value && !retry) { // Wait for user interaction before starting session
      loading.value = false
      return
    }

    if (sessionStarted.value) {
      throw new Error('Session must not be started more than once')
    }

    try {
      error.value = null
      user.value = (await api.get('/me', { fetchLocaleFromApi: true })).data
      sessionStarted.value = true

      const userRoles = user.value && Array.isArray(user.value.roles) ? user.value.roles.map(role => role.toLowerCase()) : []
      isAdmin.value = userRoles.includes(Role.Admin.toLowerCase())
      isProfessional.value = userRoles.includes(Role.Professional.toLowerCase())
      isPatient.value = userRoles.includes(Role.Patient.toLowerCase())
      cguConsentRequired.value = false
      personalDataUseConsentRequired.value = false

      router.removeRoute('loading')
      if (isAdmin.value) {
        await onCreateAdminSession()
      } else if (isPatient.value) {
        await onCreatePatientSession()
      } else if (isProfessional.value) {
        await onCreateProfessionalSession()
      }
    } catch (e) {
      // TODO Handle auth error
      if (
        e.response &&
        e.response.status === 401 &&
        (e.response.data.error === 'personal_data_use_not_approved' || e.response.data.error === 'cgu_not_accepted')
      ) {
        error.value = e.response.data.error
        if (e.response.data.error === 'personal_data_use_not_approved') {
          personalDataUseConsentRequired.value = true
        }
        cguConsentRequired.value = true
      } else {
        logger.error(e)
      }
    } finally {
      loading.value = false

      try {
        await router.replace(targetRoute ?? { path: window.location.pathname })
      } catch {
        // Ignore it
      }
    }
  }

  function onLogin(targetUrl) {
    if (error.value) {
      throw new Error('Infinite loop avoided. Cannot call login without clearing current error WITH MANUAL user action.')
    }

    window.location.assign(config.urls.api + '/login?' + qs.stringify({ targetUrl: targetUrl || currentURL(), branding: config.theme.branding }))
  }

  function onLogout() {
    loggingOut.value = true
    window.location.assign(config.urls.api + '/logout?' + qs.stringify({ targetUrl: currentHost(), branding: config.theme.branding }))
  }

  instance = {
    user,
    loading,
    granted,
    isAdmin,
    isProfessional,
    isPatient,
    cguConsentRequired,
    personalDataUseConsentRequired,
    onCheckSession,
    onLogin,
    onLogout,
    loggingOut,
    error
  }

  return instance
}
