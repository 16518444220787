export default {
  if: 'إذا:',
  then: 'إذن:',
  is: 'هو ',
  referenceValue: 'القيمة المرجعية',
  indicatorBoundary: 'حد المؤشر',
  defined: 'المحدد للتنبيه',
  plus: 'أكثر',
  minus: 'أقل',
  atLeastOne: 'على الأقل أحد هذه الشروط صحيح',
  all: 'جميع الشروط صحيحة',
  answer: 'الإجابة على السؤال',
  positive: 'إيجابية',
  negative: 'سلبية',
  formElementType: {
    calculation: 'الحساب',
    property: 'المعطى الإضافي',
    score: 'الدرجة'
  },
  unnamed: 'بدون اسم مذكور',
  notSpecified: 'لم يتم تحديده',
  specified: 'تم تحديده',
  containChoice: 'يتضمن الاختيار',
  matchChoice: 'يتطابق مع الاختيار',
  responseValue: {
    noValue: 'ليس له قيمة وقت الإجابة',
    value: 'قيمته {value} وقت الإجابة'
  },
  createMesure: 'إنشاء قياس جديد للمؤشر',
  worth: 'قيمته',
  conditionOperators: {
    equals: 'يساوي',
    greater: 'أكبر من',
    less: 'أقل من',
    greatEqual: 'أكبر من أو يساوي',
    lessEqual: 'أقل من أو يساوي'
  }
}
