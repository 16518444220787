export default {
  add: 'إضافة',
  confirm: 'تأكيد',
  cancel: 'إلغاء',
  close: 'إغلاق',
  save: 'حفظ',
  back: 'عودة',
  submit: 'إرسال',
  clearSelection: 'مسح التحديد',
  possibleChoices: 'اختيار ممكن | اختيارات ممكنة',
  openInNewWindow: 'فتح في نافذة جديدة',
  search: 'بحث',
  toSearch: 'البحث',
  searchByName: 'البحث حسب الاسم',
  sort: 'ترتيب',
  sortBy: 'ترتيب حسب',
  filter: 'تصفية',
  noResult: 'لا توجد نتائج',
  unknownError: 'حدث خطأ',
  chooseLanguage: 'اختر لغة',
  welcome: 'مرحبًا',
  about: 'حول',
  form: {
    title: 'العنوان',
    description: 'الوصف'
  },
  you: 'أنت',
  acknowledged: 'تم الإقرار من قِبل {person} في {date}',
  waitingForAcknowledgment: 'بانتظار الإقرار',
  validate: 'تحقق',
  next: 'التالي',
  previous: 'السابق',
  wait: 'يرجى الانتظار',
  of: 'من',
  yes: 'نعم',
  no: 'لا',
  and: 'و',
  other: 'آخر | آخرون',
  notSpecified: 'غير محدد',
  renew: 'تجديد',
  edit: 'تعديل',
  ignore: 'تجاهل',
  download: 'تحميل',
  delete: 'حذف',
  connect: 'تسجيل الدخول',
  disconnect: 'تسجيل الخروج',
  reconnect: 'إعادة الاتصال',
  confirmRequired: 'التأكيد مطلوب',
  confirmMessage: 'هل أنت متأكد أنك تريد تنفيذ هذا الإجراء؟',
  export: 'التصدير',
  continue: 'تابع',
  dateErrors: {
    incomplete: 'التاريخ غير مكتمل',
    inThePast: 'لا يمكن أن يكون التاريخ أقل من اليوم',
    inTheFuture: 'لا يمكن أن يكون التاريخ أكبر من اليوم',
    birthDateInTheFuture: 'لا يمكن أن يكون تاريخ الميلاد أكبر من اليوم'
  },
  fileUnavailable: 'الملف لم يعد متاحًا'
}
