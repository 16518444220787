export default {
  title: 'إضافة مستندات',
  subtitle: 'يمكنك إضافة عنوان ووصف إلى مستنداتك',
  uploaderTitle: 'أسقط ملفًا أو انقر هنا لتحميل مستنداتك',
  externalFileUrl: 'رابط',
  addFiles: 'إضافة ملف',
  addMyDocument: 'إضافة مستندي',
  addDocument: 'إضافة مستند',
  status: 'حالة الإقرار',
  showToPatientAndCaregivers: 'عرض الوثيقة للمريض ومقدمي الرعاية',
  identityDocument: 'وثيقة هوية مرجعية',
  startDate: 'تاريخ البداية',
  endDate: 'تاريخ الانتهاء',
  reminderDate: 'تاريخ التذكير',
  file: 'ملف',
  link: 'رابط يوتيوب',
  additionSuccess: 'تمت إضافة الوثيقة بنجاح',
  close: {
    search: 'إغلاق البحث',
    sortOptions: 'إغلاق خيارات الفرز',
    filters: 'إغلاق الفلاتر'
  },
  listOfDocument: {
    type: 'النوع',
    appointment: 'موعد',
    document: 'مستند',
    information: 'معلومة',
    teleconsultation: 'استشارة عن بُعد',
    report: 'تقرير'
  },
  listOfTypeOfDocuments: {
    typeOfDocument: 'نوع المستند',
    telemonitoringPrescription: 'وصفة المراقبة عن بُعد',
    prescription: 'وصفة المراقبة عن بُعد',
    medicationPrescription: 'وصفة طبية',
    analysisResults: 'نتائج التحاليل',
    image: 'الصور الطبية',
    therapeutic: 'مستند علاجي',
    information: 'مستند معلومات',
    report: 'تقرير',
    other: 'أخرى',
    identityDocument: 'مستند الهوية'
  },
  displayDocumentList: {
    title: 'قائمة المستندات',
    subtitle: 'يمكنك مشاهدة قائمة بجميع مستنداتك',
    tableInput: {
      title: 'قائمة مستنداتي',
      titleOfDocument: 'العنوان',
      typeOfDocument: 'نوع المستند',
      addDate: 'تاريخ الإضافة',
      addBy: 'تمت الإضافة بواسطة',
      created: 'تم الإنشاء في',
      end: 'ينتهي في',
      tooltipForEdit: 'تعديل',
      tooltipForDelete: 'حذف',
      sortByDate: 'فرز حسب التاريخ',
      noData: 'لا توجد مستندات تطابق بحثك'
    }
  },
  acquittal: {
    acquit: 'إقرار',
    message: 'رسالة',
    acquitted: 'تم الإقرار'
  },
  noDocument: {
    title: 'ليس لديك أي مستندات للعرض.',
    subtitle: 'يمكنك الآن تبادل الوثائق مع فريقك الطبي',
    subtitle2: 'بالضغط على زر "إضافة مستند " أدناه'
  },
  documentOrderBy: {
    name: 'الاسم',
    creationDate: 'تاريخ الإضافة'
  },
  displayDocument: {
    sentBy: '{fileSize} تم إرساله بواسطة {provider}',
    acknowledgmentFailure: 'تعذر الإقرار بالملف. يرجى المحاولة مرة أخرى.',
    deletionConfirm: 'أنت على وشك حذف هذا الملف. هل تريد المتابعة؟',
    deletionSuccess: 'تم حذف الملف بنجاح.',
    deletionFailure: 'تعذر حذف الملف.',
    waitingForAcknowledgment: 'في انتظار الإقرار من قبل أحد المحترفين',
    generalInformations: {
      title: 'معلومات عامة',
      patient: 'المريض',
      documentType: 'نوع المستند',
      creationDate: 'تاريخ الإضافة',
      prescriptionDate: 'تاريخ بدء الوصفة',
      endPrescriptionDate: 'تاريخ انتهاء الوصفة',
      reminder: 'تاريخ التذكير',
      actions: 'الإجراءات المتاحة'
    },
    error: 'تعذر عرض الوثيقة،\nيمكنك محاولة تحميلها.',
    download: 'تحميل الوثيقة',
    isOpening: 'سيتم فتح الوثيقة في عارض الملفات الافتراضي على هاتفك.'
  },
  dialog: {
    edit: 'تعديل مستند',
    acquit: 'الإقرار بمستند',
    renew: 'تجديد مستند'
  },
  description: 'الوصف',
  prescription: {
    deletion: {
      confirmation: 'أنت على وشك حذف الوصفة الطبية، هل تريد المتابعة؟',
      success: 'تم حذف الوصفة الطبية بنجاح.',
      error: 'من المستحيل إزالة الوصفة الطبية.'
    },
    status: {
      waiting: 'قيد الانتظار',
      ongoing: 'جارية',
      expired: 'منتهية الصلاحية',
      awaitingRenewal: 'في انتظار التجديد'
    }
  },
  identityType: {
    errors: {
      load: 'تعذر تحميل أنواع وثائق الهوية',
      creation: 'تعذر إنشاء نوع وثيقة الهوية {label}',
      edition: 'تعذر تعديل نوع وثيقة الهوية {label}',
      deletion: 'نوع وثيقة الهوية {label} مستخدم، ولا يمكن حذفه',
      used: 'تعذر حذف نوع وثيقة الهوية {label}'
    }
  },
  therapeuticContent: {
    addedOn: 'أضيف في {date}',
    video: 'فيديو',
    document: 'وثيقة',
    image: 'صورة',
    youtube: 'فيديو يوتيوب'
  }
}
