export default {
  agenda: 'Mon agenda',
  tasks: 'Mes tâches',
  monitoring: 'Mon suivi',
  formResponse: 'Réponses à un questionnaire',
  profileEdit: 'Édition du profil',
  iot: 'Objets connectés',
  myForm: 'Mon questionnaire',
  messenger: 'Ma messagerie',
  addDocument: 'Ajouter un document',
  myDocuments: 'Mes documents',
  documentConsult: 'Consulter un document',
  therapeuticEducation: 'Éducation thérapeutique'
}
