export default {
  displayMode: {
    title: 'وضع العرض',
    light: 'فاتح',
    dark: 'داكن',
    auto: 'تلقائي'
  },
  alertThemes: {
    title: 'ثيمات التنبيهات',
    blue: 'أزرق',
    red: 'أحمر',
    green: 'أخضر',
    pink: 'وردي'
  },
  layoutEditor: {
    title: 'عرض وتنظيم الصفحات',
    description: 'الانتقال إلى محرر الواجهة'
  }
}
