export default {
  errors: {
    invalid_operand: 'Invalid operand',
    invalid_mathematical_expression: 'The mathematical expression contains errors in its operands',
    invalid_duration: 'The operand has an invalid duration',
    missing_offset: 'The operand does not have an offset',
    missing_indicator: 'The operand does not have an indicator',
    missing_scalar: 'The operand does not have a scalar value',
    missing_property: 'The operand does not have an additional parameter'
  }
}
