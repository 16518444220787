export default {
  edit: {
    title: 'Éditer son profil',
    subTitle: 'Formulaire d\'édition',
    editButton: 'Modifier'
  },
  generalInformations: 'Informations générales',
  myProfile: 'Mon profil',
  myInformations: 'Mes informations',
  caregivers: 'Mes aidants',
  changePassword: 'Changer mon mot de passe',
  notifications: 'Gestion des notifications',
  iot: 'Objets connectés',
  chooseLanguage: 'Choisir la langue',
  help: 'Documentation et aide',
  onlineHelp: 'Aide en ligne',
  userManual: 'Manuel d\'utilisation',
  legalMentions: 'Mentions légales',
  eula: 'CGU',
  about: 'À propos',
  disconnect: 'Déconnexion',
  menu: 'Retour au menu',
  password: 'Mot de passe',
  editProfile: {
    title: 'Modifier mon profil',
    emailAddress: 'Adresse e-mail',
    addAddress: 'Ajouter une adresse',
    phone: 'Téléphone',
    addPhone: 'Ajouter un numéro de téléphone',
    phoneNumber: 'Numéro de téléphone'
  },
  endUserLevelAgreement: 'Conditions générales d\'utilisation',
  otp: {
    invalidCode: 'Le code est invalide'
  },
  emailUpdate: {
    info: 'Vous pouvez définir une nouvelle adresse e-mail pour votre compte.',
    validationCode: 'Afin de vérifier que cette adresse vous appartient, nous vous enverrons un code de validation.',
    waitBeforeRetry: 'Veuillez attendre {duration} avant de réessayer',
    sentValidationCode: 'Afin de vérifier que cette adresse vous appartient, nous avons envoyé un code de validation à votre nouvelle adresse. Veuillez le saisir dans les champs ci-dessous',
    verifyCode: 'Vérifier le code',
    success: 'L\'adresse e-mail a bien été modifiée',
    error: {
      alreadyUsedEmailAddress: 'L\'adresse e-mail est déjà utilisée'
    }
  },
  mobilePhoneUpdate: {
    info: 'Vous pouvez définir un nouveau numéro de téléphone pour votre compte.',
    validationCode: 'Afin de vérifier que ce numéro de téléphone vous appartient, nous vous enverrons un code de validation par SMS.',
    waitBeforeRetry: 'Veuillez attendre {duration} avant de réessayer',
    sentValidationCode: 'Afin de vérifier que ce numéro de téléphone vous appartient, nous avons envoyé un code de validation par SMS. Veuillez le saisir dans les champs ci-dessous',
    verifyCode: 'Vérifier le code',
    success: 'Le numéro de téléphone a bien été modifié'
  }
}
