import { areSame } from 'src/utils/variable'

export function slice(items, index, direction = 'after', limit = 10) {
  let start, end

  if (direction === 'after') {
    start = index + 1
    end = start + limit
  } else if (direction === 'before' && index > 0) {
    start = Math.max(index - limit, 0)
    end = index
  } else {
    return []
  }

  return items.slice(start, end)
}

export function arrayUnique(values) {
  return [...new Set(values)]
}

export function removeItem(items, item) {
  let index
  while ((index = items.indexOf(item)) > -1) {
    items.splice(index, 1)
  }
  return items
}

export function replaceItem(items, oldItem, newItem) {
  for (const index in items) {
    if (items[index] === oldItem) {
      items[index] = newItem
    }
  }
  return items
}

/**
 * Replace item find by compare func with newItem
 * Push newItem if there is no replaced item
 */
export function upsert(items, predicate, newItem) {
  const oldItemIndex = items.findIndex(predicate)
  if (oldItemIndex > -1) {
    items[oldItemIndex] = newItem
  } else {
    items.push(newItem)
  }
}

export function clone(array) {
  return [...array]
}

export function sortBy(items, key, order = 'ascending') {
  return items.sort((a, b) => {
    if (a[key] === b[key]) {
      return 0
    } else {
      if (order === 'ascending') {
        return (a[key] < b[key]) ? -1 : 1
      } else {
        return (a[key] > b[key]) ? -1 : 1
      }
    }
  })
}

export function ksort(w) {
  const sArr = []
  const tArr = []
  let n = 0
  for (const i in w) {
    n++
    tArr[n] = i
  }
  tArr.sort()
  n = tArr.length
  for (let i = 0; i < n; i++) {
    sArr[tArr[i]] = w[tArr[i]]
  }
  return sArr
}

export function compare(a, b) {
  // if the other array is a falsy value, return
  if (!Array.isArray(a) || !Array.isArray(b)) {
    return a === b
  }

  // compare lengths - can save a lot of time
  if (a.length !== b.length) {
    return false
  }

  for (let i = 0, l = a.length; i < l; i++) {
    // Check if we have nested arrays
    if (a[i] instanceof Array && b[i] instanceof Array) {
      // recurse into the nested arrays
      if (!compare(!a[i], b[i])) {
        return false
      }
    } else if (a[i] !== b[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false
    }
  }
  return true
}

export function column(arr, column) {
  return arr.map(item => {
    return typeof column === 'function' ? column(item) : item[column]
  })
}

// concat without duplicated values
export function merge(a, b) {
  return a.concat(b.filter(item => !a.includes(item)))
}

export function arrayContainsArray(superset, subset) {
  return subset.every((value) => superset.indexOf(value) >= 0)
}

export function arrayToObject(array, column) {
  return array.reduce((obj, item) => {
    const itemKey = typeof column === 'function' ? column(item) : item[column]
    return {
      ...obj,
      [itemKey]: item
    }
  }, {})
}

export function concat(arrays) {
  return Array.prototype.concat(...arrays)
}

// https://stackoverflow.com/a/5306832/4864628
export function arrayMove(arr, oldIndex, newIndex) {
  while (oldIndex < 0) {
    oldIndex += arr.length
  }
  while (newIndex < 0) {
    newIndex += arr.length
  }
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
  return arr
}

/**
 * Flatten an array
 *
 * const a = [
 *   [1,2],
 *   [
 *     [3,4]
 *   ],
 *   [
 *     [[5],[6]]
 *   ]
 * ];
 *
 * flatten(a) => [1, 2, 3, 4, 5, 6]
 */
export function flatten(arr) {
  return arr.reduce((a, v) => {
    v instanceof Array ? a.push(...flatten(v)) : a.push(v)
    return a
  }, [])
}

/**
 * Flatten an object passed as the first parameter using the callable as the second parameter.
 * The callable will receive as argument the key, the value,
 * a "push" callable called to push the current value into the results (no argument),
 * a "flatten" callable to recursively flatten the object which is passed to it as an argument.
 */
export function flattenArrayByCallable(object, callable) {
  let flattened = []
  const cb = object => Object.entries(object).forEach(([key, value]) => {
    const push = (value) => {
      flattened.push(value)
    }
    const flattenChildren = (children) => {
      flattened = flattened.concat(flattenArrayByCallable(children, callable))
    }
    callable(key, value, push, flattenChildren)
  })
  cb(object)
  return flattened
}

function resolveGroupBy(path, obj = self, separator = '.') {
  const properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev, curr) => prev?.[curr], obj)
}

export function groupBy(array, key) {
  return array.reduce((objectsByKeyValue, obj) => {
    const value = resolveGroupBy(key, obj)
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})
}

export function areSameArray(a, b) {
  if ((a !== null && !Array.isArray(a)) || (b !== null && !Array.isArray(b))) {
    throw new Error('Arguments must be arrays or null')
  }
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((value, index) => areSame(value, b[index]))
}

export function arraySlideIn(item, array) {
  array.unshift(item)
  array.pop()

  return array
}

/**
 * Returns every element from first array that are in all subsequent arrays
 */
export function intersectArrays(a = [], ...b) {
  const commonElements = []

  for (const value of a) {
    if (b.every(arr => arr.some((secondValue) => areSame(value, secondValue)))) {
      commonElements.push(value)
    }
  }

  return commonElements
}

/**
 * Returns array elements in a random order.
 */
export function shuffle(array) {
  return array
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
}
