import { defineBoot } from '#q-app/wrappers'
import { useUriUtils } from 'src/utils/composables/uriUtils'
import { empty, isTrue } from 'src/utils/variable'
import logoAptc from 'assets/logo-aptc-dark-BG.svg'
const { currentHost, currentURL } = useUriUtils()

const envVars = {
  DEBUG_LEVEL: process.env.DEBUG_LEVEL,
  DISABLE_CONSOLE: process.env.DISABLE_CONSOLE,
  BUILD_DATE: process.env.BUILD_DATE,
  BRANDING_SLUG_REGEX: process.env.BRANDING_SLUG_REGEX,
  API_ENTRYPOINT: process.env.API_ENTRYPOINT,
  AUTH_HOMEPAGE: process.env.AUTH_HOMEPAGE,
  STRICT_MODE: process.env.STRICT_MODE,
  SENTRY_DSN: process.env.SENTRY_DSN,
  SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT,
  SENTRY_AUTO_SESSION_TRACKING: process.env.SENTRY_AUTO_SESSION_TRACKING,
  SENTRY_TRACES_SAMPLE_RATE: process.env.SENTRY_TRACES_SAMPLE_RATE,
  SENTRY_REPLAYS_SESSION_SAMPLE_RATE: process.env.SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
  SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE: process.env.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE
}

// Override environments variables by those defined by the reverse proxy in the index.html sent to the end-user
for (const key in window.appConfig) {
  if (!empty(window.appConfig[key]) && window.appConfig[key] !== '__' + key + '__') {
    envVars[key] = window.appConfig[key]
  }
}

const BRANDING_LOGOS = {
  esantelys: 'https://cdn.healabs.fr/themes/santelys/logo-blanc.png'
}

const selfUrl = currentURL()
const matches = empty(envVars.BRANDING_SLUG_REGEX) ? null : selfUrl.match(envVars.BRANDING_SLUG_REGEX)
const branding = Array.isArray(matches) && !empty(matches[1]) ? matches[1] : null

const config = {
  env: process.env.NODE_ENV,
  version: process.env.APP_VERSION,
  buildDate: process.env.BUILD_DATE,
  releaseDate: process.env.RELEASE_DATE,
  disableConsole: isTrue(envVars.DISABLE_CONSOLE),
  strictMode: isTrue(envVars.STRICT_MODE),
  session: {
    timeRemainingBeforeUserWarn: 20 // seconds
  },
  theme: {
    branding,
    logo: BRANDING_LOGOS[branding] || logoAptc
  },
  host: currentHost(),
  urls: {
    self: selfUrl,
    api: envVars.API_ENTRYPOINT,
    // is used as a clickable button by the user when he is not authorized to access the application to bring him back to the SPA of the authentication server
    authHomepage: envVars.AUTH_HOMEPAGE
  },
  sentry: {
    // Leave it empty to disable Sentry
    // Get it at https://healabs.sentry.io/settings/projects/aptc-v3-spa/keys/
    dsn: envVars.SENTRY_DSN,
    environment: envVars.SENTRY_ENVIRONMENT || 'development',
    autoSessionTracking: isTrue(envVars.SENTRY_AUTO_SESSION_TRACKING ?? false),
    // Set tracesSampleRate to 1.0 to capture 100%  of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // Default to 1.0, (it means 100% of transactions are sent to sentry)
    tracesSampleRate: !empty(envVars.SENTRY_TRACES_SAMPLE_RATE) ? parseFloat(envVars.SENTRY_TRACES_SAMPLE_RATE) : 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    // Default to 0.1
    replaysSessionSampleRate: !empty(envVars.SENTRY_REPLAYS_SESSION_SAMPLE_RATE) ? parseFloat(envVars.SENTRY_REPLAYS_SESSION_SAMPLE_RATE) : 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    // Default to 1.0
    replaysOnErrorSampleRate: !empty(envVars.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE) ? parseFloat(envVars.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE) : 1.0
  }
}

export default defineBoot(({ app }) => {
  app.config.globalProperties.$config = config
})

export { config }
