export default {
  title: 'Données externes',
  subtitle: 'Liste des données externes',
  create: 'Ajouter une donnée externe',
  displayHistoryTooltip: 'Afficher l\'historique des versions',
  disableDataTooltip: 'Désactiver cette donnée',
  disableDataDate: 'Désactivée le',
  activateDataTooltip: 'Activer cette donnée',
  alertDisable: 'Êtes-vous sûr de vouloir désactiver cette donnée ?'
}
