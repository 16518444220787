export default {
  title: 'الإعدادات الإضافية',
  subtitle: 'قائمة الإعدادات الإضافية',
  create: 'إضافة إعداد إضافي',
  errors: {
    name: {
      NOT_UNIQUE_ERROR: 'هناك إعداد إضافي بنفس الاسم موجود بالفعل في هذه المؤسسة'
    }
  }
}
